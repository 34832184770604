import React, {useEffect, useState} from 'react';
import usePageMove from '../../hooks/usePageMove';
import {useSearch} from '../../hooks/useSearch';
import useGlobalData from "../../hooks/useGlobalData";
import useStorage from "../../hooks/useStorage";
import {useDataset} from "../../components/contexts/CommonContext";
import {useError} from "../../hooks/useException";
import useAxios, {AXIOS_HEADER} from "../../hooks/useAxios";
import {
    ComponentHistoryType,
    NexacroJsonType,
    NexacroJsonUnitType,
    SearchInfoType
} from "../../components/model/SqciTypes";
import {STORAGE_NAME} from "../../components/CommonConstants";
import DatePicker from "react-datepicker";
import {gfn_isNull, gfn_n_today_yyyy_mm_dd} from "../../components/utils/CommonUtils";
import {useComponent} from "../../components/contexts/ComponentContext";
import * as gfn from "../../components/utils/CommonUtils";
import {values} from "lodash";
import {useLocation, useOutletContext} from "react-router-dom";

const regexPlant: RegExp = /^(\[\w+\])(.*)$/gi;

export default function QmvSearch() {
    const {pageMove} = usePageMove();
    const {setDialog} = useComponent();
    const {doSearch, searchComponent, doSearchDivision, doSearchPlant, doSearchVendor, doSearchPart} = useSearch();
    const {dataset, sessionValues} = useDataset();
    const {sendAxios} = useAxios();
    const {throwException} = useError();
    const {getSdpVendorList, getSdpPartList, getGlobalSetting} = useGlobalData();
    const {getDocumentHistory} = useStorage();
    const sLocale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    const {getDomain, getMessage} = useGlobalData();

    let fv_Records = 9999; // 목록갯수
    let fv_Page = 0; // 페이지번호
    let temp_Status: NexacroJsonUnitType[] = [];
    let tempDsDivision:any = [];
    let tempDsPlant:any = [];
    let tempDsPlantList:any = [];
    let result:any = [];
    let [statusNm, setStatusNm] = useState(' All ');
    let [statusCd, setStatusCd] = useState(String);
    let tempDivList = new Array<NexacroJsonUnitType>();
    let tempPlantList = new Array<NexacroJsonUnitType>();

    const [vdivision, setvDivision] = useState<any>();
    const [vPlant, setvPlant] = useState<any>();
    const [vPlantList, setvPlantList] = useState<any>();
    const [ds_Search, setDs_Search] = useState(new Array<NexacroJsonUnitType>());
    const [divisionList, setDivisionList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_PlantList, setDs_PlantList] = useState(new Array<NexacroJsonUnitType>());
    const [plantList, setPlantList] = useState(new Array<NexacroJsonUnitType>());
    const [status, setStatus] = useState<NexacroJsonUnitType[]>();
    const [ds_List, setDsList] = useState(new Array<any>());
    const [hqList, setHqList] = useState<NexacroJsonUnitType[]>(); // 협력사 목록
    const [partList, setPartList] = useState<NexacroJsonUnitType[]>();
    const [division, setDivision] = useState<NexacroJsonUnitType>(getDocumentHistory('division'));
    const [plant, setPlant] = useState<NexacroJsonUnitType>(getDocumentHistory('plant'));
    const [vendor, setVendor] = useState<NexacroJsonUnitType>(getDocumentHistory('vendor'));
    const [part, setPart] = useState<NexacroJsonUnitType>(getDocumentHistory('part'));
    const [selectedIdx, setSelectedIdx] = useState(-1);
    const [datePickerFromWithDash, setDatePickerFromWithDash] = useState(getDocumentHistory('datePickerFromWithDash') ? getDocumentHistory('datePickerFromWithDash') : fn_dateToString_N("M", -1, true));
    const [datePickerToWithDash, setDatePickerToWithDash] = useState(getDocumentHistory('datePickerToWithDash') ? getDocumentHistory('datePickerToWithDash') : fn_dateToString_Z(new Date, true));

    useEffect(()=>{
        fn_onload();
    },[]);

    /* 사업부 선택 callback */
    const selectedDivision = (value: any) => {
        setPlant({});
        setVendor({});
        setPart({});
        setDivision(value);
    };

    /* 협력사 계정 진입시 사업부 선택 callback */
    const selectedPartnertDivision = (value: any) => {
        setPlant({});
        setPart({});
        setDivision(value);
    };

    /* Plant 선택 callback */
    const selectedPlant = (value: any) => {
        setVendor({});
        setPart({});
        setPlant(value)
    };

    /* 협력사 계정 진입시 Plant 선택 callback */
    const selectedPartnerPlant = (value: any) => {
        setPlant(value)
    };

    /* 협력사 선택 callback */
    const selectedValueHq = (value:any) => {
        setPart({});
        setVendor(value);
    };

    /* 부품 선택 callback */
    const selectedValuePart = (value: any) => {
        setPart(value);
    };

    /* Status 선택 callback */
    const selectedValueStatus = (value:any)=> {
        setStatusNm(value.CDNM);
        setStatusCd(value.CD);
    };

    useEffect(() => {
        if(division?.DIVISION_CD && plant?.PLANT_CD) {
            const requestJson: NexacroJsonType = {
                ds_Search: [{
                    PLANT_CD: plant?.PLANT_CD,
                    DIVISION_CD: division?.DIVISION_CD
                }],
            };
            getSdpVendorList(requestJson).then(response => {
                setHqList(response?.data.ds_vendorList
                    .filter((vendor: NexacroJsonUnitType) => vendor.PLANT_CD === plant?.PLANT_CD));
            }).catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
        }
    },[division, plant]);

    useEffect(() => {
        if(division?.DIVISION_CD && plant?.PLANT_CD && vendor?.HQ_CD) {
            const requestJson: NexacroJsonType = {
                ds_search: [{
                    SRCH_TYPE: 'C',
                    RECORDSOFFSET: '0',
                    RECORDS: '100000',
                    EXTINCTION: '0',
                    //CATEGORY2: '',
                    //CATEGORY1: '',
                    HQ_CD: vendor.HQ_CD,
                    POP_TYPE: 'D',
                    LANG: sLocale
                }],
                ds_plantList: [{
                    PLANT_CD: plant?.PLANT_CD
                }],
                ds_divList: [{
                    DIVISION_CD: division?.DIVISION_CD
                }]
            };
            getSdpPartList(requestJson).then(
                response => setPartList(response?.data.ds_list)
            );
        }
    },[division, plant, vendor]);

    useEffect(() => {
        const filter = (dataset?.ds_CommonCode || []).filter(f => f.CATEGORY === "SQCI000079" && f.LANG === sLocale && (f.CD == 'T' || f.CD == 'Y' || f.CD == 'N' || f.CD == 'P' || f.CD == 'C'));
        temp_Status.push({
            CD: '',
            CDNM: 'All'
        });
        for (const f of filter || []) {
            temp_Status.push({
                CD: String(f.CD),
                CDNM: String(f.CDNM)
            })
        }
        setStatus(temp_Status);
    },[]);

    useEffect(() => {
        if(
            !gfn.gfn_isNull(division?.DIVISION_CD)
            && !gfn.gfn_isNull(plant?.PLANT_CD)
            && !gfn.gfn_isNull(vendor?.HQ_CD)
            // && !gfn.gfn_isNull(part?.PART_CD)
            && !gfn.gfn_isNull(datePickerFromWithDash)
            && !gfn.gfn_isNull(datePickerToWithDash)
        ){
            setSelectedIdx(1);
        }else{
            setSelectedIdx(-1);
        }
    }, [division, plant, vendor, part, datePickerFromWithDash, datePickerToWithDash]);

    useEffect(() => {
        //협력사 계정일 경우
        if(sessionValues?.WORK_GROUP_CD == 1){
            if(!gfn.gfn_isNull(division) && !gfn.gfn_isNull(vPlant)){
                result = vPlant.filter((item:any) => item.DIVISION_CD == division?.DIVISION_CD);
                for(let i=0; i<result.length; i++){
                    tempDsPlantList.push(result[i]['PLANT_CD']);
                }
                setvPlantList(tempDsPlantList);
            }
        }
    },[division, plant, vendor, vPlant]);

    async function fn_onload(){
        if(sessionValues?.WORK_GROUP_CD == 1){
            fn_searchDivision();
            let tempPlant: [] = [];

            await fn_selectPlantList().then(response => {
                tempPlant = response?.data.ds_Plant;
                setDs_PlantList(response?.data.ds_Plant);
            }).catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
            await fn_setOrgData(tempPlant);
        }
    }

    /* 모든 사업부, Plant 리스트 조회 */
    function fn_searchDivision(){
        sendAxios('/common/nexacro/selectComPlantInfo.do', 'GET', AXIOS_HEADER.NEXACRO)
            .then(response => {
                tempDivList = response?.data.ds_divList;
                tempPlantList = response?.data.ds_plantList;
                setDivisionList(tempDivList);
                setPlantList(tempPlantList);
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
    }

    /* 협력사 계정 진입시 접근가능한 사업부, Plant rawData 조회 */
    function fn_selectPlantList() {
        const requestJson: NexacroJsonType = {
            ds_Search: [{
                LANG: sLocale,
                AUTH: 'HQ',
                HQ_CD: sessionValues?.HQ_CD,
                SHOW_HQ: 'false'
            }]
        };
        return sendAxios('/common/nexacro/selectPlantList.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson);
    }

    /* 협력사 계정 진입시 가져온 사업부, Plant rawData 분리 */
    function fn_setOrgData(tempPlant:any[]) {
        let sDivision = '';
        for(let i = 0; i < tempPlant.length; i++) {
            let sLevel = tempPlant[i]['LV'];
            if(sLevel == '2') { //사업부
                let code = tempPlant[i]['CODE'];
                sDivision = code;
                tempDsDivision.push(code);
            }else if(sLevel == '4') { //Plant
                let code = tempPlant[i]['CODE'];
                let nm = tempPlant[i]['NAME']
                let prod = tempPlant[i]['PLANT_PROD']
                let sPlantNm = "[" + code + "] " + nm + ' ' + prod;
                tempDsPlant.push({
                    PLANT_CD : code,
                    PLANT_NM : sPlantNm,
                    DIVISION_CD: sDivision
                })
            }
        }
        setvDivision(tempDsDivision);
        setvPlant(tempDsPlant);

        //기본세팅 사업부
        let divisionCd = sessionValues?.DIVISION_CD;
        let divisionNm = sessionValues?.DIVISION_NM;
        let tempDivisionSet:any = [];
        tempDivisionSet.push({
            DIVISION_CD: divisionCd,
            DIVISION_NM: divisionNm
        });
        setDivision(tempDivisionSet[0]);

        //기본세팅 Plant
        let plantCd = sessionValues?.PLANT_CD;
        let plantNm = sessionValues?.PLANT_NM;
        let tempPlantSet:any = [];
        tempPlantSet.push({
            DIVISION_CD: divisionCd,
            DIVISION_NM: divisionNm,
            PLANT_CD: plantCd,
            PLANT_NM: '['+plantCd+']'+plantNm,
            PLANT_PROD_NM: '['+plantCd+']'+plantNm
        });
        setPlant(tempPlantSet[0]);

        //기본세팅 협력사
        let hqcd = sessionValues?.HQ_CD;
        let hqNm = sessionValues?.HQ_NM;
        let tempHqSet:any = [];
        tempHqSet.push({
            DIVISION_CD: divisionCd,
            DIVISION_NM: divisionNm,
            HQ_CD: hqcd,
            HQ_NM: hqNm,
            PLANT_CD : plantCd
        });
        setVendor(tempHqSet[0]);
    }

    // 일주일 전으로 날짜 세팅
    function fn_dateToString_N(gb: string, diff: number, dashFlag: boolean){
        let nowDt = new Date;

        let diffY = 0;
        let diffM = 0;
        let diffD = 0;

        if (gb == "Y") {
            diffY = diff;
        } else if (gb == "M") {
            diffM = diff;
        } else if (gb == "D") {
            diffD = diff;
        }

        let nDate = new Date(nowDt.getFullYear() + diffY, nowDt.getMonth() + diffM, nowDt.getDate() + diffD);

        return fn_dateToString_Z(nDate, dashFlag);
    }

    // 일주일 전으로 날짜 세팅
    function fn_dateToString_Z(date:Date, dashFlag: boolean){
        let year = date.getFullYear();
        let month = ("0" + (1 + date.getMonth())).slice(-2);
        let day = ("0" + date.getDate()).slice(-2);

        let rv = year + month + day;

        if (dashFlag) {
            rv = year + "-" + month + "-" + day;
        }

        return rv;
    }

    /* 달력 교체 시 */
    function changeDatePicker(e: any, dateFromTo: string) {
        // let val = e.target.value.replaceAll('-', '').replaceAll('.', '');
        if (dateFromTo == 'F') {
            // setDatePickerFrom(val);
            setDatePickerFromWithDash(e.target.value);
        } else {
            // setDatePickerTo(val);
            setDatePickerToWithDash(e.target.value);
        }
    }

    function fn_search(){
        let records = '100000'; // 목록갯수
        let sType     = '01';
        let sVender   = vendor.HQ_CD;
        let sPartNo       = '';
        let sMaterialNo = part.PART_CD == undefined ? "" : String(part.PART_CD);
        let sStatus   = statusCd;
        let sSearchType = '';
        if (sMaterialNo.length >=4 && sMaterialNo.length <=10) {
            //라이크 검색
            sSearchType = 'LIKE';
        } else {
            //이콜검색
            sSearchType = 'EQ';
        }
        let vFromDt = datePickerFromWithDash.replaceAll('-', '').replaceAll('.', '');
        let vToDt = datePickerToWithDash.replaceAll('-', '').replaceAll('.', '');

        let startDt = new Date(datePickerFromWithDash); //to_date
        let endtDt = new Date(datePickerToWithDash);
        let diff = Math.abs(startDt.getTime() - endtDt.getTime());
        diff = Math.ceil(diff / (1000 * 60 * 60 * 24));
        if(diff > 90){
            setDialog({
                show: true,
                type: 'ALERT',
                text: getMessage("MSG00396")    // 조회기간은 최대 90일 입니다.
            });
            return false;
        }else{
            const requestJson: NexacroJsonType = {
                ds_Search: [{
                    FROM_DATE: vFromDt,
                    TO_DATE: vToDt,
                    //MAKER_CD: '',
                    SEARCH_TYPE: sSearchType,
                    MATERIAL_CD: sMaterialNo,
                    PART_NO: sPartNo,
                    LANG: sLocale,
                    RECORDS: records,
                    STATUS: sStatus,
                    RECORDSOFFSET: '0',
                    TYPE: sType,
                    VENDER_CD: sVender
                }],
                ds_Plant: [{
                    PLANT: plant.PLANT_CD
                }],
                ds_division: [{
                    DIVISION_CD: division.DIVISION_CD
                }]
            }
            pageMove(process.env.REACT_APP_URL_QMV_LIST || '', {
                division: division,
                plant: plant,
                vendor: vendor,
                part: part,
                datePickerFromWithDash: datePickerFromWithDash,
                datePickerToWithDash: datePickerToWithDash,
                requestJson : requestJson
            })
        }
    }

    function btnEvent(){
        if(selectedIdx == -1){
            return <button type={'button'} className={'button-primary'} onClick={() => fn_search()} disabled>{getDomain('DOMAIN4599')}</button>
        }else{
            return <button type={'button'} className={'button-primary'} onClick={() => fn_search()}>{getDomain('DOMAIN4599')}</button>
        }
    }

    /* 부품명 선택시, 사업부, plant, 협력사 체크 */
    function fn_doSearchPart() {
        let messageList: string[] = [];
        if(!division?.DIVISION_CD) {
            messageList.push(getDomain('DOMAIN0222'));
        }
        if(!plant?.PLANT_CD) {
            messageList.push(getDomain('DOMAIN2426'));
        }
        if(!vendor?.HQ_CD) {
            messageList.push(getDomain('DOMAIN0372'));
        }

        if(
            gfn.gfn_isNull(division?.DIVISION_CD)
            || gfn.gfn_isNull(plant?.PLANT_CD)
            || gfn.gfn_isNull(vendor?.HQ_CD)
        ) {
            setDialog({
                show: true,
                type: 'ALERT',
                text: messageList.join('/').concat(getMessage('MSG01542')),
                confirm: () => {}
            })
        }else{
            return doSearchPart('FAVORITES', [], selectedValuePart, undefined,
                undefined,
                division ? division.DIVISION_CD ? String(division.DIVISION_CD) : undefined : undefined,
                plant ? plant.PLANT_CD ? String(plant.PLANT_CD) : undefined : undefined,
                vendor ? vendor.HQ_CD ? String(vendor.HQ_CD) : undefined : undefined,
            );
        }
    }

    return (
        <>
            {searchComponent}
            <div className={'container'}>
                <div className={'contents-wrap'}>
                    <ul className={'reports-list'}>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> {getDomain('DOMAIN0222')} {/* 사업부 */}
                            </span>
                            { (1 === (sessionValues?.WORK_GROUP_CD || 0)) ? //협력사계정일 경우
                                <>
                                    <div role={'button'} onClick={() => doSearch({
                                        show: true,
                                        title: getDomain('DOMAIN0222'),
                                        type: 'RADIO',
                                        props: {
                                            target: divisionList || [],
                                            display: {
                                                name: 'DIVISION_NM',
                                                value: 'DIVISION_CD',
                                                strong: ''
                                            }
                                        },
                                        filter: [{
                                            DIVISION_CD: vdivision
                                        }],
                                        onSelect: selectedPartnertDivision
                                    })}>
                                        <p>{division?.DIVISION_NM}</p>
                                        <button type={'button'} className={'btn-link'}>
                                            <i className={'hidden'}>이동</i>
                                        </button>
                                    </div>
                                </>
                                :
                                ((
                                        2 === (sessionValues?.WORK_GROUP_CD || 0)  ||
                                        3 === (sessionValues?.WORK_GROUP_CD || 0) ||
                                        4 === (sessionValues?.WORK_GROUP_CD || 0) ||
                                        5 === (sessionValues?.WORK_GROUP_CD || 0)
                                    ) ? //시업부관리자 또는 법인검사자
                                        <div role={'button'} onClick={() => doSearchDivision('RADIO', selectedDivision,undefined, [{
                                            DIVISION_CD: gfn.gfn_tostring(sessionValues?.DIVISION_CD)
                                        }])}>
                                            <p>{division?.DIVISION_NM}</p>
                                            <button type={'button'} className={'btn-link'}>
                                                <i className={'hidden'}>{getDomain('DOMAIN4561')}{/*이동*/}</i>
                                            </button>
                                        </div>
                                        :
                                        <div role={'button'} onClick={() => doSearchDivision('RADIO', selectedDivision,undefined)}>
                                            <p>{division?.DIVISION_NM}</p>
                                            <button type={'button'} className={'btn-link'}>
                                                <i className={'hidden'}>{getDomain('DOMAIN4561')}{/*이동*/}</i>
                                            </button>
                                        </div>
                                )
                            }
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> {getDomain('DOMAIN2426')} {/* Plant */}
                            </span>
                            { 1 === (sessionValues?.WORK_GROUP_CD || 0) ? //협력사계정일 경우
                                <>
                                    <div role={'button'} onClick={() => doSearch({
                                        show: true,
                                        title: getDomain('DOMAIN2426'),
                                        type: 'RADIO',
                                        props: {
                                            target: plantList || [],
                                            display: {
                                                name: 'PLANT_PROD_NM',
                                                value: 'PLANT_CD',
                                                strong: 'PLANT_CD'
                                            }
                                        },
                                        filter: [{
                                            DIVISION_CD: vdivision
                                        }, {
                                            PLANT_CD: vPlantList
                                        }],
                                        onSelect: selectedPartnerPlant
                                    })}>
                                        <p>{plant?.PLANT_CD ? '['.concat(String(plant?.PLANT_CD), ']') : ''}<br/>{String(plant?.PLANT_PROD_NM || '').replace(regexPlant, '$2')}</p>
                                        <button type={'button'} className={'btn-link'}>
                                            <i className={'hidden'}>이동</i>
                                        </button>
                                    </div>
                                </> :
                                <>
                                    <div role={'button'} onClick={() => doSearchPlant('RADIO', selectedPlant, undefined,[{
                                        DIVISION_CD:[division?.DIVISION_CD]
                                    }])}>
                                        <p>{plant?.PLANT_CD ? '['.concat(String(plant?.PLANT_CD), ']') : ''}<br/>{String(plant?.PLANT_PROD_NM || '').replace(regexPlant, '$2')}</p>
                                        <button type={'button'} className={'btn-link'}>
                                            <i className={'hidden'}>이동</i>
                                        </button>
                                    </div>
                                </>
                            }
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> {getDomain('DOMAIN0372')} {/* 협력사 */}
                            </span>
                            { 1 === (sessionValues?.WORK_GROUP_CD || 0) ? //협력사계정일 경우
                                <>
                                    <div className={'text-item'}>
                                        <p>{sessionValues?.WORK_GROUP_CD == 1 ? '['.concat(String(sessionValues?.HQ_CD), ']') : ''}<br/>{sessionValues?.WORK_GROUP_CD == 1 ? sessionValues?.HQ_NM : ''}
                                        </p>
                                    </div>
                                </> :
                                <>
                                    <div className={'text-item'} onClick={() => doSearchVendor('FAVORITES', hqList || [], selectedValueHq)}>
                                        <p>{vendor?.HQ_CD ? '['.concat(String(vendor?.HQ_CD), ']') : ''}<br/>{vendor?.HQ_NM}</p>
                                        <button type={'button'} className={'btn-link'}>
                                            <i className={'hidden'}>이동</i>
                                        </button>
                                    </div>
                                </>
                            }
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'} dangerouslySetInnerHTML={{__html:"<!--<strong class='required'>*</strong>--> "+getDomain('DOMAIN4596')}}></span> {/* 부품명 */}
                            <div className={'item-wrap ellipsis1'} role={'button'} onClick={() => fn_doSearchPart()}>
                                <p className={'item-wrap ellipsis1'}>{part?.PART_CD ? '['.concat(String(part?.PART_CD), ']') : ''}<br/><strong className={'item-wrap ellipsis1'}>{part?.PART_NM}</strong></p>
                                <button type={'button'} className={'btn-link'}>
                                    <i className={'hidden'}>이동</i>
                                </button>
                            </div>
                        </li>
                        <li className={'form-item type-date'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> {getDomain('DOMAIN2602')} {/* 생산일자 */}
                            </span>
                            <div className="type-date-in">
                                <div className={'datepicker-wrap'}>
                                    <span className={'date-item'}>
                                        <input type={'date'} style={{WebkitAppearance:"none"}} required={true} pattern={'\d{4}-\d{2}-\d{2}'} defaultValue={datePickerFromWithDash} onChange={(e) => changeDatePicker(e, 'F')}/>
                                    </span>
                                </div>
                                <div className={'datepicker-wrap'}>
                                    <span className={'date-item'}>
                                        <input type={'date'} style={{WebkitAppearance:"none"}} required={true} pattern={'\d{4}-\d{2}-\d{2}'} defaultValue={datePickerToWithDash} onChange={(e) => changeDatePicker(e, 'T')}/>
                                    </span>
                                </div>
                            </div>
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                {getDomain('DOMAIN4598')} {/* Status */}
                            </span>
                            <div role={'button'} onClick={() => doSearch({
                                    show: true,
                                    title: ''+getDomain('DOMAIN4598')+'',
                                    type: 'RADIO',
                                    props: {
                                        target: status || [],
                                        display: {
                                            name: 'CDNM',
                                            value: 'CD',
                                            strong: ''
                                        }
                                    },
                                    onSelect: selectedValueStatus
                                })}>
                                <p>{statusNm}</p>
                                <button type={'button'} className={'btn-link'}>
                                    <i className={'hidden'}>이동</i>
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className={'btn-area bottom'}>
                    {btnEvent()}
                </div>
            </div>
        </>
    );
};