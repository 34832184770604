import React, {useEffect, useState} from 'react';
import usePageMove from '../../hooks/usePageMove';
import {useSearch} from '../../hooks/useSearch';
import {useDataset} from "../../components/contexts/CommonContext";
import useAxios, {AXIOS_HEADER} from "../../hooks/useAxios";
import {useError} from "../../hooks/useException";
import useGlobalData from "../../hooks/useGlobalData";
import useStorage from "../../hooks/useStorage";
import {
    ComponentHistoryType,
    NexacroJsonType,
    NexacroJsonUnitType,
    SearchInfoType
} from "../../components/model/SqciTypes";
import {useComponent} from "../../components/contexts/ComponentContext";
import {STORAGE_NAME} from "../../components/CommonConstants";
import {gfn_isNull, gfn_n_today_yyyy_mm_dd, gfn_toString} from "../../components/utils/CommonUtils";
import * as gfn from "../../components/utils/CommonUtils";
import {useLocation, useOutletContext} from "react-router-dom";

const regexPlant: RegExp = /^(\[\w+\])(.*)$/gi;

export default function QmvAdd() {
    const {pageMove} = usePageMove();
    const {doSearch, searchComponent, doSearchDivision, doSearchPlant, doSearchVendor, doSearchPart} = useSearch();
    const {dataset, sessionValues} = useDataset();
    const {sendAxios} = useAxios();
    const {throwException} = useError();
    const {getSdpVendorList, getSdpPartList, getGlobalSetting, getDomain, getMessage} = useGlobalData();
    const {getDocumentHistory} = useStorage();
    const {dialog, setDialog} = useComponent();
    const sLocale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);

    let [divisionNm, setDivisionNm] = useState(String);
    let [divisionCd, setDivisionCd] = useState(String);
    let [plantNm, setPlantNm] = useState(String);
    let [plantCd, setPlantCd] = useState(String);
    let [hqNm, setHqNm] = useState(String);
    let [hqCd, setHqCd] = useState(String);
    let [vendorCd, setVendorCd] = useState(String);
    let [vendorNm, setVendorNm] = useState(String);
    let [partCd, setPartCd] = useState(String);
    let [partNm, setPartNm] = useState(String);
    const [selectedIdx, setSelectedIdx] = useState(-1);
    const [vdivision, setvDivision] = useState<any>();
    const [vPlant, setvPlant] = useState<any>();
    const [vPlantList, setvPlantList] = useState<any>();
    const [datePickerFromWithDash, setDatePickerFromWithDash] = useState(fn_dateToString_Z(new Date, true));
    const [datePickerFrom, setDatePickerFrom] = useState(fn_dateToString_Z(new Date, false));

    const [hqList, setHqList] = useState<NexacroJsonUnitType[]>();
    const [division, setDivision] = useState<NexacroJsonUnitType>(getDocumentHistory('division'));
    const [plant, setPlant] = useState<NexacroJsonUnitType>(getDocumentHistory('plant'));
    const [vendor, setVendor] = useState<NexacroJsonUnitType>(getDocumentHistory('vendor'));
    const [part, setPart] = useState<NexacroJsonUnitType>(getDocumentHistory('part'));

    const [ds_Search, setDs_Search] = useState(new Array<NexacroJsonUnitType>());
    const [divisionList, setDivisionList] = useState(new Array<NexacroJsonUnitType>());
    const [plantList, setPlantList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_PlantList, setDs_PlantList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Part, setDsPart] = useState(new Array<NexacroJsonUnitType>());
    const [ds_LotNo, setDsLotNo] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Spec, setDsSpec] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Vendor, setDsVendor] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Aql, setDsAql] = useState(new Array<NexacroJsonUnitType>());
    const [ds_AllSample, setDsAllSample] = useState(new Array<NexacroJsonUnitType>());
    const [ds_org, setDsOrg] = useState(new Array<NexacroJsonUnitType>());
    const [ds_search, setDsSearch] = useState(new Array<NexacroJsonUnitType>());
    const [ds_chngPoingHList, setDsChngPoingHList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_plant, setDsplant] = useState(new Array<NexacroJsonUnitType>());
    const [ds_division, setDsdivision] = useState(new Array<NexacroJsonUnitType>());
    const [ds_SpecApproveStatus, setDsSpecApproveStatus] = useState(new Array<NexacroJsonUnitType>());
    const [ds_tsiAgentYn, setDsTSiAgentYn] = useState(new Array<NexacroJsonUnitType>());
    const [lotNo, setLotNo] = useState(String);
    const [lotSize, setLotSize] = useState(String);
    const location = useLocation();
    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();

    let vds_Part = new Array<NexacroJsonUnitType>();
    const [lotLimit, setLotlimit] = useState("50,000");
    let [haveSpec, setHaveSpec] = useState(Boolean(false));
    let tempLotNo = '';
    let tempLotSize = '';
    let fv_today = getToday(); //오늘 날짜

    let tempDsSpec = [];
    let tempDsChngPoingHList= new Array<NexacroJsonUnitType>();
    let tempDsSpecApproveStatus = new Array<NexacroJsonUnitType>();
    let tempDivList = new Array<NexacroJsonUnitType>();
    let tempPlantList = new Array<NexacroJsonUnitType>();
    let tempDsLotNo = [];
    let tempDsDivision:any = [];
    let tempDsPlant:any = [];
    let tempDsPlantList:any = [];
    let result:any = [];

    useEffect(() => {
        fn_onload();
    }, []);

    useEffect(() => {
        if(division?.DIVISION_CD && plant?.PLANT_CD) {
            const requestJson: NexacroJsonType = {
                ds_Search: [{
                    PLANT_CD: plant.PLANT_CD,
                    DIVISION_CD: divisionCd
                }],
            };
            getSdpVendorList(requestJson).then(response => {
                setHqList(response?.data.ds_vendorList)
            }).catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
        }
    },[division, plant]);

    useEffect(() => {
        if(
            !gfn.gfn_isNull(division?.DIVISION_CD)
            && !gfn.gfn_isNull(plant?.PLANT_CD)
            && !gfn.gfn_isNull(vendor?.HQ_CD)
            && !gfn.gfn_isNull(part?.PART_CD)
            && !gfn.gfn_isNull(lotNo)
            && !gfn.gfn_isNull(lotSize)
            && !gfn.gfn_isNull(datePickerFrom)
        ){
            setSelectedIdx(1);
        }else{
            setSelectedIdx(-1);
        }
    }, [division, plant, vendor, part, lotNo, lotSize, datePickerFrom]);

    useEffect(() => {
        // if(ds_Part && ds_LotNo) {
        //     qmvInspectRcrdInfoRegLotNoSelect();
        // }
    }, [ds_LotNo]);

    useEffect(() => {
        //협력사 계정일 경우
        if(sessionValues?.WORK_GROUP_CD == 1){
            if(!gfn.gfn_isNull(division) && !gfn.gfn_isNull(vPlant)){
                result = vPlant.filter((item:any) => item.DIVISION_CD == division?.DIVISION_CD);
                for(let i=0; i<result.length; i++){
                    tempDsPlantList.push(result[i]['PLANT_CD']);
                }
                setvPlantList(tempDsPlantList);
            }
        }
    },[division, plant, vendor, vPlant]);

    function fn_dateToString_Z(date:Date, dashFlag: boolean){
        let year = date.getFullYear();
        let month = ("0" + (1 + date.getMonth())).slice(-2);
        let day = ("0" + date.getDate()).slice(-2);

        let rv = year + month + day;

        if (dashFlag) {
            rv = year + "-" + month + "-" + day;
        }
        return rv;
    }

    async function fn_onload() {
        if(sessionValues?.WORK_GROUP_CD == 1){
            let tempPlant: [] = [];
            fn_searchDivision();

            await fn_selectPlantList().then(response => {
                tempPlant = response?.data.ds_Plant;
                setDs_PlantList(response?.data.ds_Plant);
            }).catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
            await fn_setOrgData(tempPlant);
        }
        if(ds_Part.length == 0){
            vds_Part.push({
                DIVISION_CD: sessionValues?.DIVISION_CD,
                DIVISION_NM: sessionValues?.DIVISION_NM,
                PLANT_CD: sessionValues?.PLANT_CD,
                PLANT_NM: '['+sessionValues?.PLANT_CD+'] '+sessionValues?.PLANT_NM,
                VENDOR_CD: sessionValues?.HQ_CD,
                VENDOR_NM: '['+sessionValues?.HQ_CD+'] '+sessionValues?.HQ_NM,
                LANG: sLocale,
                INSP_GB: 3
            })
        }
        setDsPart(vds_Part);
    };

    /* 모든 사업부, Plant 리스트 조회 */
    function fn_searchDivision(){
        sendAxios('/common/nexacro/selectComPlantInfo.do', 'GET', AXIOS_HEADER.NEXACRO)
            .then(response => {
                tempDivList = response?.data.ds_divList;
                tempPlantList = response?.data.ds_plantList;
                setDivisionList(tempDivList);
                setPlantList(tempPlantList);
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
    }

    /**
     * 부품명 검색 callback
     * @param param
     * @constructor
     */
    function COM_P_Com_PartList(param:any) {
        //231004 기존에 fn_onload에서 호출하던 fn_selectAqlCnt 위치변경
        //두번호출되는 이슈, sessionValue 가져오지 못해서 생기는 이슈로 인한 수정
        fn_selectAqlCnt();
        if(division?.DIVISION_CD && plant?.PLANT_CD && vendor?.HQ_CD){
            if (!gfn_isNull(param)) {
                ds_Part[0]['PART_CD'] = param.PART_CD;
                ds_Part[0]['PART_NM'] = String("[" + param.PART_CD + "] " + param.PART_NM);	//arrRetVal[1]

                if (!gfn_isNull(param.MAX_LOT_SIZE)) {
                    ds_Part[0]['MAX_LOT_SIZE'] = Number(param.MAX_LOT_SIZE);
                    setLotlimit(param.MAX_LOT_SIZE.replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                } else {
                    ds_Part[0]['MAX_LOT_SIZE'] = 50000;
                }
                ds_Part[0]['L2_CODE'] = param.CATEGORY2;

                //변경 초물을 ECN 조회후에 확정
                if (!gfn_isNull(param.NEW_PART_TYPE)) {
                    if (param.NEW_PART_TYPE == "N") {
                        ds_Part[0]['NEW_PART_TYPE'] = 'N';
                    } else {
                        ds_Part[0]['NEW_PART_TYPE'] = 'O';
                    }
                }
                //성적서 대상유무 체크 (자재가 성적서 제출 대상이고, SQCI_YN 이 M, Y    => 성적서 비대상 자재 로직 삭제  20.01.21 by KKA
                //fn_selectVendorNm();
                ds_Part[0]['DIVISION_NM'] = division?.DIVISION_NM;
                ds_Part[0]['PLANT_NM'] = plant?.PLANT_NM;
                ds_Part[0]['VENDOR_CD'] = vendor?.HQ_CD;
                ds_Part[0]['VENDOR_NM'] = '['+vendor?.HQ_CD+'] '+vendor?.HQ_NM;

                //SPEC 승인중인지 체크(1미수립, 2수립중, 3반려, 4완료, 5상신취소)
                const requestJson: NexacroJsonType = {
                    ds_Part: ds_Part
                };
                sendAxios('/common/nexacro/selectSpecApproveStatus.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
                    .then(response => {
                        tempDsSpecApproveStatus = response?.data.ds_SpecApproveStatus;
                        setDsSpecApproveStatus(tempDsSpecApproveStatus);
                        let ds_SpecApproveStatusCount = tempDsSpecApproveStatus.filter(result => result.LAST_REV_YN == 'Y' && (result.APPROVE_STATUS == 1 || result.APPROVE_STATUS == 4 || result.APPROVE_STATUS == 5)).length;
                        if (ds_SpecApproveStatusCount != 1) {
                            setDialog({
                                type: 'ALERT',
                                show: true,
                                text: getMessage('MSG01131'), //해당 자재는 SPEC 승인 요청중입니다.
                                confirm: () => {
                                    // pageMove(-1);
                                    setPart({});
                                    setSelectedIdx(-1);
                                },
                            });
                        }else{
                            // //스펙 항목 체크
                            fn_specInfoSelectChk();
                            // //ECN 정보 조회
                            fn_searchEcn().then(response => {
                                tempDsChngPoingHList = response?.data.ds_chngPoingHList;
                                setDsChngPoingHList(response?.data.ds_chngPoingHList);
                                selectTotalStatusList(response?.data.ds_chngPoingHList);
                            }).catch(e => {
                                throwException(e);
                                return new Promise(() => {
                                });
                            });
                        }
                    })
                    .catch(e => {
                        throwException(e);
                        return new Promise(() => {
                        });
                    });
            }
        }
    }

    /* 협력사 계정 진입시 접근가능한 사업부, Plant rawData 조회 */
    function fn_selectPlantList() {
        const requestJson: NexacroJsonType = {
            ds_Search: [{
                LANG: sLocale,
                AUTH: 'HQ',
                HQ_CD: sessionValues?.HQ_CD,
                SHOW_HQ: 'false'
            }]
        };
        return sendAxios('/common/nexacro/selectPlantList.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson);
    }

    /* 협력사 계정 진입시 가져온 사업부, Plant rawData 분리 */
    function fn_setOrgData(tempPlant:any[]) {
        let sDivision = '';
        for(let i = 0; i < tempPlant.length; i++) {
            let sLevel = tempPlant[i]['LV'];
            if(sLevel == '2') { //사업부
                let code = tempPlant[i]['CODE'];
                sDivision = code;
                tempDsDivision.push(code);
            }else if(sLevel == '4') { //Plant
                let code = tempPlant[i]['CODE'];
                let nm = tempPlant[i]['NAME']
                let prod = tempPlant[i]['PLANT_PROD']
                let sPlantNm = "[" + code + "] " + nm + ' ' + prod;
                tempDsPlant.push({
                    PLANT_CD : code,
                    PLANT_NM : sPlantNm,
                    DIVISION_CD: sDivision
                })
            }
        }
        setvDivision(tempDsDivision);
        setvPlant(tempDsPlant);

        //기본세팅 사업부
        let divisionCd = sessionValues?.DIVISION_CD;
        let divisionNm = sessionValues?.DIVISION_NM;
        let tempDivisionSet:any = [];
        tempDivisionSet.push({
            DIVISION_CD: divisionCd,
            DIVISION_NM: divisionNm
        });
        setDivision(tempDivisionSet[0]);

        //기본세팅 Plant
        let plantCd = sessionValues?.PLANT_CD;
        let plantNm = sessionValues?.PLANT_NM;
        let tempPlantSet:any = [];
        tempPlantSet.push({
            DIVISION_CD: divisionCd,
            DIVISION_NM: divisionNm,
            PLANT_CD: plantCd,
            PLANT_NM: '['+plantCd+']'+plantNm,
            PLANT_PROD_NM: '['+plantCd+']'+plantNm
        });
        setPlant(tempPlantSet[0]);

        //기본세팅 협력사
        let hqcd = sessionValues?.HQ_CD;
        let hqNm = sessionValues?.HQ_NM;
        let tempHqSet:any = [];
        tempHqSet.push({
            DIVISION_CD: divisionCd,
            DIVISION_NM: divisionNm,
            HQ_CD: hqcd,
            HQ_NM: hqNm,
            PLANT_CD : plantCd
        });
        setVendor(tempHqSet[0]);
    }

    /*
     *	Spec Info 조회
     *  strSvcId = "qmvInspectRcrdInfoRegSpecChk";
     */
    function fn_specInfoSelectChk(){
        const requestJson: NexacroJsonType = {
            ds_Part: ds_Part
        };
        sendAxios('/common/nexacro/QMV_P_OUT_InspectRcrdInfoRegSpecSelect.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
            .then((response) => {
                tempDsSpec = response?.data.ds_Spec;
                setDsSpec(tempDsSpec);
                qmvInspectRcrdInfoRegSpecChk(tempDsSpec);
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
    }

    /*
    * AQL 테이블 조회
    * return : none
    */
    function fn_selectAqlCnt() {
        sendAxios('/common/nexacro/QMS_P_Pre_SelectAqlCnt.do', 'POST', AXIOS_HEADER.NEXACRO)
            .then(response => {
                let tempDsAql = response?.data.ds_Aql;
                let tempDsAllSample = response?.data.ds_AllSample;
                setDsAql(tempDsAql);
                setDsAllSample(tempDsAllSample);
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
    }

    /*
     *	Lot No 중복 체크
     */
    function fn_lotNoSelect() {
        const requestJson: NexacroJsonType = {
            ds_Part: ds_Part
        };
        return sendAxios('/common/nexacro/qmvInspectRcrdInfoRegLotNoSelect.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
    };

    function qmvInspectRcrdInfoRegSpecSelect(param:any) {	//자재 spec 조회 - > 샘플 수량 조회
        // SPEC NUMYN 없을시 검사스펙을 확인하여 넣어줌
        for (let i = 0; i < param.length; i++) {
            if (gfn_isNull(ds_Spec[i]['NUM_YN'])){
                if (!ds_Spec[i]['USL'] && !ds_Spec[i]['CL'] && !ds_Spec[i]['LSL']) {
                    ds_Spec[i]['NUM_YN'] = 'N';
                } else {
                    ds_Spec[i]['NUM_YN'] = 'Y';
                }
            }
        }
        // AQL 적용
        for(let i = 0; i < ds_Spec.length; i++){
            let samCnt = 0;
            let inspMth = String(ds_Spec[i]['INSP_MTH']);
            let aql = String(ds_Spec[i]['AQL_LEVEL']);

            //sample cnt 계산 로직
            if(inspMth != null && (inspMth.substring(0,1) == "A")){// 전수검사일때
                samCnt = Number(tempLotSize);
                //samCnt = this.gfn_numberExceptCommas(this.div_Main.form.msk_LotSize.text);
            }else{ // 검사방식, AQL 계산
                samCnt = fn_getSampleCntByMeasMth(inspMth);
                if(inspMth.substring(0,1) == "S" || inspMth.substring(0,1) == "G"){
                    if(aql != null){
                        samCnt = fn_getSampleCntByAql(aql, samCnt);
                        //trace("aql : " + aql + "mth : "+inspMth+"sam : "+samCnt);
                    }
                }
            }
            //lot 보다 샘플수가 크면 샘플수를 랏사이즈로 변경
            samCnt = Number(tempLotSize) < samCnt ? Number(tempLotSize) : samCnt;
            ds_Spec[i]['SAM_CNT'] = String(samCnt);
        }
        fn_vndRcrdDetailReg();
    }

    function fn_getSampleCntByMeasMth(measMth:string){
        let rtnSamCnt = 0;
        let filterList = ds_AllSample.filter(item => String(item['MEAS_MTH']) == measMth);
        let lotSizeVal = Number(tempLotSize);

        if(measMth.substring(0,1) == "A"){ //전수검사 case
            rtnSamCnt = lotSizeVal;
        }else if(measMth.substring(0,1) == "C"){
            rtnSamCnt = Number(filterList[0]['SAM_CNT']);
        }else{
            for(let i=0; i<filterList.length; i++){
                let fromSize = filterList[i]['FROM_SIZE'];
                let toSize = filterList[i]['TO_SIZE'];

                if( Number(lotSizeVal) >= Number(fromSize) && Number(lotSizeVal) <= Number(toSize)){
                    rtnSamCnt = Number(filterList[i]['SAM_CNT']);
                    break;
                }
            }
        }
        return rtnSamCnt;
    };

    function fn_getSampleCntByAql(aql:string, samCnt:number) {
        let rtnSamCnt = 0;
        // this.ds_Aql.filter("AQL_LEVEL=='"+aql+"'");
        let filterDsAql = ds_Aql.filter(result => result['AQL_LEVEL'] == aql);
        let idx = filterDsAql.findIndex((item) =>item.AQL_SAMPLE_CNT == samCnt);
        //let idx = this.ds_Aql.findRow("AQL_SAMPLE_CNT", samCnt);
        let lotSizeVal = Number(tempLotSize);

        if(idx > -1){
            let iterator = filterDsAql[idx]['ACCESS_PATH'];
            if(iterator == "U"){
                while(iterator != "OK"){
                    iterator = filterDsAql[idx]['ACCESS_PATH'];
                    rtnSamCnt = Number(filterDsAql[idx]['AQL_SAMPLE_CNT']);
                    idx--;
                }
            }else if(iterator == "D"){
                while(iterator != "OK"){
                    iterator = filterDsAql[idx]['ACCESS_PATH'];
                    rtnSamCnt = Number(filterDsAql[idx]['AQL_SAMPLE_CNT']);
                    idx++;
                }
            }else{
                rtnSamCnt = Number(filterDsAql[idx]['AQL_SAMPLE_CNT']);
            }
            if(Number(lotSizeVal) < rtnSamCnt){
                rtnSamCnt = lotSizeVal;
            }
        }else{
            rtnSamCnt = samCnt;
        }
        return rtnSamCnt;
    };

    /*
     *	Spec Info 조회
     *  strSvcId = "qmvInspectRcrdInfoRegSpecSelect";
     */
    function fn_specInfoSelect(){
        const requestJson: NexacroJsonType = {
            ds_Part: ds_Part
        }
        sendAxios('/common/nexacro/QMV_P_OUT_InspectRcrdInfoRegSpecSelect.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
            .then((response) => {
                tempDsSpec = response?.data.ds_Spec;
                setDsSpec(response?.data.ds_Spec);
                qmvInspectRcrdInfoRegSpecSelect(tempDsSpec);
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
    }

    function fn_vndRcrdDetailReg(){
        let copyArray = [...ds_Part];
        copyArray[0]['ORG_LOT_SIZE'] = tempLotSize;
        setDsPart(copyArray);

        let param = new Map();
        param.set('pv_flag','INS');
        param.set('pv_part', copyArray); //ds_Part의 카피본
        param.set('pv_spec', ds_Spec);
        param.set('pv_menuId01','QMV_M_Out_InspectList');
        param.set('pv_ecnList', ds_chngPoingHList);
        param.set('pv_status_N','INFO');

        pageMove(process.env.REACT_APP_URL_QMV_INFO || '', {
            PARAM: param
        })
    }

    function qmvInspectRcrdInfoRegLotNoSelect(params: any) {
        if (params.length > 0) {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage('MSG01056'), // 동일한 Lot No가 존재합니다. Lot No를 확인하세요.
                confirm: () => {
                },
            });
            return;
        }else {
            //매핑 대상
            if(ds_Part.length == 0){
                ds_Part.push({
                    TSI_AGENT_YN: ds_Part[0]['TSI_AGENT_YN']
                })
            }else{
                ds_Part[0]['TSI_AGENT_YN'] = ds_Part[0]['TSI_AGENT_YN']
            }

            // if (ds_Part[0]['TSI_AGENT_YN'] == 'Y') {
            //AGENT 연계 팝업 열기
            //fn_openAgentDataMapping();
            // } else {
            //미연계시
            // if (this.div_Main.form.rdo_agentYn.value == "02" && this.gfn_isNull(this.div_Main.form.edt_reason.value)) {
            //     this.gfn_alert("MSG01360"); //Agent Data 미연계할 경우, 미연계 사유 입력은 필수 항목입니다.
            //     return;
            // }
            //AGENT 미연계, 비대상
            //23.09.20 현업요청(이성우 프로)
            //모바일에는 AGENT 연계 팝업X(TSI부품군일 경우 AGENT 미연계로 등록, 사유 MOBILE)
            fn_specInfoSelect();
            // }
        }
    }

    function qmvInspectRcrdInfoRegSpecChk(param:any) { //자재 spec 조회 - > 샘플 수량 조회
        if (gfn_isNull(param)) {
            //alert("해당 자재에 대한 측정항목이 없습니다.");
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage('MSG00939'), //해당 자재에 대한 측정항목이 없습니다.
                confirm: () => {
                    setPart({});
                    setSelectedIdx(-1);
                },
            });
        } else {
            setHaveSpec(true);
            fn_searchTsiAgentYn();
        }
    }

    /**
     * @description ECN 정보 조회
     */
    async function fn_searchEcn() {
        if(ds_search.length == 0){
            ds_search.push({
                TYPE: '04', //20.07.16 by KKA  검색조건 : 적용일(01) -> 입고예정일(04)로 변경
                FROM_DATE: getToday(0,0,-2), //12.06 JKA ECN 적용일 24개월 이전부터 조회
                TO_DATE: getToday(0,1,0),  //07.18 JKA ECN 적용일 1개월 이후까지 조회
                VENDOR_CD : ds_Part[0]['VENDOR_CD'],
                MATERIAL_CD : ds_Part[0]['PART_CD'],
                RECORDS : 100,
                RECORDSOFFSET : '0',
                SEARCH_TYPE : 1,
                LANG : sLocale
            })
        }else{
            ds_search[0]['TYPE'] = '04';
            ds_search[0]['FROM_DATE'] = getToday(0,0,-2);
            ds_search[0]['TO_DATE'] = getToday(0,1,0);
            ds_search[0]['VENDOR_CD'] = ds_Part[0]['VENDOR_CD'];
            ds_search[0]['MATERIAL_CD'] = ds_Part[0]['PART_CD'];
            ds_search[0]['RECORDS'] = 100;
            ds_search[0]['RECORDSOFFSET'] = '0';
            ds_search[0]['SEARCH_TYPE'] = 1;
            ds_search[0]['LANG'] = sLocale;
        }

        if(ds_division.length == 0){
            ds_division.push({
                DIVISION_CD: ds_Part[0]['DIVISION_CD']
            })
        }else{
            ds_division[0]['DIVISION_CD'] = ds_Part[0]['DIVISION_CD'];
        }

        if(ds_plant.length == 0){
            ds_plant.push({
                PLANT_CD: ds_Part[0]['PLANT_CD']
            })
        }else{
            ds_plant[0]['PLANT_CD'] = ds_Part[0]['PLANT_CD'];
        }

        const requestJson: NexacroJsonType = {
            ds_search: ds_search,
            ds_plant: ds_plant,
            ds_division: ds_division
        }

        return await sendAxios('/common/nexacro/selectTotalStatusList.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
    };

    function selectTotalStatusList(param:any) {
        // let rowIdx = 0;
        let ds_popChngPoingHList = [...param] || [];
        //팝업데이터에 맞게 데이터 파싱
        for (let i = 0; i < param.length; i++) {
            // let ds_popChngPoingHList = [...param];
            ds_popChngPoingHList[i]['HQ_INSP_YN'] = 'Y';
            ds_popChngPoingHList[i]['ECN_NO'] = param[i]['EC_NO'];
            ds_popChngPoingHList[i]['PART_CD'] = param[i]['ITEM_CODE'];
        }
        //대기나 지연상태이고 생산일이 등록 안된경우, 진행중이지만 결과가 불합격한경우 추가
        let ds_popChngPoingHListCnt = ds_popChngPoingHList.filter(result => result.STATUS == '1' || result.STATUS == '5' || (result.STATUS == '2' && (result.MESAS_RESULT_CD == 'B' || result.MESAS_RESULT_CD == 'R'))).length; // && (STATUS =='1' || STATUS=='5') 대기, 지연

        if (ds_popChngPoingHListCnt > 0) {
            if (ds_Part[0]['NEW_PART_TYPE'] == 'C' || ds_Part[0]['NEW_PART_TYPE'] == 'O') {
                // ECN이 있으면 변경초물로 변경 나머지는 파트마스터에서 가져옴
                // 2020.06.19 LEJ 신규초물이면 변경초물로 변경하지 않음
                ds_Part[0]['NEW_PART_TYPE'] = 'C';
                //ds_chngPoingHList.updateSortGroup("S:-ECR_END_DATE");

                //입고예정일 전인 경우 - 조회팝업
                if (fn_chkStockPlanDate()) {
                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: getMessage("MSG01495"), //해당 부품은 변경점이\n발생된 부품입니다.\n\n부품정보 탭에서 변경내용 및 적용여부를 확인하여 주십시오.
                        confirm: () => {
                            // setPart({});
                            // setSelectedIdx(-1);
                        }
                    });
                } else {
                    // if (fn_chkStockPlanDate()) {
                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: getMessage("MSG01494"), //해당 부품은 변경점이 발생된 부품으로 초물 입고예정일이 초과되어 출하성적서를 작성할 수 없습니다.\n\nSQCI Web 버전 변경점 내역에서 입고예정일을 확인하여 주십시오.
                        confirm: () => {
                            setPart({});
                            setSelectedIdx(-1);
                        }
                    });
                    // }
                }
            }
        }
        // }
    }

    function fn_chkStockPlanDate() {
        let sStockPlanDt = "";
        let today  = getToday();

        for(let i=0; i < tempDsChngPoingHList.length; i++) {
            sStockPlanDt = String(tempDsChngPoingHList[i]['STOCK_PLAN_DATE']);
            let nDiff = fn_getDiffDay(today,sStockPlanDt);

            //입고예정일이 하나라도 지난경우
            if(nDiff <= 0) {
                return false;
            }
        }
        //입고예정일이 전부 전인경우 경우
        return true;
    };

    function fn_getDiffDay(d1:string,d2:string){
        let vfromDt = gfn_n_today_yyyy_mm_dd(d1);
        let vToDt = gfn_n_today_yyyy_mm_dd(d2);

        let date1 = new Date(vfromDt);
        let date2 = new Date(vToDt);

        let diffDate = date2.getTime() - date1.getTime();
        let nDay = 1000 * 60 * 60 * 24;

        diffDate = (diffDate / nDay);
        if (diffDate < 0){
            diffDate = diffDate - 1;
        }
        else {
            diffDate = diffDate + 1;
        }

        return diffDate;
    }

    function fn_searchTsiAgentYn() {
        const requestJson: NexacroJsonType = {
            ds_Part : ds_Part
        }
        sendAxios('/common/nexacro/searchTsiAgentYn.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
            .then(response => {
                let tempDsTsiAgentYn = response?.data.ds_tsiAgentYn;
                setDsTSiAgentYn(tempDsTsiAgentYn);
                searchTsiAgentYn(tempDsTsiAgentYn);
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
    };

    function searchTsiAgentYn(param:any){
        //TSI 부품군이면
        if (param[0]['TSI_AGENT_YN'] == 'Y' || param[0]['TSI_AGENT_YN'] == 'O') {
            //23.09.20 현업요청(이성우프로) 모바일에서는 Agent data연계 팝업이 없는 대신 agent미연계, 미연계사유 모바일로 입력
            ds_Part[0]['AGENT_ASSOCIATION'] = '02';  //agent 미연계
            ds_Part[0]['REASON'] = 'MOBILE';
            ds_Part[0]['TSI_AGENT_YN'] = 'Y';
            //fn_selectQRCheck();
            setDsPart(ds_Part);
        } else {
            ds_Part[0]['AGENT_ASSOCIATION'] = '03'; //agent 비대상
            ds_Part[0]['TSI_AGENT_YN'] = 'N';
            setDsPart(ds_Part);
        }
    }

    /* 날짜 계산 */
    function getToday(d: number = 0, m: number = 0, y: number = 0){
        let date = new Date();

        date.setFullYear(date.getFullYear() + y);
        date.setMonth(date.getMonth() + m);
        date.setDate(date.getDate() + d);

        let year = date.getFullYear();
        let month = ("0" + (1 + date.getMonth())).slice(-2);
        let day = ("0" + date.getDate()).slice(-2);

        return year + month + day;
    };

    /**
     * @class String타입의 형식을 날짜형식으로 변환
     * @param sFdate   sDate  8자리 형식으로된 날짜. yyyyMMdd의 형식으로 입력됩니다.
     * @return Date
     */
    function fn_strToDate(sDate:string) {
        let nYear = parseInt(sDate.substr(0, 4));
        let nMonth = parseInt(sDate.substr(4, 2)) - 1;
        let nDate = parseInt(sDate.substr(6, 2));

        return new Date(nYear, nMonth, nDate);
    }

    function numberExceptComma(num:string) {
        return num.replace(/[^\d]+/g, '');
    }

    function fn_dateFormatToString(num:string){
        let date = num.substring(0,4)+num.substring(5,7)+num.substring(8,10);
        return date;
    }

    /* 부품명 선택시, 사업부, plant, 협력사 체크 */
    function fn_doSearchPart(){
        let messageList: string[] = [];
        if(!division?.DIVISION_CD) {
            messageList.push(getDomain('DOMAIN0222'));
        }
        if(!plant?.PLANT_CD) {
            messageList.push(getDomain('DOMAIN2426'));
        }
        if(!vendor?.HQ_CD) {
            messageList.push(getDomain('DOMAIN0372'));
        }

        if(
            gfn.gfn_isNull(division?.DIVISION_CD)
            || gfn.gfn_isNull(plant?.PLANT_CD)
            || gfn.gfn_isNull(vendor?.HQ_CD)
        ) {
            setDialog({
                show: true,
                type: 'ALERT',
                text: messageList.join('/').concat(getMessage('MSG01542')),
                confirm: () => {}
            })
        }else{
            return doSearchPart('FAVORITES', [], selectedPart, undefined,
                undefined,
                division ? division.DIVISION_CD ? String(division.DIVISION_CD) : undefined : undefined,
                plant ? plant.PLANT_CD ? String(plant.PLANT_CD) : undefined : undefined,
                vendor ? vendor.HQ_CD ? String(vendor.HQ_CD) : undefined : undefined,
            );
        }
    }

    /*
     *	출하검사 성적서 작성 버튼 클릭 시
     */
    async function fn_qmvAddValid(){
        tempLotSize = numberExceptComma(lotSize);
        setLotSize(tempLotSize);
        ds_Part[0]['LOT_NO'] = lotNo;
        ds_Part[0]['LOT_SIZE'] = Number(tempLotSize);

        let nDiff = fn_getDiffDay(fv_today, datePickerFrom);

        if(nDiff > 1 || nDiff < -2){
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG00367"), //생산일자는 현재일부터 1일 이내로만 입력하세요.
                confirm: () => {
                }
            });
            return;
        }

        // 랏사이즈
        if(!checkLotSize()){
            return false;
        }
        // spec 없음
        if (!haveSpec) {
            return false;
        }

        //입고예정일 ecn 인데 입고 예정일이 지난 경우 수정팝업 열기팝업
        if(!fn_chkStockPlanDate() && (ds_Part[0]['NEW_PART_TYPE'] == 'C')){
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG01494"), //해당 부품은 변경점이 발생된 부품으로 초물 입고예정일이 초과되어 출하성적서를 작성할 수 없습니다.\n\nSQCI Web 버전 변경점 내역에서 입고예정일을 확인하여 주십시오.
                confirm: () => {
                }
            });
        }

        if (ds_Part[0]['VENDOR_CD'] == ''){
            ds_Part[0]['VENDOR_CD'] = ds_Vendor[0]['HQ_CD'];
            // setDsPart(ds_Part);
        }

        ds_Part[0]['PROD_DT'] = datePickerFrom;
        setDsPart(ds_Part);

        await fn_lotNoSelect().then((response) => {
            tempDsLotNo = response?.data.ds_LotNo;
            setDsLotNo(response?.data.ds_LotNo);
            qmvInspectRcrdInfoRegLotNoSelect(tempDsLotNo);
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    function checkLotSize() {
        let sMaxLotSize = Number(ds_Part[0]['MAX_LOT_SIZE']);
        let sLotSize = Number(ds_Part[0]['LOT_SIZE']);

        if(sLotSize > sMaxLotSize){
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG01062"), //Lot Size는 기준보다 작은 값을 입력하세요.
                confirm: () => {
                }
            });
            return false;
        } else {
            return true;
        }
    };

    const selectedDivision = (value: any) => {
        setDivisionNm(value.DIVISION_NM);
        setDivisionCd(value.DIVISION_CD);
        if(ds_Part.length == 0){
            vds_Part.push({
                DIVISION_CD: sessionValues?.DIVISION_CD,
                DIVISION_NM: sessionValues?.DIVISION_NM,
                PLANT_CD: sessionValues?.PLANT_CD,
                PLANT_NM: '['+sessionValues?.PLANT_CD+'] '+sessionValues?.PLANT_NM,
                VENDOR_CD: sessionValues?.HQ_CD,
                VENDOR_NM: '['+sessionValues?.HQ_CD+'] '+sessionValues?.HQ_NM,
                LANG: sLocale,
                INSP_GB: 3
            })
            setDsPart(vds_Part);
        }else if (ds_Part && ds_Part.length > 0) {
            ds_Part[0]['DIVISION_CD'] = String(value.DIVISION_CD || '');
            ds_Part[0]['DIVISION_NM'] = String(value.DIVISION_NM || '');
            setDsPart(ds_Part);
        }
        setDivision(value);
        setPlant({});
        setVendor({});
        setPart({});
    };

    const selectedPartnerDivision = (value: any) => {
        setDivisionNm(value.DIVISION_NM);
        setDivisionCd(value.DIVISION_CD);
        setDivision(value);
        setPlant({});
        setPart({});
        ds_Part[0]['DIVISION_CD'] = value.DIVISION_CD;
        ds_Part[0]['DIVISION_NM'] = String(value.DIVISION_NM);
        setDsPart(ds_Part);
    };

    const selectedPlant = (value: any) => {
        setPlantNm(value.PLANT_PROD_NM);
        setPlantCd('['+value.PLANT_CD+']');
        setPlant(value);
        setVendor({});
        setPart({});
        ds_Part[0]['PLANT_CD'] = String(value.PLANT_CD);
        ds_Part[0]['PLANT_NM'] = String(value.PLANT_NM);
        setDsPart(ds_Part);
    };

    const selectedPartnerPlant = (value: any) => {
        setPlantNm(value.PLANT_PROD_NM);
        setPlantCd('['+value.PLANT_CD+']');
        setPlant(value);
        setPart({});
        ds_Part[0]['PLANT_CD'] = String(value.PLANT_CD);
        ds_Part[0]['PLANT_NM'] = String(value.PLANT_NM);
        setDsPart(ds_Part);
    };

    const selectedVendor = (value: any) => {
        setHqNm(value.HQ_NM);
        setHqCd('['+value.HQ_CD+']');
        setVendorCd('['+value.HQ_CD+']');
        setVendorNm(value.HQ_Nm);
        setVendor(value);
        setPart({});
        ds_Part[0]['VENDOR_CD'] = String(value.HQ_CD);
        ds_Part[0]['VENDOR_NM'] = String('['+value.HQ_CD+'] '+value.HQ_NM);
        setDsPart(ds_Part);
    };

    const selectedPart = (value: any) => {
        setPartCd('['+value.PART_CD+ ']');
        setPartNm(value.PART_NM);
        setPart(value);
        let copyArray = [...ds_Part];
        copyArray[0]['PART_CD'] = String(value.PART_CD);
        copyArray[0]['PART_NM'] = String(value.PART_NM);
        setDsPart(copyArray);
        COM_P_Com_PartList(value);
    };

    const handleLotNoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const cntVal = e.target.value.replace(/[^가-힣\w\s]/g, "");
        setLotNo(cntVal);
        tempLotNo = cntVal;
    };

    const handleLotSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const cntVal = e.target.value.replace(/[^0-9]/g, "");
        // setLotSize(cntVal.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","));
        setLotSize(cntVal.replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    };

    /* 달력 교체 시 */
    function changeDatePicker(e: any) {
        let val = e.target.value.replaceAll('-', '').replaceAll('.', '');
        setDatePickerFromWithDash(e.target.value);
        setDatePickerFrom(val)
    }

    function btnEvent(){
        if(selectedIdx == -1){
            return <button type={'button'} className={'button-primary'} onClick={() => fn_qmvAddValid()} disabled>{getDomain('DOMAIN4597')}</button>
        }else{
            return <button type={'button'} className={'button-primary'} onClick={() => fn_qmvAddValid()}>{getDomain('DOMAIN4597')}</button>
        }
    }

    return (
        <>
            {searchComponent}
            <div className={'container'}>
                <div className={'contents-wrap'}>
                    <ul className={'reports-list'}>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> {getDomain('DOMAIN0222')} {/* 사업부 */}
                            </span>
                            { 1 === (sessionValues?.WORK_GROUP_CD || 0) ? //협력사계정일 경우
                                <>
                                    <div role={'button'} onClick={() => doSearch({
                                        show: true,
                                        title: getDomain('DOMAIN0222'),
                                        type: 'RADIO',
                                        props: {
                                            target: divisionList || [],
                                            display: {
                                                name: 'DIVISION_NM',
                                                value: 'DIVISION_CD',
                                                strong: ''
                                            }
                                        },
                                        filter: [{
                                            DIVISION_CD: vdivision
                                        }],
                                        onSelect: selectedPartnerDivision
                                    })}>
                                        <p>{division?.DIVISION_NM}</p>
                                        <button type={'button'} className={'btn-link'}>
                                            <i className={'hidden'}>이동</i>
                                        </button>
                                    </div>
                                </>
                                :
                                ((
                                        2 === (sessionValues?.WORK_GROUP_CD || 0)  ||
                                        3 === (sessionValues?.WORK_GROUP_CD || 0) ||
                                        4 === (sessionValues?.WORK_GROUP_CD || 0) ||
                                        5 === (sessionValues?.WORK_GROUP_CD || 0)
                                    ) ? //시업부관리자 또는 법인검사자
                                        <div role={'button'} onClick={() => doSearchDivision('RADIO', selectedDivision,undefined, [{
                                            DIVISION_CD: gfn.gfn_tostring(sessionValues?.DIVISION_CD)
                                        }])}>
                                            <p>{division?.DIVISION_NM}</p>
                                            <button type={'button'} className={'btn-link'}>
                                                <i className={'hidden'}>{getDomain('DOMAIN4561')}{/*이동*/}</i>
                                            </button>
                                        </div>
                                        :
                                        <div role={'button'} onClick={() => doSearchDivision('RADIO', selectedDivision,undefined)}>
                                            <p>{division?.DIVISION_NM}</p>
                                            <button type={'button'} className={'btn-link'}>
                                                <i className={'hidden'}>{getDomain('DOMAIN4561')}{/*이동*/}</i>
                                            </button>
                                        </div>
                                )
                            }
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> {getDomain('DOMAIN2426')} {/* Plant */}
                            </span>
                            { 1 === (sessionValues?.WORK_GROUP_CD || 0) ? //협력사계정일 경우
                                <>
                                    <div role={'button'} onClick={() => doSearch({
                                        show: true,
                                        title: getDomain('DOMAIN2426'),
                                        type: 'RADIO',
                                        props: {
                                            target: plantList || [],
                                            display: {
                                                name: 'PLANT_PROD_NM',
                                                value: 'PLANT_CD',
                                                strong: 'PLANT_CD'
                                            }
                                        },
                                        filter: [{
                                            DIVISION_CD: vdivision
                                        }, {
                                            PLANT_CD: vPlantList
                                        }],
                                        onSelect: selectedPartnerPlant
                                    })}>
                                        <p>{plant?.PLANT_CD ? '['.concat(String(plant?.PLANT_CD), ']') : ''}<br/>{String(plant?.PLANT_PROD_NM || '').replace(regexPlant, '$2')}</p>
                                        <button type={'button'} className={'btn-link'}>
                                            <i className={'hidden'}>이동</i>
                                        </button>
                                    </div>
                                </> :
                                <>
                                    <div role={'button'} onClick={() => doSearchPlant('RADIO', selectedPlant, undefined,[{
                                        DIVISION_CD:[division?.DIVISION_CD]
                                    }])}>
                                        <p>{plant?.PLANT_CD ? '['.concat(String(plant?.PLANT_CD), ']') : ''}<br/>{String(plant?.PLANT_PROD_NM || '').replace(regexPlant, '$2')}</p>
                                        <button type={'button'} className={'btn-link'}>
                                            <i className={'hidden'}>이동</i>
                                        </button>
                                    </div>
                                </>
                            }
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> {getDomain('DOMAIN0372')} {/* 협력사 */}
                            </span>
                            {/*<p>{vendor?.HQ_CD ? '['.concat(String(vendor?.HQ_CD), ']') : ''}<br/>{vendor?.HQ_NM}</p>*/}
                            { 1 === (sessionValues?.WORK_GROUP_CD || 0) ? //협력사계정일 경우
                                <>
                                    <div className={'text-item'}>
                                        <p>{sessionValues?.WORK_GROUP_CD == 1 ? '['.concat(String(sessionValues?.HQ_CD), ']') : ''}<br/>{sessionValues?.WORK_GROUP_CD == 1 ? sessionValues?.HQ_NM : ''}
                                        </p>
                                    </div>
                                </> :
                                <>
                                    <div className={'text-item'} role={'button'} onClick={() => doSearchVendor('FAVORITES', hqList || [], selectedVendor)}>
                                        <p>{vendor?.HQ_CD ? '['.concat(String(vendor?.HQ_CD), ']') : ''}<br/>{vendor?.HQ_NM}</p>
                                        <button type={'button'} className={'btn-link'}>
                                            <i className={'hidden'}>이동</i>
                                        </button>
                                    </div>
                                </>
                            }
                        </li>
                        <li className={'text-item'}>
                            <span className={'title'} dangerouslySetInnerHTML={{__html:"<strong class='required'>*</strong> "+getDomain('DOMAIN4596')}}></span> {/* 부품명 */}
                            <div className={'item-wrap ellipsis1'} role={'button'} onClick={() => fn_doSearchPart()}>
                                <p className={'item-wrap ellipsis1'}>{part?.PART_CD ? '['.concat(String(part?.PART_CD), ']') : ''}<br/><strong className={'item-wrap ellipsis1'}>{part?.PART_NM}</strong></p>
                                <button type={'button'} className={'btn-link'}>
                                    <i className={'hidden'}>이동</i>
                                </button>
                            </div>
                        </li>
                        <li className={'form-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> {getDomain('DOMAIN2602')}  {/* 생산일자 */}
                            </span>
                            <div className={'datepicker-wrap'}>
                                {/*{datePicker?.datePicker}*/}
                                <input type={'date'} style={{WebkitAppearance:"none"}} required={true} pattern={'\d{4}-\d{2}-\d{2}'} defaultValue={datePickerFromWithDash} onChange={(e) => changeDatePicker(e)}/>
                            </div>
                        </li>
                        <li className={'form-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> {getDomain('DOMAIN2027')} {/* Lot No. */}
                            </span>
                            <div>
                                <input type={'text'} onInput={handleLotNoChange} value={lotNo} placeholder={getDomain('DOMAIN2027')+' Limit : 20'}/>
                            </div>
                        </li>
                        <li className={'form-item'}>
                            <span className={'title'}>
                                <strong className={'required'}>*</strong> {getDomain('DOMAIN2028')} {/* Lot Size */}
                            </span>
                            <div>
                                <input type={'text'} inputMode={"numeric"} onInput={handleLotSizeChange} value={lotSize} maxLength={10} placeholder={getDomain('DOMAIN4701')+' : '+lotLimit}/>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className={'btn-area bottom'}>
                    {btnEvent()}
                </div>
            </div>
        </>
    );
};