import React, {useEffect, useState} from 'react';
import {useLocation, useOutletContext} from 'react-router-dom';
import useAxios, {AXIOS_HEADER} from '../../hooks/useAxios';
import {useError} from '../../hooks/useException';
import {unescape} from 'lodash';
import {ComponentHistoryType} from '../../components/model/SqciTypes';

export default function Terms() {
    const location = useLocation();
    const {sendAxios} = useAxios();
    const {throwException} = useError();
    const [terms, setTerms] = useState<string>();

    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();
    useEffect(() => {
        if((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            sendAxios('/common/anonymous/privacyPolicy/mobileViewTerms.do', 'POST', AXIOS_HEADER.JSON, {
                langType: location.state.userLang || 'EN',
                partId: location.state.partId || 'AU4ASjv6AAFlNm02'
            })
            .then(response => {
                setTerms(unescape(response?.data.terms || ''));
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {});
            });
        }
    }, [enteredComponent, exitedComponent]);

    return (
        <>
            <div className={'container'}>
                <div className={'privacy-wrap'}>
                    <div className={'privacy-statement'} style={{overflow: 'auto'}} dangerouslySetInnerHTML={{__html: terms || ''}}></div>
                </div>
            </div>
        </>
    );
};