/**************************************************************************************************
 * 01. 업무구분	    : P-SIS > 검사결과 조회
 * 02. 프로그램ID	: PsisInspResultList.tsx
 * 03. 프로그램명	    : P-SIS > 검사결과 조회
 * 04. 화면설명	    : P-SIS > 검사결과 조회
 * 05. 작성일		: 2023.10.04
 * 06. 작성자		: YGY
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-10-04     YGY    최초 작성
 **************************************************************************************************
 */
/******************************************************************************************
 *                                        변경이력                                         *
 *----------   ----------   ------------   ------------------------------------------------*
 * 변경번호     변경일자       변경자                        변경내용                      *
 *----------   ----------   ------------   ------------------------------------------------*

 ******************************************************************************************/

import React, {useEffect, useState} from 'react';
import {useDataset} from '../../../components/contexts/CommonContext';
import useAxios, {AXIOS_HEADER} from '../../../hooks/useAxios';
import {useError} from '../../../hooks/useException';
import useHtmlElement from '../../../hooks/useHtmlElement';
import {ComponentHistoryType, NexacroJsonType, NexacroJsonUnitType} from '../../../components/model/SqciTypes';
import usePageMove from "../../../hooks/usePageMove";
import {useComponent} from "../../../components/contexts/ComponentContext";
import {useSearch} from '../../../hooks/useSearch';
import useStorage from '../../../hooks/useStorage';
import useGlobalData from '../../../hooks/useGlobalData';
import useDatePicker from '../../../hooks/useDatePicker';
import {STORAGE_NAME} from "../../../components/CommonConstants";
import {useLocation, useOutletContext} from "react-router-dom";
import moment from "moment";

export default function PsisInspResultList() {
    const {dataset, sessionValues} = useDataset();
    const {sendAxios} = useAxios();
    const location = useLocation();
    const {throwException} = useError();
    const {pageMove} = usePageMove();
    const {getGlobalSetting, setGlobalSetting, getDomain, getMessage} = useGlobalData();
    const gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    const {useSelectBox, useInput} = useHtmlElement();
    const {setDialog} = useComponent();
    const datePicker = useDatePicker('SINGLE');
    const {
        doSearch,
        searchComponent,
        doSearchDivision,
        doSearchPlant,
        doSearchVendor,
        doSearchPart
    } = useSearch();
    const {getDocumentHistory} = useStorage();
    const regexPlant: RegExp = /^(\[\w+\])(.*)$/gi;

    let [hqInspYnNm, setHqInspYnNm] = useState<string | undefined>(getDocumentHistory('hqInspYnNm'));
    let [hqInspYn, setHqInspYn] = useState<string | undefined>(getDocumentHistory('hqInspYn'));
    const [divisionList, setDivisionList] = useState<NexacroJsonUnitType[]>([]);
    const [plantList, setPlantList] = useState<NexacroJsonUnitType[]>([]);
    const [vendorList, setVendorList] = useState<NexacroJsonUnitType[]>();
    const [dataList, setDataList] = useState<NexacroJsonUnitType[]>();
    const [division, setDivision] = useState<NexacroJsonUnitType | undefined>(getDocumentHistory('division'));
    const [plant, setPlant] = useState<NexacroJsonUnitType | undefined>(getDocumentHistory('plant'));
    const [vendor, setVendor] = useState<NexacroJsonUnitType | undefined>(getDocumentHistory('hqCd'));
    const [insertDt, setInsertDt] = useState<NexacroJsonUnitType | undefined>(getDocumentHistory('insertDt'));
    const [sInsertDt, setSInsertDt] = useState(String);
    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();
    const sLocale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);

    useEffect(() => {
        if ((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            let today = moment().format('YYYY-MM-DD');
            //let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            setSInsertDt(today);

            let sInspYnNm = getDocumentHistory('hqInspYnNm') !== undefined ? getDocumentHistory('hqInspYnNm') : 'ALL';
            let sInspYn = getDocumentHistory('hqInspYn') !== undefined ? getDocumentHistory('hqInspYn') : 'A';
            setHqInspYnNm(sInspYnNm);
            setHqInspYn(sInspYn);

            fn_onload();
        }
    }, [enteredComponent, exitedComponent]);



    async function fn_onload(){
        let plantList:any[] = [];
        if(sessionValues?.WORK_GROUP_CD == 2 || sessionValues?.WORK_GROUP_CD == 3 || sessionValues?.WORK_GROUP_CD == 4 || sessionValues?.WORK_GROUP_CD == 5) {
            await fn_selectPlantList().then(response => {
                plantList = response?.data.ds_Plant;
                plantList = plantList.filter((v: any) => v.LV == '4');
            }).catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
        }

        await fn_selectPsisVendorList(plantList);
    }

    async function fn_selectPsisVendorList(plantList:any[]){
        sendAxios('/common/nexacro/selectPsisVendorList.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_Search: [{
                DIVISION_CD: '',
                HQ_CD: (sessionValues?.WORK_GROUP_CD || 0) === 1 ? sessionValues?.HQ_CD : vendor?.HQ_CD,
                USE_YN: ''
            }]
        }).then(response => {
            let sData = response?.data.ds_vendorList || [];
            let nData= [];

            if(sessionValues?.WORK_GROUP_CD == 2 || sessionValues?.WORK_GROUP_CD == 3 || sessionValues?.WORK_GROUP_CD == 4 || sessionValues?.WORK_GROUP_CD == 5) {
                for (let a = 0; a < plantList.length; a++) {
                    let detail = (sData.filter((v: any) => v.PLANT_CD == plantList[a].CODE));
                    for (let b = 0; b < detail.length; b++) {
                        nData.push(detail[b]);
                    }
                }
            }else{
                nData = sData;
            }

            setPlantList(nData.filter((v: any, i: number, callback: any) => i === callback.findIndex((loc: any) => loc.PLANT_CD === v.PLANT_CD)));
            setDataList(nData);
            setPlant({PLANT_CD: 'all'});
        })
            .catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
    }

    async function fn_selectPlantList() {
        let requestJson: NexacroJsonType = {};

        if(sessionValues?.WORK_GROUP_CD == 2 || sessionValues?.WORK_GROUP_CD == 3){
            requestJson = {
                ds_Search: [{
                    DIVISION_CD : sessionValues?.DIVISION_CD,
                    LANG: sLocale,
                    SHOW_HQ: 'true'
                }]
            }
        }else if(sessionValues?.WORK_GROUP_CD == 4 || sessionValues?.WORK_GROUP_CD == 5){
            requestJson = {
                ds_Search: [{
                    PLANT_CD : sessionValues?.PLANT_CD,
                    AUTH_MANAGER : sessionValues?.WORK_GROUP_CD,
                    AUTH : 'PLACE',
                    LANG: sLocale,
                    SHOW_HQ: 'true'
                }]
            }
        }

        return sendAxios('/common/nexacro/selectPlantList.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson);
    }

    // useEffect(() => {
    //     console.log(sInsertDt);
    // }, [sInsertDt]);

    useEffect(() => {
        if (plant?.PLANT_CD !== 'all') {
            setVendorList(dataList?.filter((v: any) => v.PLANT_CD == plant?.PLANT_CD) || []);
        } else {
            setVendorList(dataList?.filter((v: any, i: number, callback: any) => i === callback.findIndex((loc: any) => loc.HQ_CD === v.HQ_CD)) || []);
        }
    }, [plant]);


    // useEffect(() => {
    //     if (plantList.length === 0) {
    //
    //     }
    // }, [plantList]);

    useEffect(() => {
        if (vendorList) {
            if (vendorList.length > 0) {
                setVendor(vendorList.find(item =>
                    item.PLANT_CD === (plant?.PLANT_CD)
                    && item.HQ_CD === (getDocumentHistory('hqCd') || sessionValues?.HQ_CD) || vendorList[0]));
            } else {
                setVendor(1 === (sessionValues?.WORK_GROUP_CD || 0) ? {
                    HQ_CD: sessionValues?.HQ_CD,
                    HQ_NM: sessionValues?.HQ_NM
                } : {});
            }
        } else {
            setVendor(1 === (sessionValues?.WORK_GROUP_CD || 0) ? {
                HQ_CD: sessionValues?.HQ_CD,
                HQ_NM: sessionValues?.HQ_NM
            } : {});
        }
    }, [vendorList]);


    const selectedPlant = (value: any) => {
        // console.log('selected plant: ', value);
        setPlant(value);
    };

    const selectedVendor = (value: any) => {
        // console.log('selected vendor: ', value);
        setVendor(value);
    };

    const callbackSearchFn = () => {
        let isValid: boolean = false;
        let alertMsg: string = "";
        if (hqInspYn === '') {
            alertMsg = getMessage('MSG01518');
        } else if (plant?.PLANT_CD === undefined) {
            alertMsg = getMessage('MSG01497');
        } else if (vendor?.HQ_CD === undefined) {
            alertMsg = getMessage('MSG01498');
        } else {
            isValid = true;
        }
        if (!isValid) {
            setDialog({
                type: 'ALERT',
                show: true,
                text: alertMsg,
                confirm: () => {
                }
            });
        } else {
            let count: number = 0;
            sendAxios('/common/nexacro/selectTargetPartListByMobile.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_search: [{
                    LANG: gv_locale,
                    RECORDSOFFSET: '0',
                    RECORDS: '1000',
                    STATUS: '02',
                    PLANT_CD: plant?.PLANT_CD == 'all' ? '' : plant?.PLANT_CD,
                    HQ_CD: vendor?.HQ_CD,
                    HQ_INSP_YN: hqInspYn == 'A' ? '' : hqInspYn,
                    INSERT_DT: (sInsertDt || '').replaceAll("-", ""),
                }]
            })
                .then(response => {
                    count = response?.data.ds_inspectResultList.length;
                    if (count > 0) {
                        pageMove(process.env.REACT_APP_URL_PSIS_INFO + '/PsisInspResultInfoList' || '', {
                            plants: plant?.PLANT_CD == 'all' ? '' : plant?.PLANT_CD,
                            hqCd: vendor?.HQ_CD,
                            hqInspYn: hqInspYn,
                            hqInspYnNm: hqInspYnNm,
                            insertDt: (sInsertDt || '').replaceAll("-", "")
                        });
                    } else {
                        alertMsg = getMessage('MSG00930');
                        setDialog({
                            type: 'ALERT',
                            show: true,
                            text: alertMsg,
                        });
                    }
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }

    };

    const selectedValueHqInspYn = (value: any) => {
        setHqInspYnNm(value.CDNM);
        setHqInspYn(value.CD);
    };


    const dateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSInsertDt(e.target.value);
    };

    return (
        <>
            {searchComponent}
            <div className={'container'}>
                <div className={'contents-wrap'}>
                    <ul className={'reports-list'}>
                        {

                            <li className={'text-item'}>
                                <span className={'title'} style={{width: '20%'}}>
                                    Plant
                                </span>
                                <div onClick={() => doSearchPlant('RADIO', selectedPlant, plantList, [], true)}>
                                    <p>{plant?.PLANT_CD === 'all' ? 'ALL' : plant?.PLANT_CD ? '['.concat(String(plant?.PLANT_CD), ']') : ''}<br/>{String(plant?.PLANT_PROD_NM || '').replace(regexPlant, '$2')}
                                    </p>
                                    <button type={'button'} className={'btn-link'}>
                                        <i className={'hidden'}>{getDomain('DOMAIN4561')}</i>
                                    </button>
                                </div>
                            </li>
                        }
                        {
                            <li className={'text-item'}>
                                <span className={'title'} style={{width: '20%'}}>
                                    {getDomain('DOMAIN0372')}
                                </span>
                                <div className={'text-item'}
                                     onClick={() => 1 !== (sessionValues?.WORK_GROUP_CD || 0) ? doSearchVendor('RADIO', vendorList || [], selectedVendor) : null}>
                                    <p>{vendor?.HQ_CD ? '['.concat(String(vendor?.HQ_CD), ']') : ''}<br/>{vendor?.HQ_NM}
                                    </p>
                                    {
                                        1 !== (sessionValues?.WORK_GROUP_CD || 0) ?
                                            <button type={'button'} className={'btn-link'}>
                                                <i className={'hidden'}>{getDomain('DOMAIN4561')}</i>
                                            </button> : null
                                    }
                                </div>
                            </li>
                        }
                        <li className={'text-item'}>
                            <span className={'title'} style={{width: '20%'}}>
                                {getDomain('DOMAIN0848')}
                            </span>
                            <div onClick={() => doSearch({
                                show: true,
                                title: getDomain('DOMAIN0848'),
                                type: 'RADIO',
                                props: {
                                    target: dataset?.ds_CommonCode || [],
                                    display: {
                                        name: 'CDNM',
                                        value: 'CD',
                                        strong: ''
                                    }
                                },
                                filter: [
                                    {CATEGORY: ['SQCI000338']},
                                    {CD: ['A', 'Y', 'N']}, /* Y:입고검사, N:공정검사*/
                                    {LANG: [gv_locale]}
                                ],
                                onSelect: selectedValueHqInspYn
                            })}>
                                <p>{hqInspYnNm}</p>
                                <button type={'button'} className={'btn-link'}>
                                    <i className={'hidden'}>{getDomain('DOMAIN4561')}</i>
                                </button>
                            </div>
                        </li>
                        <li className={'form-item'}>
                            <span className={'title'} style={{width: '20%'}}>
                                <span dangerouslySetInnerHTML={{__html: getDomain('DOMAIN3278')}}></span>
                            </span>
                            {/*<div className={'datepicker-wrap'}>*/}
                            {/*    {datePicker?.datePicker}*/}
                            {/*</div>*/}
                            <input type={'date'} style={{WebkitAppearance:"none"}} required={true} pattern={'\d{4}-\d{2}-\d{2}'} defaultValue={sInsertDt}
                                   onChange={dateChange}/>
                        </li>
                    </ul>
                </div>
                <div className={'btn-area bottom'}>
                    <button type={'button'} className={'button-primary'}
                            onClick={() => callbackSearchFn()}>{getDomain('DOMAIN0312')}
                    </button>
                </div>
            </div>
        </>
    );
};