/**************************************************************************************************
 * 01. 업무구분	    : P-SIS > 검사결과 입력 > 검사결과 리스트
 * 02. 프로그램ID	: PsisInspResultRegList.tsx
 * 03. 프로그램명	    : P-SIS > 검사결과 입력 > 검사결과 리스트
 * 04. 화면설명	    : P-SIS > 검사결과 입력 > 검사결과 리스트
 * 05. 작성일		: 2023.10.04
 * 06. 작성자		: YGY
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-10-04     YGY    최초 작성
 **************************************************************************************************
 */
/******************************************************************************************
 *                                        변경이력                                         *
 *----------   ----------   ------------   ------------------------------------------------*
 * 변경번호     변경일자       변경자                        변경내용                      *
 *----------   ----------   ------------   ------------------------------------------------*

 ******************************************************************************************/

import React, {useEffect, useState} from 'react';
import {useDataset} from '../../../components/contexts/CommonContext';
import useAxios, {AXIOS_HEADER} from '../../../hooks/useAxios';
import {useError} from '../../../hooks/useException';
import useHtmlElement from '../../../hooks/useHtmlElement';
import usePageMove from "../../../hooks/usePageMove";
import {useComponent} from "../../../components/contexts/ComponentContext";
import {useLocation, useOutletContext} from 'react-router-dom';
import {toNumber} from "lodash";
import useGlobalData from "../../../hooks/useGlobalData";
import {STORAGE_NAME} from "../../../components/CommonConstants";
import {ComponentHistoryType} from "../../../components/model/SqciTypes";


/**
 * 대상 부품 조회 에서 입고/공정 검사 건수 조회시에 보여지는 카드뷰 페이지
 * @constructor
 */
export default function PsisInspResultRegList() {
    const {dataset, sessionValues} = useDataset();
    const {getGlobalSetting, setGlobalSetting, getDomain, getMessage} = useGlobalData();
    const gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    const {sendAxios} = useAxios();
    const {throwException} = useError();
    const {pageMove} = usePageMove();
    const {useSelectBox, useInput} = useHtmlElement();
    const {setDialog} = useComponent();
    const location = useLocation();
    const [inspTargetCd, setInspTargetCd] = useState(String);
    const [hqInspYn, setHqInspYn] = useState(String);
    const [inspectList, setInspectList] = useState(new Array<any>());
    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();

    useEffect(() => {
        if ((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            sendAxios('/common/nexacro/selectTargetPartListByMobile.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_search: [{
                    LANG: gv_locale,
                    RECORDSOFFSET: '0',
                    RECORDS: '1000',
                    STATUS: '01',
                    PLANT_CD: location.state.plants === 'all' ? '' : location.state.plants,
                    HQ_CD: location.state.hqCd,
                    HQ_INSP_YN: location.state.hqInspYn
                }]
            })
                .then(response => {
                    setInspectList(response?.data.ds_inspectResultList || []);
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }
    }, [enteredComponent, exitedComponent]);

    /**
     * 카드뷰 삭제 처리
     * @param index
     */
    const callbackDelFn = (e: React.MouseEvent<HTMLButtonElement>, sInspTargetCd: string) => {
        //마우스 클릭 이벤트시, 삭제 클릭이 우선 처리되기 위해 아이템 클릭 하는 것을 방지 하기 위함.
        e.stopPropagation();
        let tmp = inspectList.filter(inspectResult => inspectResult.INSP_TARGET_CD === sInspTargetCd);
        if (tmp[0]?.HQ_INSP_YN === "Y") {
            setDialog({
                type: 'CONFIRM',
                show: true,
                text: getMessage('MSG01503'),
                confirm: () => {
                    //삭제 후에 리로딩 필요.
                    sendAxios('/common/nexacro/updatePSISInspTargetDelY.do', 'POST', AXIOS_HEADER.NEXACRO, {
                        ds_inspectModify: [{
                            INSP_TARGET_CD: sInspTargetCd,
                            UPDATE_BY: sessionValues?.USER_ID
                        }]
                    })
                        .then(response => {
                            setInspectList(inspectList.filter(inspectResult => inspectResult.INSP_TARGET_CD !== sInspTargetCd));
                            if (inspTargetCd === sInspTargetCd) {
                                setInspTargetCd('');
                            }
                        })
                        .catch(e => {
                            throwException(e);
                            return new Promise(() => {
                            });
                        });
                }
            });
        } else if (tmp[0]?.HQ_INSP_YN === "N" && toNumber(tmp[0]?.INSP_CNT) > 0) {
            setDialog({
                type: 'CONFIRM',
                show: true,
                text: getMessage('MSG01508'),
                confirm: () => {
                    tmp[0]["STATUS"] = "02"; //검사완료
                    tmp[0]["INSP_BY"] = sessionValues?.USER_ID;
                    sendAxios('/common/nexacro/updatePSISInspTargetMobile.do', 'POST', AXIOS_HEADER.NEXACRO, {
                        ds_inspTarget: tmp,
                        ds_inspDetail: []
                    })
                        .then(response => {
                            setInspectList(inspectList.filter(inspectResult => inspectResult.INSP_TARGET_CD !== sInspTargetCd));
                            if (inspTargetCd === sInspTargetCd) {
                                setInspTargetCd('');
                            }
                        })
                        .catch(e => {
                            throwException(e);
                            return new Promise(() => {
                            });
                        });
                }
            });
        } else if (tmp[0]?.HQ_INSP_YN === "N" && toNumber(tmp[0]?.INSP_CNT) == 0) {
            let msg = getMessage('MSG01503');
            if (toNumber(tmp[0]?.MAX_INSP_SEQ) > 0) {
                msg = getMessage('MSG01509')
            }
            setDialog({
                type: 'CONFIRM',
                show: true,
                text: msg,
                confirm: () => {
                    //삭제 후에 리로딩 필요.
                    sendAxios('/common/nexacro/updatePSISInspTargetDelY.do', 'POST', AXIOS_HEADER.NEXACRO, {
                        ds_inspectModify: [{
                            INSP_TARGET_CD: sInspTargetCd,
                            UPDATE_BY: sessionValues?.USER_ID
                        }]
                    })
                        .then(response => {
                            setInspectList(inspectList.filter(inspectResult => inspectResult.INSP_TARGET_CD !== sInspTargetCd));
                            if (inspTargetCd === sInspTargetCd) {
                                setInspTargetCd('');
                            }
                        })
                        .catch(e => {
                            throwException(e);
                            return new Promise(() => {
                            });
                        });
                }
            });
        }

    };

    /**
     * 클릭시, 아이템이 선택 되고, css가 active상태로 변경
     * @param sInspTargetCd
     */
    const callbackItemClick = (sInspTargetCd: string, sHqInspYn: string) => {
        setInspTargetCd(sInspTargetCd);
        setHqInspYn(sHqInspYn);
    };


    /**
     * 상세 페이지 callback 이벤트
     */
    const callbackDetail = () => {
        if (inspTargetCd === '') {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage('MSG01504'),
                confirm: () => {
                }
            });
        } else {
            let sMovePage = process.env.REACT_APP_URL_PSIS_INPUT;
            // 입고, 공정에 따라 보여지는 페이지를 다르게 하기 위함.
            const ds_inspect = inspectList.filter((inspect) => inspect.INSP_TARGET_CD === inspTargetCd);
            if (hqInspYn === 'Y') {
                sMovePage = sMovePage + '/PsisInspResultRegIncoming';
            } else {
                sMovePage = sMovePage + '/PsisInspResultRegProcess';
            }
            pageMove(sMovePage || '', {
                ds_inspect: ds_inspect
            });
        }
    };

    return (
        <>
            <div className={'container'}>
                <div className={'card-wrap'}>
                    <ul className={'card-list'}>
                        {
                            inspectList.map((item, index) => {
                                return <li className={'card-item'}
                                           style={{border: inspTargetCd === item?.INSP_TARGET_CD ? '0.3rem solid #3478F5' : '0.3rem solid #D7D7D7'}}
                                           key={'cardView_'.concat(String(index))}
                                           onClick={() => callbackItemClick(item.INSP_TARGET_CD, item.HQ_INSP_YN)}>
                                    <CardViewList data={item}
                                                  callback={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => callbackDelFn(e, item.INSP_TARGET_CD)}/>
                                </li>
                            })
                        }
                    </ul>
                </div>
                <div className={'btn-area bottom'}>
                    <button type={'button'} className={'button-primary'}
                            disabled={inspTargetCd !== '' ? false : true}
                            onClick={() => callbackDetail()}>{getDomain('DOMAIN4564')}
                    </button>
                </div>
            </div>
        </>
    );

    /**
     * 카드뷰 동적 생성 처리
     * @param data
     * @param callback
     * @constructor
     */
    function CardViewList({data, callback}: { data: any, callback: any }) {
        return (
            <>
                <div className={'item'} role={'button'}>
                    <section>
                        <div className={'label-wrap'}>
                            {data.HQ_INSP_YN === 'Y' && (
                                <i className={'label-type17'}>{data.HQ_INSP_YN_NM}</i>
                            )}
                            {data.HQ_INSP_YN === 'N' && (
                                <i className={'label-type16'}>{data.HQ_INSP_YN_NM}({data.INSP_CNT})</i>
                            )}

                            {data.TIME_VALUE === '01' && (
                                <i className={'label-time1'}>{(Number(data.TIME_REMAIN) / 60).toFixed(0)}hr</i>
                            )}
                            {data.TIME_VALUE === '02' && (
                                <i className={'label-time2'}>{(Number(data.TIME_REMAIN) / 60).toFixed(0)}hr</i>
                            )}
                            {data.TIME_VALUE === '03' && (
                                <i className={'label-time3'}>
                                    {
                                        Number((Number(data.TIME_REMAIN) / 60).toFixed(0)) > 0 ? Number(Number(data.TIME_REMAIN) / 60).toFixed(0).concat('hr') : String(Number(data.TIME_REMAIN)).concat('min')
                                    }
                                </i>
                            )}
                        </div>
                        <div className={'title-wrap'}>
                            {data.HQ_INSP_YN === 'Y' && (
                                <span>[{data.PLANT_CD}] [{data.PART_CD}] [{data.HQ_CD_2ND}]</span>
                            )}
                            {data.HQ_INSP_YN === 'N' && (
                                <span>[{data.PLANT_CD}] [{data.PART_CD}]</span>
                            )}
                            <strong>{data.PART_NM.split(';', 1)}</strong>
                        </div>
                    </section>
                    <ul className={'date-list'}>
                        {
                            <li>
                                <em style={{width: '50%'}}>[{data.LINE_CD}] {data.LINE_NM}</em>
                                <span style={{width: '50%'}}>{data.INSERT_DT_EXPR}</span>
                            </li>
                        }
                        {
                            <li>
                                <em style={{width: '50%'}}>{data.SPEC_CNT_EXPR}</em>
                                <span
                                    style={{width: '50%'}}>{getDomain('DOMAIN4730')} {data.TARGET_CNT.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            </li>
                        }
                    </ul>
                    <button type={'button'} className={'button-delete'} onClick={(e) => callback(e)}>
                        <i className={'hidden'}>{getDomain('DOMAIN0229')}</i>
                    </button>
                </div>
            </>
        );
    };
};