/**************************************************************************************************
 * 01. 업무구분	    : P-SIS > 검사결과 입력 > 입고검사 입력
 * 02. 프로그램ID	: PsisInspResultRegIncoming.tsx
 * 03. 프로그램명	    : P-SIS > 검사결과 입력 > 입고검사 입력
 * 04. 화면설명	    : P-SIS > 검사결과 입력 > 입고검사 입력
 * 05. 작성일		: 2023.10.04
 * 06. 작성자		: YGY
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-10-04     YGY    최초 작성
 **************************************************************************************************
 */
/******************************************************************************************
 *                                        변경이력                                         *
 *----------   ----------   ------------   ------------------------------------------------*
 * 변경번호     변경일자       변경자                        변경내용                      *
 *----------   ----------   ------------   ------------------------------------------------*

 ******************************************************************************************/

import React, {useEffect, useRef, useState} from 'react';
import useAxios, {AXIOS_HEADER} from '../../../hooks/useAxios';
import {useError} from '../../../hooks/useException';
import {useLocation, useOutletContext} from 'react-router-dom';
import {useComponent} from "../../../components/contexts/ComponentContext";
import {Swiper, SwiperSlide} from 'swiper/react';
import {A11y, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import {CSSTransition} from 'react-transition-group';
import {floor, toNumber} from "lodash";
import ImageViewer from "./ImageViewer";
import {requestParser, responseParser} from "../../../components/utils/NexacroParseUtil";
import CounterViewer from "./CounterViewer";
import usePageMove from "../../../hooks/usePageMove";
import imageCompression from "browser-image-compression";
import InspResultViewer from "./InspResultViewer";
import {useDataset} from "../../../components/contexts/CommonContext";
import useGlobalData from "../../../hooks/useGlobalData";
import {STORAGE_NAME} from "../../../components/CommonConstants";
import {ComponentHistoryType} from "../../../components/model/SqciTypes";
import {v4 as uuidv4} from "uuid";
import { isMobile, isAndroid, isIOS, CustomView, isSamsungBrowser } from 'react-device-detect';
import * as gfn from "../../../components/utils/CommonUtils";

/**
 * 입고검사 검사결과 입력
 * @constructor
 */
export default function PsisInspResultRegIncoming() {
    const {sendAxios} = useAxios();
    const {getGlobalSetting, setGlobalSetting, getDomain, getMessage} = useGlobalData();
    const gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    const {setDialog} = useComponent();
    const {throwException} = useError();
    const location = useLocation();
    const {pageMove} = usePageMove();
    const {sessionValues, confirmHistoryBack} = useDataset();
    const [inspectList, setInspectList] = useState(new Array<any>());
    const [detailResultList, setDetailResultList] = useState(new Array<any>());
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
    const [resultList, setResultList] = useState(new Array<any>());
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [showImage, setShowImage] = useState<boolean>(false);
    const [showCounter, setShowCounter] = useState<boolean>(false);
    const [imageFileId, setImageFileId] = useState(String);
    const [titleNm, setTitleNm] = useState(String);
    const [counterNum, setCounterNum] = useState(Number);
    const imageRef = useRef<any>([]);
    const reportRef = useRef<HTMLInputElement>(null);
    const inputRef = useRef<{ seq: number; index: number; el: HTMLInputElement}[]>([]);
    const [inspFile, setInspFile] = useState(String);
    const [inspFileId, setInspFileId] = useState(String);
    const [reportFileList, setReportFileList] = useState(new Array<any>());
    const [reportFileMap, setReportFileMap] = useState<any>();
    const [counterMap, setCounterMap] = useState<any>();
    const [isChange, setIsChange] = useState<boolean>(false);
    const [isEnter, setIsEnter] = useState<boolean>(false);
    const [nextIdx, setNextIdx] = useState(Number);
    const [nextMap, setNextMap] = useState<{ seq: number; index: number} | undefined>();
    const [nextData, setNextData] = useState(new Array<any>());
    const [qpointFileId, setQpointFileId] = useState(String);
    const [showInspViewer, setShowInspViewer] = useState<boolean>(false);
    const [pictureFile, setPictureFile] = useState<File>();
    const [inspViewerMap, setInspViewerMap] = useState(new Array<any>());
    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();
    const [gMinHeight, setGMinHeight] = useState(String);

    useEffect(() => {
        if ((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            let sMinHeight = window.innerHeight - 201.6;
            setGMinHeight(String(sMinHeight).concat("px"));
            setShowDetail(false);
            setShowCounter(false);
            setNextIdx(0);
            setInspectList(location.state.ds_inspect);
            let inspCnt = location.state.ds_inspect[0].INSP_CNT != 0 ? location.state.ds_inspect[0].INSP_CNT : '5';
            sendAxios('/common/nexacro/selectPsisDetailResultList.do', 'POST', AXIOS_HEADER.NEXACRO, {
                ds_search: [{
                    LANG: gv_locale,
                    INSP_TARGET_CD: location.state.ds_inspect[0].INSP_TARGET_CD,
                    INSP_CNT: inspCnt,
                    HQ_INSP_YN: location.state.ds_inspect[0].HQ_INSP_YN
                }]
            })
                .then(response => {
                    let sResultList = new Array<any>();
                    let countIdx: number = 0;
                    setDetailResultList(response?.data.ds_detailResultList || []);
                    for (const ds_res of response?.data.ds_detailResultList || []) {
                        for (let i = 1; i <= toNumber(inspCnt); i++) {
                            if (i == 1) {
                                if (ds_res["T_" + 1 + "_FILE_NM"] !== undefined) {
                                    ds_res["FILE_ID"] = ds_res["T_1_FILE_ID"];
                                    ds_res["FILE_NM"] = ds_res["T_1_FILE_NM"];
                                }
                            }
                            sResultList.push({
                                countIdx: countIdx++,
                                INSP_TARGET_CD: ds_res["INSP_TARGET_CD"],
                                INSP_CD: ds_res["INSP_CD"],
                                INSP_NM: ds_res["INSP_NM"],
                                INSP_TYPE: ds_res["INSP_TYPE"],
                                seqVal: toNumber(ds_res["SEQ"]),
                                NUM_YN: ds_res["NUM_YN"],
                                POINT: ds_res["POINT"],
                                MEAS_VAL: ds_res["NUM_YN"] === 'Y' ? ds_res["T_" + i + "_MEAS_VAL"] !== undefined ? floor(toNumber(ds_res["T_" + i + "_MEAS_VAL"]), ds_res["POINT"]).toFixed(ds_res["POINT"]) : undefined : undefined,
                                MEAS_RESULT: ds_res["T_" + i + "_MEAS_RESULT"],
                                FILE_ID: ds_res["T_" + 1 + "_FILE_ID"],
                                FILE_NM: ds_res["T_" + 1 + "_FILE_NM"]
                            });
                        }

                    }
                    setResultList(sResultList);
                    setCounterNum(countIdx);

                    let fileMap: any = {
                        fileId: location.state.ds_inspect[0].FILE_ID,
                        prevFileId: '',
                        fileState: 'S'
                    }
                    setReportFileMap(fileMap);
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }
    }, [enteredComponent, exitedComponent]);

    useEffect(() => {
        if (isChange) {
            confirmHistoryBack(true, getMessage('MSG01510'), () => {
                inspectList[0]["STATUS"] = "01"; //대기 상태
                inspectList[0]["INSP_CNT"] = Math.max(...detailResultList.map(target => target.SAMPLE_CNT)); //샘플 갯수로 검사 개수 최고를 구함? 공정에서는 다르게...

                let inspResultVal = "P";
                if (Math.max(...detailResultList.map(target => target.NG_CNT)) > 0) {
                    inspResultVal = "F";
                }
                inspectList[0]["INSP_RESULT"] = inspResultVal;
                inspectList[0]["INSP_BY"] = sessionValues?.USER_ID;

                sendAxios('/common/nexacro/updatePSISInspTargetMobile.do', 'POST', AXIOS_HEADER.NEXACRO, {
                    ds_inspTarget: inspectList,
                    ds_inspDetail: resultList.filter((result) => (result.MEAS_RESULT !== undefined && result.MEAS_RESULT !== ''))
                })
                    .then(response => {
                        if (response?.data.ds_res[0]?.CODE === "SUCESS") {
                            setDialog({
                                type: 'ALERT',
                                show: true,
                                text: getMessage('MSG01511'),
                                confirm: () => {
                                    pageMove(-1);
                                }
                            });
                        }
                    })
                    .catch(e => {
                        throwException(e);
                        return new Promise(() => {
                        });
                    });
            });
        }
    }, [isChange]);

    /**
     * fileState : S (신규 등록), U(수정)
     */
    useEffect(() => {
        sendAxios('/common/nexacro/commonSelectFileList.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_Search: [{
                FILE_ID: reportFileMap?.fileState === 'S' ? reportFileMap?.fileId : reportFileMap?.prevFileId
            }]
        })
            .then(response => {
                setReportFileList(response?.data.ds_List || []);
                if (response?.data.ds_List.length > 0) {
                    setInspectList(inspectList.map((inspect, idx) => idx === 0 ? {
                        ...inspect,
                        FILE_ID: reportFileMap?.fileState === 'S' ? reportFileMap?.fileId : reportFileMap?.prevFileId
                    } : inspect));
                }

            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
    }, [reportFileMap]);

    /**
     * 이미지 컴포넌트 Close
     */
    const closeImage = () => {
        setShowImage(false);
        setImageFileId("");
    };

    /**
     * 대책서 컴포넌트 Close
     */
    const closeCounter = () => {
        setShowCounter(false);
    };

    /**
     * 대책서 컴포넌트 저장 및 Close
     */
    const closeSaveCounter = (inspCd: string) => {

        for (let i = 0; i < detailResultList.length; i++) {
            if (detailResultList[i]["INSP_CD"] === inspCd) {
                detailResultList[i]["COUNTER_CNT"] = 1;
            }
        }

        setShowCounter(false);
    };

    /**
     * swipe(Detail) 페이지로 이동
     * @param e
     * @param inspIdx
     */
    const callbackSwipe = (e: React.MouseEvent<HTMLAnchorElement>, inspIdx: number) => {
        e.preventDefault();
        setSelectedIndex(inspIdx - 1);
        for (let i = 0; i < resultList.length; i++) {
            if (resultList[i]["NUM_YN"] == 'Y' && resultList[i]["MEAS_VAL"] !== undefined && resultList[i]["MEAS_VAL"] !== '') {
                resultList[i]["MEAS_VAL"] = toNumber(resultList[i]["MEAS_VAL"]).toFixed(toNumber(resultList[i]["POINT"]));
            }

        }
        //setPageTypeCd("swipe");
        setShowDetail(true);
    };


    /**
     * 리스트 페이지로 이동
     * 리스트 페이지로 갈때, detailResultList 값을 다시 세팅함.
     */
    const callbackClose = () => {

        for (let i = 0; i < detailResultList.length; i++) {
            let inspCd = detailResultList[i]["INSP_CD"];
            if(!isChange) {
                if ((toNumber(detailResultList[i]["SAMPLE_CNT"]) !== resultList.filter(result => result.INSP_CD === inspCd && (result.MEAS_RESULT !== undefined)).length)
                    || (toNumber(detailResultList[i]["NG_CNT"]) !== resultList.filter(result => result.INSP_CD === inspCd && (result.MEAS_RESULT === 'NG')).length)) {
                    setIsChange(true);
                }
            }
            detailResultList[i]["SAMPLE_CNT"] = resultList.filter(result => result.INSP_CD === inspCd && (result.MEAS_RESULT !== undefined)).length;
            detailResultList[i]["NG_CNT"] = resultList.filter(result => result.INSP_CD === inspCd && (result.MEAS_RESULT === 'NG')).length;
            if (detailResultList[i]["FILE_ID"] !== undefined) {
                setNextMap(undefined);
                setResultList(resultList.map((result) => result.INSP_CD === detailResultList[i]["INSP_CD"] ? {
                    ...result,
                    FILE_ID: detailResultList[i]["FILE_ID"]
                } : result));
            }
        }



        setShowDetail(false);
    };

    // useEffect(() => {
    //     for (let i = 0; i < detailResultList.length; i++) {
    //         console.log(detailResultList[i]["MEAS_RESULT"]);
    //     }
    //     // if (detailResultList.filter(result => result.MEAS_RESULT !== undefined && result.FILE_ID !== undefined).length === detailResultList.length) {
    //     //     let inspResultVal = "P";
    //     //     if (Math.max(...detailResultList.map(target => target.NG_CNT)) > 0) {
    //     //         inspResultVal = "F";
    //     //     }
    //     //     setInspectList(inspectList.map((result) => result.INSP_TARGET_CD === detailResultList[0]["INSP_TARGET_CD"] ? {
    //     //             ...result,
    //     //             INSP_RESULT: inspResultVal
    //     //         } : result));
    //     // }
    // }, [detailResultList]);


    /**
     * fileId를 가지고서, 이미지 뷰어 처리
     * @param fileId
     */
    const imageViewerCallback = (fileId: string, title: string) => {
        setShowImage(true);
        setImageFileId(fileId);
        setTitleNm(title);
    };


    /**
     * 아이템이 숫자형일때만 callback 처리함.
     * @param e
     * @param dataSeq
     * @param counterIdx
     * @param point
     */
    const onBlurCallback = (e: React.ChangeEvent<HTMLInputElement>, dataSeq: string, index: number,counterIdx: number, point: number) => {
            // let indexNum: number = toNumber(nextIdx);
            // indexNum++
            // setNextIdx(indexNum);
            if (e.target.value != '') {
                if (!isNaN(parseFloat(e.target.value))) {
                    let cntVal = e.target.value;

                    const newInspectResult = detailResultList.filter(inspectResult => inspectResult.SEQ == dataSeq);
                    //숫자형이 아니면 바로 리턴 시킴
                    let measVal: number = toNumber(cntVal);

                    if (isNaN(toNumber(cntVal))) {
                        measVal = 0;
                    } else {
                        if (measVal >= 0) {
                            measVal = floor(measVal, point);
                        } else {
                            measVal = -floor(-measVal, point);
                        }
                    }

                    //기본값은 NG
                    let measResult = "NG";
                    //값이 LSL , USL 안에 들어갈시에는 측정값은 OK로 변경 처리
                    if (isNaN(toNumber(newInspectResult[0].USL))) {
                        if (measVal >= toNumber(newInspectResult[0].LSL)) {
                            measResult = "OK";
                        }
                    } else if (isNaN(toNumber(newInspectResult[0].LSL))) {
                        if (toNumber(newInspectResult[0].USL) >= measVal) {
                            measResult = "OK";
                        }
                    } else {
                        if (toNumber(newInspectResult[0].USL) >= measVal && measVal >= toNumber(newInspectResult[0].LSL)) {
                            measResult = "OK";
                        }
                    }

                    setResultList(resultList.map((result) => result.seqVal == dataSeq && result.countIdx == counterIdx ? {
                        ...result,
                        MEAS_VAL: measVal.toFixed(point),
                        MEAS_RESULT: measResult
                    } : result));
                } else {
                    //2024-07-24 zilong.ge GHCSEC-3998 Add only input - or non-numeric type character scene processing.
                    e.target.value = '';
                    setResultList(resultList.map((result) => result.seqVal == dataSeq && result.countIdx == counterIdx ? {
                        ...result,
                        MEAS_VAL: undefined,
                        MEAS_RESULT: undefined
                    } : result));
                }
            } else {
                setResultList(resultList.map((result) => result.seqVal == dataSeq && result.countIdx == counterIdx ? {
                    ...result,
                    MEAS_VAL: undefined,
                    MEAS_RESULT: undefined
                } : result));
            }
    };

    const inputCallback = (e: React.ChangeEvent<HTMLInputElement>, dataSeq: string, index: number, counterIdx: number, point: number) => {
        const old = resultList.find(result =>result.seqVal == dataSeq && result.countIdx === counterIdx)
        const cntVal= inputChg(e.target.value, old.MEAS_VAL as string);
        e.target.value = cntVal;

        //2024-07-26 zilong.ge GHCSEC-3998 Resolves the problem that every character entered triggers a page refresh that loses focus.
        for(let i=0; i<resultList.length; i++) {
            if (resultList[i].seqVal == dataSeq && resultList[i].countIdx === counterIdx) {
                resultList[i].MEAS_VAL = cntVal;
                resultList[i].MEAS_RESULT = undefined;
                setResultList(resultList);
                break;
            }
        }
    };
 
    const inputChg = (str:String, oldVal:string) =>{
        let val = String(str);
        if (isSamsungBrowser && val.startsWith('.')) {
            val = val.replace('.','-');
        }

        //2024-07-25 zilong.ge GHCSEC-3998 Solve the problem that cannot be deleted after 1e+2 1e2 1e-2 is converted to scientific counting when the number is too large
        if(val.includes('e') && oldVal.includes('e') && oldVal.startsWith(val)) {
            return '';
        }
        //2024-07-24 zilong.ge GHCSEC-3998 Add 01, 001, 00.1 and - 01 - 00, 1-0 0.1 scenario processing
        if (/^0(\d+)/g.test(val) || /^-0(\d+)/g.test(val)) {
            return String(toNumber(val)) || '';
        }

        const regx = /^(\-)?\d+(\.?)(\d+)?$/g
        if(/^(\-)?$/g.test(val) || regx.test(val)) {
            return val;
        } else {
            return oldVal || '';
        }
    };


    const keyPressCallback = (e: React.KeyboardEvent<HTMLInputElement>, dataSeq: number, index: number, counterIdx: number, point: number) => {
        if (e.key == 'Enter') {
            e.preventDefault();
            if (inputRef && inputRef.current.length > 0) {
            const nextTarget = inputRef.current.find(
              (target) => target.seq == toNumber(dataSeq) && target.index == (index+1) && target.el != null
            );
            if (nextTarget?.el) {
                const currentTarget = inputRef.current.find(
                    (target) => target.seq == toNumber(dataSeq) && target.index == (index) && target.el != null
                );
                if (currentTarget?.el?.value != '') {
                    const cntVal = currentTarget?.el.value;
                    const newInspectResult = detailResultList.filter(inspectResult => inspectResult.SEQ == dataSeq);
                    //숫자형이 아니면 바로 리턴 시킴
                    let measVal: number = toNumber(cntVal);

                    if (isNaN(toNumber(cntVal))) {
                        measVal = 0;
                    } else {
                        if (measVal >= 0) {
                            measVal = floor(measVal, point);
                        } else {
                            measVal = -floor(-measVal, point);
                        }
                    }

                    //기본값은 NG
                    let measResult = "NG";
                    //값이 LSL , USL 안에 들어갈시에는 측정값은 OK로 변경 처리
                    if (isNaN(toNumber(newInspectResult[0].USL))) {
                        if (measVal >= toNumber(newInspectResult[0].LSL)) {
                            measResult = "OK";
                        }
                    } else if (isNaN(toNumber(newInspectResult[0].LSL))) {
                        if (toNumber(newInspectResult[0].USL) >= measVal) {
                            measResult = "OK";
                        }
                    } else {
                        if (toNumber(newInspectResult[0].USL) >= measVal && measVal >= toNumber(newInspectResult[0].LSL)) {
                            measResult = "OK";
                        }
                    }

                    setResultList(resultList.map((result) => result.countIdx == counterIdx ? {
                        ...result,
                        MEAS_VAL: measVal.toFixed(point),
                        MEAS_RESULT: measResult
                    } : result));
                } else {

                    setResultList(resultList.map((result) => result.countIdx == counterIdx ? {
                        ...result,
                        MEAS_VAL: undefined,
                        MEAS_RESULT: undefined
                    } : result));
                }
                setNextMap({seq: toNumber(dataSeq), index : (index+1)});
            }
          }
        }
    };

    useEffect(() => {
        if (nextMap !== undefined) {
             const currentTarget = inputRef.current.find(
              (target) => target.seq == nextMap.seq && target.index == nextMap.index  && target.el != null
            );
            if (currentTarget?.el) currentTarget.el.focus();
        }
    },[nextMap]);

    // useEffect(() => {
    //     for (let i = 0; i < resultList.length; i++) {

    //         if (resultList[i]["NUM_YN"] == 'Y' && resultList[i]["MEAS_VAL"] !== undefined && resultList[i]["MEAS_VAL"] !== '') {
    //             const newInspectResult = detailResultList.filter(inspectResult => inspectResult.SEQ == resultList[i]["seqVal"]);

    //             //숫자형이 아니면 바로 리턴 시킴
    //             let measVal: number = toNumber(resultList[i]["MEAS_VAL"]);

    //             if (isNaN(toNumber(resultList[i]["MEAS_VAL"]))) {
    //                 measVal = 0;
    //             } else {
    //                 measVal = floor(measVal, toNumber(resultList[i]["POINT"]));
    //             }

    //             //기본값은 NG
    //             let measResult = "NG";
    //             //값이 LSL , USL 안에 들어갈시에는 측정값은 OK로 변경 처리
    //             if (isNaN(toNumber(newInspectResult[0].USL))) {
    //                 if (measVal >= toNumber(newInspectResult[0].LSL)) {
    //                     measResult = "OK";
    //                 }
    //             } else if (isNaN(toNumber(newInspectResult[0].LSL))) {
    //                 if (toNumber(newInspectResult[0].USL) >= measVal) {
    //                     measResult = "OK";
    //                 }
    //             } else {
    //                 if (toNumber(newInspectResult[0].USL) >= measVal && measVal >= toNumber(newInspectResult[0].LSL)) {
    //                     measResult = "OK";
    //                 }
    //             }


    //             resultList[i]["MEAS_VAL"] = toNumber(resultList[i]["MEAS_VAL"]).toFixed(toNumber(resultList[i]["POINT"]));
    //             resultList[i]["MEAS_RESULT"] = measResult;
    //         }

    //     }
    // }, [resultList,  nextIdx]);

    /**
     * 문자형일때만, radio버튼 callback
     * @param e
     * @param dataSeq
     * @param counterIdx
     * @param radioData
     */
    const itemRadioCallback = (e: React.MouseEvent<HTMLInputElement>, dataSeq: number, index: number, counterIdx: number, radioData: string) => {
        if (!isChange) {
            setIsChange(true);
        }
        let measResult = radioData;

        if (index === 0 && radioData === 'OK') {
            setResultList(resultList.map((result) => result.seqVal == dataSeq ? {
                ...result,
                MEAS_RESULT: measResult
            } : result));
        } else {
            setResultList(resultList.map((result) => result.countIdx == counterIdx ? {
                ...result,
                MEAS_RESULT: measResult
            } : result));
        }
        setNextMap(undefined);

    };


    const dataAddCallback = (dataSeq: string) => {
        setNextMap(undefined);
        let temp = [...detailResultList];
        const ds_res = temp.filter(inspectResult => inspectResult.SEQ == dataSeq);
        let indexNum: number = toNumber(counterNum);
        setResultList([...resultList, {
            countIdx: toNumber(indexNum++),
            INSP_TARGET_CD: ds_res[0]["INSP_TARGET_CD"],
            INSP_CD: ds_res[0]["INSP_CD"],
            INSP_NM: ds_res[0]["INSP_NM"],
            NUM_YN: ds_res[0]["NUM_YN"],
            POINT: ds_res[0]["POINT"],
            INSP_TYPE: ds_res[0]["INSP_TYPE"],
            seqVal: toNumber(ds_res[0]["SEQ"]),
            MEAS_VAL: undefined,
            MEAS_RESULT: undefined,
            FILE_ID: ""
        }]);
        setCounterNum(indexNum);
    };

    /**
     * 아이템 삭제 callback
     * @param dataSeq
     * @param counterIdx
     */
    const dataDelCallback = (dataSeq: string, index: number, counterIdx: number) => {
        setNextMap(undefined);
        const currentTarget = inputRef.current.find(
                (target) => target.seq == toNumber(dataSeq) && target.index == (index) && target.el !== undefined
        );
        if (currentTarget?.el) currentTarget.el.remove();
        setResultList(resultList.filter((result) => result.countIdx !== counterIdx));

    };

    /**
     * 사진촬영  callback
     * @param seq
     */
    const photoCallback = (seq: number) => {
        imageRef.current[seq]?.click();
    };


    const photoChangeCallback = async (e: React.ChangeEvent<HTMLInputElement>, seq: number) => {

        if (e.target.files) {
            setPictureFile(e.target.files[0]);
            let resultMap: any = detailResultList.filter(result => result.SEQ == seq);
            setInspViewerMap(resultMap);
            setQpointFileId(resultMap[0]["QPOINT_FILE_ID"]);
        }
    };

    useEffect(() => {
        if (pictureFile) {
            setShowInspViewer(true);
        }
    }, [pictureFile]);


    const callbackInspResultConfirm = async (resMap: any, pictureFile: File) => {

        setShowInspViewer(false);

        const formData = new FormData();
        let fileNm: string;
        const options = {
            maxWidthOrHeight: 1920, //width
            initialQuality: 0.8 //quality
        }
        if (pictureFile != undefined) {
            try {
                const compressedFile = await imageCompression(pictureFile, options);
                const resizeFile = new File([compressedFile], pictureFile.name, {type: pictureFile.type});
                formData.append('file', resizeFile);
                fileNm = pictureFile.name;
            } catch (error) {
                console.log(error);
            }
            formData.append("workType", "PSIS");
            sendAxios('/common/nexacro/commonSingleFileUpload.do', 'POST', AXIOS_HEADER.FORM, formData)
                .then(response => {
                    for (const ds_res of responseParser(response?.data)?.ds_output || []) {
                        if (ds_res.RTN_CODE === "SUCESS") {
                            setInspFile(fileNm);
                            if (ds_res.RTN_MSG !== undefined) {
                                setInspFile(fileNm);
                                setInspFileId(String(ds_res.RTN_MSG));
                                setNextMap(undefined);
                                setResultList(resultList.map((result) => toNumber(result.seqVal) == toNumber(resMap[0]["SEQ"]) ? {
                                    ...result,
                                    FILE_ID: ds_res.RTN_MSG,
                                    FILE_NM: fileNm
                                } : result));

                                setDetailResultList(detailResultList.map((detail) => toNumber(detail.SEQ) == toNumber(resMap[0]["SEQ"]) ? {
                                    ...detail,
                                    FILE_ID: ds_res.RTN_MSG,
                                    FILE_NM: fileNm
                                } : detail));
                            }
                        }
                    }
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }
    }

    /**
     * 출하검사 성적서 업로드 click event
     */
    const reportCallback = () => {
        reportRef.current?.click();
    };

    /**
     * 출하검사 성적서 첨부파일 삭제
     */
    const reportDelCallback = (fileItem: any) => {
        const formData = new FormData();
        let inputDatasets: any = {
            ds_input: [{}],
            ds_fileInfo: [{
                MODULE_ID: "PSIS"
            }],
            ds_remove: [{
                FILE_ID: fileItem.FILE_ID,
                FILE_SEQ: fileItem.FILE_SEQ,
                FILE_PATH_NAME: fileItem.FILE_PATH_NAME,
                DB_SRCH: "S",
            }]
        }
        formData.append("inputDatasets", String(requestParser(inputDatasets)));

        sendAxios('/common/nexacro/commonFileupload.do', 'POST', AXIOS_HEADER.FORM, formData)
            .then(response => {
                for (const ds_res of responseParser(response?.data)?.ds_output || []) {
                    if (ds_res.ERR_CODE === "SUCESS") {
                        if (ds_res.ERR_MSG !== undefined) {
                            let fileMap: any = {
                                fileId: ds_res.ERR_MSG,
                                prevFileId: fileItem.FILE_ID,
                                fileState: 'U'
                            }
                            setReportFileMap(fileMap);
                        }
                    }
                }
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
    };

    /**
     * 대책서 작성 팝업 open
     */
    const counterMeasCallback = (inspTargetCd: string, inspCd: string, inspType: string) => {

        let counterData: any = {
            inspTargetCd: inspTargetCd,
            inspCd: inspCd,
            inspType: inspType

        }
        setCounterMap(counterData);
        setShowCounter(true);
    };


    /**
     * 출하검사 성적서 파일 업로드, 멀티 파일 업로드 change event
     * @param e
     */
    const reportChangeCallback = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const formData = new FormData();
        const options = {
            maxWidthOrHeight: 1920, //width
            initialQuality: 0.8 //quality
        }
        let maxSize = 30 * 1024 * 1024; //* 30MB 사이즈 제한
        let fileSize = 0;
        if (e.target.files?.length != undefined) {
            for (let i = 0; i < e.target.files?.length; i++) {
                if (e.target.files[i].type.match('image')) {
                    const compressedFile = await imageCompression(e.target.files[i], options);
                    const resizeFile = new File([compressedFile], e.target.files[i].name, {type: e.target.files[i].type});
                    formData.append('file' + i, resizeFile);
                    fileSize = fileSize + resizeFile.size;
                } else {
                    formData.append('file' + i, e.target.files[i]);
                    fileSize = fileSize + e.target.files[i].size;
                }
                if(fileSize >= maxSize){
                    setDialog({
                        type: 'ALERT',
                        show: true,
                        text: getMessage("MSG01296").replace("0", "30MB"),
                    });
                    return;
                }
            }

            formData.append("workType", "PSIS");

            let inputDatasets: any = {
                ds_input: [{}],
                ds_fileInfo: [{
                    MODULE_ID: "PSIS"
                }],
                ds_remove: [{}]
            }
            formData.append("inputDatasets", String(requestParser(inputDatasets)));

            sendAxios('/common/nexacro/commonFileupload.do', 'POST', AXIOS_HEADER.FORM, formData)
                .then(response => {
                    for (const ds_res of responseParser(response?.data)?.ds_output || []) {
                        if (ds_res.ERR_CODE === "SUCESS") {
                            if (ds_res.ERR_MSG !== undefined) {
                                let fileMap: any = {
                                    fileId: ds_res.ERR_MSG,
                                    prevFileId: '',
                                    fileState: 'S'
                                }
                                setReportFileMap(fileMap);
                            }
                        }
                    }
                })
                .catch(e => {
                    throwException(e);
                    return new Promise(() => {
                    });
                });
        }

    };


    /**
     * 검사결과 임시저장 Click Event
     */
    const callbackTempSave = () => {

        if (detailResultList.filter(result => result.SAMPLE_CNT == 0).length === detailResultList.length) {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage('MSG01512'),
                confirm: () => {
                }
            });
        } else {
            setDialog({
                type: 'CONFIRM',
                show: true,
                text: getMessage('MSG01517'),
                confirm: () => {
                    inspectList[0]["STATUS"] = "01"; //대기 상태
                    inspectList[0]["INSP_CNT"] = Math.max(...detailResultList.map(target => target.SAMPLE_CNT)); //샘플 갯수로 검사 개수 최고를 구함? 공정에서는 다르게...

                    let inspResultVal = "P";
                    if (Math.max(...detailResultList.map(target => target.NG_CNT)) > 0) {
                        inspResultVal = "F";
                    }
                    inspectList[0]["INSP_RESULT"] = inspResultVal;
                    inspectList[0]["INSP_BY"] = sessionValues?.USER_ID;

                    sendAxios('/common/nexacro/updatePSISInspTargetMobile.do', 'POST', AXIOS_HEADER.NEXACRO, {
                        ds_inspTarget: inspectList,
                        ds_inspDetail: resultList.filter((result) => (result.MEAS_RESULT !== undefined && result.MEAS_RESULT !== ''))
                    })
                        .then(response => {
                            if (response?.data.ds_res[0]?.CODE === "SUCESS") {
                                setDialog({
                                    type: 'ALERT',
                                    show: true,
                                    text: getMessage('MSG01511'),
                                    confirm: () => {
                                        pageMove(-1);
                                    }
                                });
                            }
                        })
                        .catch(e => {
                            throwException(e);
                            return new Promise(() => {
                            });
                        });
                }
            });
        }

    };

    /**
     * 검사결과 검사완료 Click Event
     */
    const callbackCompleSave = () => {

        if (detailResultList.filter(result => result.NG_CNT > 0 && result.COUNTER_CNT == 0).length > 0) {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage('MSG01513'),
                confirm: () => {
                    return;
                }
            });
        } else {
            setDialog({
                type: 'CONFIRM',
                show: true,
                text: getMessage('MSG01514'),
                confirm: () => {
                    inspectList[0]["STATUS"] = "02"; //검사완료
                    inspectList[0]["INSP_CNT"] = Math.max(...detailResultList.map(target => target.SAMPLE_CNT)); //샘플 갯수로 검사 개수 최고를 구함? 공정에서는 다르게...

                    let inspResultVal = "P";
                    if (Math.max(...detailResultList.map(target => target.NG_CNT)) > 0) {
                        inspResultVal = "F";
                    }
                    inspectList[0]["INSP_RESULT"] = inspResultVal;
                    inspectList[0]["INSP_BY"] = sessionValues?.USER_ID;
                    inspectList[0]["INSP_BY_NM"] = sessionValues?.USER_NAME;

                    sendAxios('/common/nexacro/updatePSISInspTargetMobile.do', 'POST', AXIOS_HEADER.NEXACRO, {
                        ds_inspTarget: inspectList,
                        ds_inspDetail: resultList.filter((result) => (result.MEAS_RESULT != undefined && result.MEAS_RESULT != ''))
                    })
                        .then(response => {
                            if (response?.data.ds_res[0]?.CODE === "SUCESS") {
                                setDialog({
                                    type: 'ALERT',
                                    show: true,
                                    text: getMessage('MSG01515'),
                                    confirm: () => {
                                        pageMove(-1);
                                    }
                                });
                            }
                        })
                        .catch(e => {
                            throwException(e);
                            return new Promise(() => {
                            });
                        });
                }
            });
        }
    };

    return (
        <>
            {(!showDetail && !showImage && !showCounter) && (
                <div className={'container'} style={{overflowY: "auto"}}>
                    <div className={'card-wrap single'}>
                        <ul className={'card-list'}>
                            {
                                inspectList.map((item, index) => {
                                    return <li className={'card-item'} key={'cardView_'.concat(String(index))}>
                                        <CardMainView data={item}/>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    <div className={'padding-box'} style={{overflowY: 'auto'}}>
                        <div className={'result-state'}
                             style={{position: 'sticky', top: '0', left: '0', width: '100%'}}>
                            {

                                resultList.filter((x) => x.MEAS_RESULT === undefined || x.FILE_ID === undefined || (x.MEAS_RESULT == 'NG' && detailResultList.filter((detail) => detail.INSP_CD == x.INSP_CD && Number(detail.COUNTER_CNT) == 0).length > 0)).length > 0 ?
                                    <span className={'result'}>{getDomain('DOMAIN4566')}</span>
                                    : resultList.filter((x) => x.MEAS_RESULT == 'NG').length > 0 ?
                                        <span className={'result fail'}>FAIL</span>
                                        : <span className={'result pass'}>PASS</span>
                            }
                        </div>
                        <div className={'table-wrap'}>
                            <table>
                                <colgroup>
                                    <col style={{width: '2.4rem'}}/>
                                    <col style={{width: '3.5rem'}}/>
                                    <col/>
                                    <col style={{width: '4.6rem'}}/>
                                    <col style={{width: '4.6rem'}}/>
                                    <col style={{width: '4.2rem'}}/>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th scope={'col'}>No</th>
                                    <th scope={'col'}>{getDomain('DOMAIN4744')}</th>
                                    <th scope={'col'}>{getDomain('DOMAIN4745')}</th>
                                    <th scope={'col'}>{getDomain('DOMAIN4746')}</th>
                                    <th scope={'col'}>{getDomain('DOMAIN4747')}</th>
                                    <th scope={'col'}>{getDomain('DOMAIN3355')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    detailResultList.map((item, index) => {
                                        return <InspTableView data={item} key={'inspTableView'.concat(String(index))}/>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={'btn-area bottom'}>
                        <button type={'button'} className={'button-line'}
                                onClick={() => callbackTempSave()}>{getDomain('DOMAIN4734')}
                        </button>
                        {
                            (detailResultList.filter(result => result.SAMPLE_CNT == 0 || result.FILE_ID === undefined || (result.NG_CNT > 0 && result.COUNTER_CNT == 0)).length > 0 || resultList.filter(rslt => rslt.MEAS_RESULT === undefined).length > 0) ?
                                <button type={'button'} className={'button-primary'} disabled>{getDomain('DOMAIN4733')}</button> :
                                <button type={'button'} className={'button-primary'} onClick={() => callbackCompleSave()}>{getDomain('DOMAIN4733')}</button>
                        }
                    </div>
                </div>
            )}

            {/*{showDetail && (*/}
            <CSSTransition in={showDetail} classNames={showDetail ? 'right' : 'left'} timeout={500} unmountOnExit>
                <div className={'pop-layer'} style={{display: 'block', zIndex: 200}}>
                    <div className={'wrap'}>
                        <header>
                            <h1>{getDomain('DOMAIN4565')}</h1>
                            <span className={'btn-wrap right'}>
                                    <button type={'button'} className={'button-close'} onClick={() => callbackClose()}>
                                        <i className={'hidden'}>닫기</i>
                                    </button>
                                </span>
                        </header>
                        <div className={'container-noheader'}>
                            <div className={'comm-slide-wrap'} style={{
                                display: 'block',
                                // backgroundColor: 'white',
                                width: '100%',
                                height: 'unset',
                                overflowY: 'auto'
                            }}>
                                <div className={'swiper-pagination'} style={{
                                    width: '100% !important',
                                    paddingBottom: '2rem'
                                }}></div>
                                <Swiper modules={[A11y, Pagination]} a11y={{enabled: true}} initialSlide={selectedIndex || 0}
                                    pagination={{
                                    clickable: true, dynamicBullets: true,
                                    el: '.swiper-pagination'
                                }} className="swiper mySwiper">
                                    {
                                        detailResultList.map((item, index) => {
                                            return <SwiperSlide style={{minHeight: gMinHeight}}
                                                                key={'inspMainSwiperSlide'.concat(item.SEQ).concat(String(index))}>
                                                <InspMainList data={item}
                                                              key={'inspMainList'.concat(item.SEQ).concat(String(index))}/>
                                            </SwiperSlide>
                                        })
                                    }
                                </Swiper>
                            </div>
                            <div className={'btn-area bottom'}>
                                <button type={'button'} className={'button-primary'}
                                        onClick={() => callbackClose()}>{getDomain('DOMAIN0173')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
            {/*)}*/}
            <CSSTransition in={showImage} classNames={showImage ? 'right' : 'left'} timeout={500} unmountOnExit>
                <ImageViewer title={titleNm} imageFileId={imageFileId} onClose={closeImage}/>
            </CSSTransition>
            <CSSTransition in={showCounter} classNames={showCounter ? 'right' : 'left'} timeout={500} unmountOnExit>
                <CounterViewer title={getDomain('DOMAIN4573')} counterMap={counterMap} onClose={closeCounter}
                               onSave={(inspCd) => closeSaveCounter(inspCd)}/>
            </CSSTransition>
            <CSSTransition in={showInspViewer} classNames={showInspViewer ? 'right' : 'left'} timeout={500}
                           unmountOnExit>
                <InspResultViewer inspMap={inspViewerMap} fileId={qpointFileId}
                                  onConfirm={(inspViewerMap, pictureFile) => callbackInspResultConfirm(inspViewerMap, pictureFile)}
                                  shootingFile={pictureFile}/>
            </CSSTransition>


        </>
    );

    /**
     * 카드뷰 동적 생성 처리
     * @param data
     * @param callback
     * @constructor
     */
    function CardMainView({data, callback}: { data: any, callback?: () => void }) {
        return (
            <>
                <div className={'item'} role={'button'}>
                    <section>
                        <div className={'label-wrap'}>
                            {data.HQ_INSP_YN === 'Y' && (
                                <i className={'label-type17'}>{data.HQ_INSP_YN_NM}</i>
                            )}
                            {data.HQ_INSP_YN === 'N' && (
                                <i className={'label-type16'}>{data.HQ_INSP_YN_NM}</i>
                            )}
                            {data.TIME_VALUE === '01' && (
                                <i className={'label-time1'}>{(Number(data.TIME_REMAIN) / 60).toFixed(0)}hr</i>
                            )}
                            {data.TIME_VALUE === '02' && (
                                <i className={'label-time2'}>{(Number(data.TIME_REMAIN) / 60).toFixed(0)}hr</i>
                            )}
                            {data.TIME_VALUE === '03' && (
                                <i className={'label-time3'}>
                                    {
                                        Number((Number(data.TIME_REMAIN) / 60).toFixed(0)) > 0 ? Number(Number(data.TIME_REMAIN) / 60).toFixed(0).concat('hr') : String(Number(data.TIME_REMAIN)).concat('min')
                                    }
                                </i>
                            )}
                        </div>
                        <div className={'title-wrap'}>
                            <span>[{data.PLANT_CD}] [{data.PART_CD}] [{data.HQ_CD_2ND}]</span>
                            <strong><a style={{'textDecorationLine': 'underline', 'color': 'blue'}}
                                       onClick={() => imageViewerCallback(data.PART_FILE_ID, getDomain('DOMAIN4748'))}>{data.PART_NM.split(';', 1)}</a></strong>
                            {/*<strong>{data.PART_NM}*/}
                            {/*    <button type={'button'} className={'btn-gallary'}*/}
                            {/*            style={{verticalAlign:'middle'}}*/}
                            {/*            onClick={() => imageViewerCallback(data.PART_FILE_ID)}><i*/}
                            {/*        className={'hidden'}>갤러리</i></button>*/}
                            {/*</strong>*/}
                        </div>
                    </section>
                    <ul className={'date-list'}>
                        {
                            <li>
                                <em style={{width: '50%'}}>
                                    <a style={{'textDecorationLine': 'underline', 'color': 'blue'}}
                                       onClick={() => imageViewerCallback(data.LINE_FILE_ID, 'LINE'.concat(' ', getDomain('DOMAIN4748')))}>[{data.LINE_CD}] {data.LINE_NM}</a>
                                    {/*[{data.LINE_CD}] {data.LINE_NM}*/}
                                    {/*<button type={'button'} className={'btn-gallary'}*/}
                                    {/*        style={{verticalAlign:'middle'}}*/}
                                    {/*        onClick={() => imageViewerCallback(data.LINE_FILE_ID)}><i*/}
                                    {/*    className={'hidden'}>갤러리</i></button>*/}
                                </em>
                                <span style={{width: '50%'}}>{data.INSERT_DT_EXPR}</span>
                            </li>
                        }
                        {
                            <li>
                                <em style={{width: '50%'}}>{data.SPEC_CNT_EXPR}</em>
                                <span
                                    style={{width: '50%'}}>{getDomain('DOMAIN4730')} {data.TARGET_CNT.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            </li>
                        }
                    </ul>
                </div>
            </>
        );
    };


    /**
     * 검사항목 테이블 동적 생성 처리
     * @param data
     * @param callback
     * @constructor
     */
    function InspTableView({data, callback}: { data: any, callback?: () => void }) {
        return (
            <>
                <tr>
                    <td><a onClick={(e) => callbackSwipe(e, toNumber(data.SEQ))}>{data.SEQ}</a></td>
                    <td><a onClick={(e) => callbackSwipe(e, toNumber(data.SEQ))}>{data.INSP_TYPE}</a></td>
                    <td className={'left'}><a onClick={(e) => callbackSwipe(e, toNumber(data.SEQ))}>[{data.INSP_CD}] {data.INSP_NM}</a>
                    </td>
                    <td className={'right'}><a onClick={(e) => callbackSwipe(e, toNumber(data.SEQ))}>{data.SAMPLE_CNT}</a>
                    </td>
                    <td className={'right'}><a onClick={(e) => callbackSwipe(e, toNumber(data.SEQ))}>{data.NG_CNT}</a>
                    </td>
                    <td><a onClick={(e) => callbackSwipe(e, toNumber(data.SEQ))}>
                        {(resultList.filter((x) => x.INSP_CD == data.INSP_CD && x.MEAS_RESULT == undefined).length == 0 && data.SAMPLE_CNT > 0 && data.NG_CNT == 0 && data.FILE_ID != undefined) ?
                            <span className={'label-state ok'}>OK</span>
                            : (resultList.filter((x) => x.INSP_CD == data.INSP_CD && x.MEAS_RESULT == undefined).length == 0 && data.NG_CNT > 0 && data.COUNTER_CNT > 0 && data.FILE_ID != undefined) ?
                                <span className={'label-state ng'}>NG</span>
                                : <span className={'label-state wait'}>{getDomain('DOMAIN4700')}</span>
                        }
                    </a>
                    </td>
                </tr>
            </>
        );
    };

    /**
     * 검사항목 메인 리스트
     * @param data
     * @param callback
     * @constructor
     */
    function InspMainList({data}: { data: any, callback?: () => void }) {
        return (
            <>
                <div className={'swiper-wrapper'}>
                    <div className={'swiper-slide'}>
                        <ul className={'contents-table-list'}>
                            <li>
                                <div className={'col'}>
                                    <span className={'title'} style={{
                                        wordBreak: 'break-word',
                                        textAlign: 'center'
                                    }}>{getDomain('DOMAIN4745')}</span>
                                    <span className={'item-wrap'}>
                                    [{data.INSP_CD}] {data.INSP_NM}
                                        <button type={'button'} className={'btn-gallary'} tabIndex={-1}
                                                style={{verticalAlign: 'middle'}}
                                                onClick={() => imageViewerCallback(data.QPOINT_FILE_ID, getDomain('DOMAIN4585'))}><i
                                            className={'hidden'}>갤러리</i></button>
                                </span>
                                </div>
                            </li>
                            <li>
                                <div className={'col'}>
                                    <span className={'title'} style={{
                                        wordBreak: 'break-word',
                                        textAlign: 'center'
                                    }}>{getDomain('DOMAIN4567')}</span>
                                    <span className={'item-wrap center'} style={{justifyContent:"center"}}>{data.INSP_TYPE}</span>
                                </div>
                                <div className={'col'}>
                                    <span className={'title'} style={{
                                        wordBreak: 'break-word',
                                        textAlign: 'center'
                                    }}>{getDomain('DOMAIN4749')}</span>
                                    <span className={'item-wrap center'} style={{justifyContent:"center"}}>{data.EQUIP_NM}</span>
                                </div>
                            </li>
                            <li>
                                <div className={'col'}>
                                    <span className={'title'} style={{
                                        wordBreak: 'break-word',
                                        textAlign: 'center'
                                    }}>LSL</span>
                                    <span className={'item-wrap right'} style={{
                                        wordBreak: 'break-word'
                                    }}>{data.NUM_YN === 'Y' && data.LSL !== undefined ? Number(data.LSL).toFixed(data.POINT) : data.LSL}</span>
                                </div>
                                <div className={'col'}>
                                    <span className={'title'} style={{
                                        wordBreak: 'break-word',
                                        textAlign: 'center'
                                    }}>USL</span>
                                    <span className={'item-wrap right'} style={{
                                        wordBreak: 'break-word'
                                    }}>{data.NUM_YN === 'Y' && data.USL !== undefined ? Number(data.USL).toFixed(data.POINT) : data.USL}</span>
                                </div>
                            </li>
                            <li>
                                <div className={'col'}>
                                    <span className={'title'} style={{
                                        wordBreak: 'break-word',
                                        textAlign: 'center'
                                    }}>CL</span>
                                    <span className={'item-wrap right'} style={{
                                        wordBreak: 'break-word'
                                    }}>{data.NUM_YN === 'Y' && data.CL !== undefined ? Number(data.CL).toFixed(data.POINT) : data.CL}</span>
                                </div>
                                <div className={'col'}>
                                    <span className={'title'} style={{
                                        wordBreak: 'break-word',
                                        textAlign: 'center'
                                    }}>{getDomain('DOMAIN4428')}</span>
                                    <span className={'item-wrap center'}
                                          style={{wordBreak: 'break-word', justifyContent:'center'}}>{data.UNIT}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={'photo-wrap'}>
                    <h2 className={'h2-title'}>{getDomain('DOMAIN4568')}</h2>
                    <div className={'btn-shoot-wrap'}>
                        <input type={'text'} id={uuidv4()} placeholder={getDomain('DOMAIN4583')} defaultValue={data.FILE_NM}
                               onClick={() => photoCallback(data.SEQ)} readOnly={true}/>
                        <input type={'file'} ref={(el) => imageRef.current[toNumber(data.SEQ)] = el}
                               id={'photoFile' + data.SEQ} accept={"image/*"} capture={"environment"}
                               style={{display: "none"}} onChange={e => photoChangeCallback(e, data.SEQ)}/>
                        <button type={'button'} onClick={() => photoCallback(data.SEQ)} className={'button-shoot'}><i
                            className={'hidden'}>{getDomain('DOMAIN4569')}</i></button>
                    </div>
                </div>
                <div className={'result-box'}>
                    <div className={'h2-title-wrap'}>
                        <h2 className={'h2-title'}>{getDomain('DOMAIN2612')}</h2>
                        <button type={'button'} className={'button-primary sm sample'}
                                onClick={() => dataAddCallback(data.SEQ)}>{getDomain('DOMAIN4570')}
                        </button>
                    </div>
                    {data.NUM_YN === 'Y' ?
                        <ul className={'result-write-list'}>
                            {
                                resultList.filter((x) => x.INSP_CD === data.INSP_CD).map((t, i) => (
                                    <li key={uuidv4()}>
                                        <div className={'num-item'}>{i + 1}</div>
                                        <div className={'inp-item'}>
                                            <input type={'text'} placeholder={getDomain('DOMAIN4579')}
                                                pattern={'-?\d+\.?\d+'}
                                                inputMode={isIOS ? undefined : "numeric"}
                                                id={t.countIdx}
                                                key={t.countIdx}
                                                name={'input_text'}
                                                defaultValue={t.MEAS_VAL}
                                                ref={(el:HTMLInputElement) => inputRef.current[t.countIdx] = {seq:toNumber(data.SEQ),index: i, el: el}}
                                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onBlurCallback(e, data.SEQ, i, t.countIdx, data.POINT)}
                                                onInput={(e: React.ChangeEvent<HTMLInputElement>) => inputCallback(e, data.SEQ, i, t.countIdx, data.POINT)}
                                                // onClick={() => setNextMap({seq: toNumber(data.SEQ), index : i })}
                                                onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                    if(e.key === 'Enter') {
                                                        keyPressCallback(e, data.SEQ, i, t.countIdx, data.POINT)}
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className={'label-item'}>
                                            {(t.MEAS_RESULT === 'OK') && (
                                                <span className={'label-state ok'}>OK</span>
                                            )}
                                            {(t.MEAS_RESULT === 'NG') && (
                                                <span className={'label-state ng'}>NG</span>
                                            )}
                                        </div>
                                        <div className={'btn-item'}>
                                            <button type={'button'}
                                                    className={'button-delete'}
                                                    style={{background: (i === 0 && resultList.filter((x) => x.INSP_CD === data.INSP_CD).length == 1 ? "#FFF" : "#E1E1E1 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAG9SURBVHgB7ZjfbYMwEIcNXSAj5C3KW7sBI6AsVLJJJgiMQCZo3+CRETIB7f1aUBByzn/ueIn8SciJzeFP4ODLGZNIJBIvReY6oSzLfZ7n5TiO303TtGYDaI6Cmj0dLc0xcOdmjgtB9os+7qau6nq9no0ip9PpE9edvt7pxnxw0rnhKcxDFlTTBCqsZMEOT5OLcQkPlj4VaYvsH1h6XNwbN9j3/XA8HrFsitVQQf2m67qbieCZLHGu6/rCxbLCgKRaTWlOln4flSveKQy0pKWywEsYSKU1ZIG3MIiV1pIFQcIgVFpTFgQLA19pbVng3Jo5SKiixvZOrlbtkmhZIBIGjLQNkSyIWhJLmOWxRiwLxMLAQ1pFFrhyiRB+IseCULnDzNtgRpR7LBELe8jOqEiLhLn3LB0QK1b9YuloYdemoJ3lzUQJ++5gW0gHC4dut9rSQcKxuYGmtLewNJHRkvYS1sq6NKSdwtopolSaFUZFJsuyi2VIlBtw0ofD4YZ/689i2VyCihrvlm6VRGa6xtky556LY4WpqNFQc190qWVdwCKNUlXLxXgVA83/oxu2LAbiaeIGuYqBiUQi8WL8AmHNPfQrrasyAAAAAElFTkSuQmCC) no-repeat 50% 50%/2.2rem auto")}}
                                                    disabled={i === 0 && resultList.filter((x) => x.INSP_CD == data.INSP_CD).length == 1 ? true : false}
                                                    onClick={() => dataDelCallback(data.SEQ, i,t.countIdx)}><i
                                                className={'hidden'}>{getDomain('DOMAIN0229')}</i></button>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                        :
                        <ul className={'result-write-list radio-type'}>
                            {
                                resultList.filter((x) => x.INSP_CD === data.INSP_CD).map((t, i) => (
                                    <li key={uuidv4()}>
                                        <div className={'num-item'}>{i + 1}</div>
                                        <div className={'radio-item'} tabIndex={toNumber(data.SEQ.concat("0")) + i}>
                                            <label>
                                                <input type={'radio'} name={'radio_' + t.countIdx}
                                                       id={uuidv4()}
                                                       key={uuidv4()}
                                                       ref={(el:HTMLInputElement) => inputRef.current[t.countIdx] = {seq:toNumber(data.SEQ),index: i, el: el}}
                                                       defaultChecked={t.MEAS_RESULT === 'OK'}
                                                       onClick={e => itemRadioCallback(e, data.SEQ, i, t.countIdx, 'OK')}/> OK
                                            </label>
                                            <label>
                                                <input type={'radio'} name={'radio_' + t.countIdx}
                                                       id={uuidv4()}
                                                       key={uuidv4()}
                                                       ref={(el:HTMLInputElement) => inputRef.current[t.countIdx] = {seq:toNumber(data.SEQ),index: i, el: el}}
                                                       defaultChecked={t.MEAS_RESULT === 'NG'}
                                                       onClick={e => itemRadioCallback(e, data.SEQ, i, t.countIdx, 'NG')}/> NG
                                            </label>
                                        </div>
                                        <div className={'btn-item'}>
                                            <button type={'button'} className={'button-delete'}
                                                    style={{background: (i === 0 && resultList.filter((x) => x.INSP_CD === data.INSP_CD).length == 1 ? "#FFF" : "#E1E1E1 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAG9SURBVHgB7ZjfbYMwEIcNXSAj5C3KW7sBI6AsVLJJJgiMQCZo3+CRETIB7f1aUBByzn/ueIn8SciJzeFP4ODLGZNIJBIvReY6oSzLfZ7n5TiO303TtGYDaI6Cmj0dLc0xcOdmjgtB9os+7qau6nq9no0ip9PpE9edvt7pxnxw0rnhKcxDFlTTBCqsZMEOT5OLcQkPlj4VaYvsH1h6XNwbN9j3/XA8HrFsitVQQf2m67qbieCZLHGu6/rCxbLCgKRaTWlOln4flSveKQy0pKWywEsYSKU1ZIG3MIiV1pIFQcIgVFpTFgQLA19pbVng3Jo5SKiixvZOrlbtkmhZIBIGjLQNkSyIWhJLmOWxRiwLxMLAQ1pFFrhyiRB+IseCULnDzNtgRpR7LBELe8jOqEiLhLn3LB0QK1b9YuloYdemoJ3lzUQJ++5gW0gHC4dut9rSQcKxuYGmtLewNJHRkvYS1sq6NKSdwtopolSaFUZFJsuyi2VIlBtw0ofD4YZ/689i2VyCihrvlm6VRGa6xtky556LY4WpqNFQc190qWVdwCKNUlXLxXgVA83/oxu2LAbiaeIGuYqBiUQi8WL8AmHNPfQrrasyAAAAAElFTkSuQmCC) no-repeat 50% 50%/2.2rem auto")}}
                                                    disabled={i === 0 && resultList.filter((x) => x.INSP_CD === data.INSP_CD).length == 1 ? true : false}
                                                    onClick={() => dataDelCallback(data.SEQ, i,t.countIdx)}><i
                                                className={'hidden'}>삭제</i></button>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    }

                    <div className={'dashed-box'} style={{padding: '1.5rem 0 0'}}>
                        <h2 className={'h2-title'}>{getDomain('DOMAIN4571')}</h2>
                        <div className={'result-file'}>
                            <div className={'title'} style={{width:'9rem'}}>{getDomain('DOMAIN4750')}</div>
                            <div className={'file-name'}>
                                <input type={'file'} multiple={true} ref={reportRef} accept={"*"} id={uuidv4()}
                                       style={{display: "none"}} onChange={e => reportChangeCallback(e)}/>
                                <button type={'button'} className={'button-primary sm upload'}
                                        style={{padding: '0 1rem'}}
                                        onClick={() => reportCallback()}>{getDomain('DOMAIN4572')}
                                </button>
                            </div>
                        </div>
                        {
                            reportFileList.map((item, index) => {
                                return <div className={'result-file'}
                                            key={'reportFileList'.concat(data.INSP_CD).concat(String(index))}>
                                    <div className={'title'} style={{width:'2rem'}}></div>
                                    <div className={'file-name'}>
                                        <p>{item.FILE_NAME}</p>
                                        <span
                                            className={'file-size'}>{floor(toNumber(item.FILE_SIZE) / (1024 * 1024), 2)}MB</span>
                                    </div>
                                    <div className={'btn-item'}>
                                        <button type={'button'} className={'button-delete'}
                                                onClick={() => reportDelCallback(item)}><i
                                            className={'hidden'}>{getDomain('DOMAIN0229')}</i>
                                        </button>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className={'dashed-box'} style={{padding: '1.5rem 0 0'}}>
                        <h2 className={'h2-title'}>{getDomain('DOMAIN4573')}</h2>
                        <div className={'result-file'}>
                            <div className={'title'} style={{width:'9rem'}}>{getDomain('DOMAIN4574')}</div>
                            <div className={'file-name'}>
                                {
                                    resultList.filter(result => result.INSP_CD == data.INSP_CD && result.MEAS_RESULT == 'NG').length == 0 && (
                                        <button type={'button'} className={'button-primary sm write'} style={{
                                            inlineSize: 'fit-content'
                                        }}>{getDomain('DOMAIN4582')}</button>
                                    )
                                }
                                {
                                    resultList.filter(result => result.INSP_CD == data.INSP_CD && result.MEAS_RESULT == 'NG').length > 0 && (
                                        <button type={'button'} className={'button-secondary sm write'}
                                                style={{backgroundColor: '#3478F5', inlineSize: 'fit-content'}}
                                                onClick={() => counterMeasCallback(data.INSP_TARGET_CD, data.INSP_CD, data.INSP_TYPE)}>
                                            {data.COUNTER_CNT > 0 ? getDomain('DOMAIN4735') : getDomain('DOMAIN4582')}
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
};