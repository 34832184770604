/**************************************************************************************************
 * 01. 업무구분	    : P-SIS > 검사결과 입력 > 공정검사 입력
 * 02. 프로그램ID	: PsisInspResultRegProcess.tsx
 * 03. 프로그램명	    : P-SIS > 검사결과 입력 > 공정검사 입력
 * 04. 화면설명	    : P-SIS > 검사결과 입력 > 공정검사 입력
 * 05. 작성일		: 2023.10.04
 * 06. 작성자		: YGY
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-10-04     YGY    최초 작성
 **************************************************************************************************
 */
/******************************************************************************************
 *                                        변경이력                                         *
 *----------   ----------   ------------   ------------------------------------------------*
 * 변경번호     변경일자       변경자                        변경내용                      *
 *----------   ----------   ------------   ------------------------------------------------*

 ******************************************************************************************/

 import React, {useEffect, useRef, useState} from 'react';
 import useAxios, {AXIOS_HEADER} from '../../../hooks/useAxios';
 import {useError} from '../../../hooks/useException';
 import {useLocation, useOutletContext} from 'react-router-dom';
 import {useComponent} from "../../../components/contexts/ComponentContext";
 import {Swiper, SwiperSlide} from 'swiper/react';
 import {Navigation, Pagination} from 'swiper';
 import 'swiper/css';
 import 'swiper/css/pagination';
 import {CSSTransition} from 'react-transition-group';
 import {floor, toNumber} from "lodash";
 import ImageViewer from "./ImageViewer";
 import {requestParser, responseParser} from "../../../components/utils/NexacroParseUtil";
 import CounterViewer from "./CounterViewer";
 import usePageMove from "../../../hooks/usePageMove";
 import imageCompression from "browser-image-compression";
 import useStorage from "../../../hooks/useStorage";
 import {STORAGE_NAME} from "../../../components/CommonConstants";
 import {v4 as uuidv4} from 'uuid';
 import InspResultViewer from "./InspResultViewer";
 import {useDataset} from "../../../components/contexts/CommonContext";
 import useGlobalData from "../../../hooks/useGlobalData";
 import {ComponentHistoryType} from "../../../components/model/SqciTypes";
import {isMobile, isAndroid, isIOS, CustomView, isSamsungBrowser } from 'react-device-detect'; //2024-07-17 huimei.zhu  GHCSEC-3998 [SQCI mobile ] The Keyboards without icons " - " 


 
 /**
  * 공정검사 검사결과 입력
  * @constructor
  */
 export default function PsisInspResultRegProcess() {
     const {sendAxios} = useAxios();
     const {getGlobalSetting, setGlobalSetting, getDomain, getMessage} = useGlobalData();
     const gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
     const {setDialog} = useComponent();
     const {throwException} = useError();
     const location = useLocation();
     const {pageMove} = usePageMove();
     const {setStorage, getStorage, clearStorage} = useStorage();
     const {sessionValues, confirmHistoryBack} = useDataset();
     const [inspectList, setInspectList] = useState(new Array<any>());
     const [detailResultList, setDetailResultList] = useState(new Array<any>());
     const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
     const [resultList, setResultList] = useState(new Array<any>());
     const [showDetail, setShowDetail] = useState<boolean>(false);
     const [showImage, setShowImage] = useState<boolean>(false);
     const [showCounter, setShowCounter] = useState<boolean>(false);
     const [imageFileId, setImageFileId] = useState(String);
     const [titleNm, setTitleNm] = useState(String);
     const [counterNum, setCounterNum] = useState(Number);
     const imageRef = useRef<any>([]);
     const [inspFile, setInspFile] = useState(String);
     const [inspFileId, setInspFileId] = useState(String);
     const [counterMap, setCounterMap] = useState<any>();
     const [focusTab, setFocusTab] = useState(Number);
     const [isChange, setIsChange] = useState<boolean>(false);
     const [useOCR, setUseOCR] = useState<boolean>(Boolean(getStorage('LOCAL', STORAGE_NAME.OCR)) || false);
     const [inspCnt, setInspCnt] = useState<Number>(0);
     const [qpointFileId, setQpointFileId] = useState(String);
     const [showInspViewer, setShowInspViewer] = useState<boolean>(false);
     const [pictureFile, setPictureFile] = useState<File>();
     const [inspViewerMap, setInspViewerMap] = useState(new Array<any>());
     const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();
     const [gMinHeight, setGMinHeight] = useState(String);
     // 포커싱된 tab을 따라, tab 스크롤 이동
     const scrollRef = useRef<HTMLUListElement>(null);
     const [nextMap, setNextMap] = useState<{ seq: number; index: number} | undefined>();
     const inputRef = useRef<{ seq: number; index: number; el: HTMLInputElement}[]>([]);
    
    //2024-07-17 huimei.zhu  GHCSEC-3998 [SQCI mobile ] The Keyboards without icons " - " end
     useEffect(() => {
         if ((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
             let sMinHeight = window.innerHeight - 201.6;
             setGMinHeight(String(sMinHeight).concat("px"));
             setInspectList(location.state.ds_inspect);
             let inspCnt = location.state.ds_inspect[0].INSP_CNT != 0 ? (toNumber(location.state.ds_inspect[0].INSP_CNT) + 1) : 1;
             sendAxios('/common/nexacro/selectPsisDetailResultList.do', 'POST', AXIOS_HEADER.NEXACRO, {
                 ds_search: [{
                     LANG: gv_locale,
                     INSP_TARGET_CD: location.state.ds_inspect[0].INSP_TARGET_CD,
                     INSP_CNT: inspCnt,
                     HQ_INSP_YN: location.state.ds_inspect[0].HQ_INSP_YN
                 }]
             })
                 .then(response => {
                     let sResultList = new Array<any>();
                     let countIdx: number = 0;
                     setDetailResultList(response?.data.ds_detailResultList || []);
                     for (const ds_res of response?.data.ds_detailResultList || []) {
                         for (let i = 1; i <= toNumber(inspCnt); i++) {
                             if (i == 1) {
                                 if (ds_res["T_" + 1 + "_FILE_NM"] !== undefined) {
                                     ds_res["FILE_ID"] = ds_res["T_1_FILE_ID"];
                                     ds_res["FILE_NM"] = ds_res["T_1_FILE_NM"];
                                 }
                             }
                             sResultList.push({
                                 countIdx: countIdx++,
                                 INSP_TARGET_CD: ds_res["INSP_TARGET_CD"],
                                 INSP_CD: ds_res["INSP_CD"],
                                 INSP_NM: ds_res["INSP_NM"],
                                 INSP_TYPE: ds_res["INSP_TYPE"],
                                 NUM_YN: ds_res["NUM_YN"],
                                 seqVal: ds_res["SEQ"], //검사항목 seq
                                 SEQ: i, //테스트 수
                                 OCR_YN: ds_res["OCR_YN"] === undefined ? "N" : ds_res["OCR_YN"],
                                 ocrList: [],
                                 selectOcr: undefined,
                                 MEAS_VAL: ds_res["NUM_YN"] === 'Y' ? ds_res["T_" + i + "_MEAS_VAL"] !== undefined ? floor(toNumber(ds_res["T_" + i + "_MEAS_VAL"]), ds_res["POINT"]).toFixed(ds_res["POINT"]) : undefined : undefined,
                                 MEAS_RESULT: ds_res["T_" + i + "_MEAS_RESULT"],
                                 FILE_ID: ds_res["T_" + i + "_FILE_ID"],
                                 FILE_NM: ds_res["T_" + i + "_FILE_NM"],
                                 QPOINT_FILE_ID: ds_res["QPOINT_FILE_ID"]
                             });
                         }
 
                     }
                     setInspCnt(inspCnt);
                     setFocusTab(inspCnt);
                     setResultList(sResultList);
                     setCounterNum(countIdx);
                 })
                 .catch(e => {
                     throwException(e);
                     return new Promise(() => {
                     });
                 });
         }
     }, [enteredComponent, exitedComponent]);
 
     useEffect(() => {
         if (isChange) {
             confirmHistoryBack(true, getMessage('MSG01510'), () => {
                 inspectList[0]["STATUS"] = "01"; //대기 상태
                 let inspResultVal = "P";
                 if (Math.max(...detailResultList.map(target => target.NG_CNT)) > 0) {
                     inspResultVal = "F";
                 }
                 inspectList[0]["INSP_RESULT"] = inspResultVal;
                 inspectList[0]["INSP_BY"] = sessionValues?.USER_ID;
 
                 sendAxios('/common/nexacro/updatePSISInspTargetMobile.do', 'POST', AXIOS_HEADER.NEXACRO, {
                     ds_inspTarget: inspectList,
                     ds_inspDetail: resultList.filter((result) => (result.MEAS_RESULT !== undefined && result.MEAS_RESULT !== '')).map(detail => detail.ocrList !== undefined ? {
                         ...detail,
                         ocrList: undefined,
                     } : detail)
                 })
                     .then(response => {
                         if (response?.data.ds_res[0]?.CODE === "SUCESS") {
                             setDialog({
                                 type: 'ALERT',
                                 show: true,
                                 text: getMessage('MSG01511'),
                                 confirm: () => {
                                     pageMove(-1);
                                 }
                             });
                         }
                     })
                     .catch(e => {
                         throwException(e);
                         return new Promise(() => {
                         });
                     });
             });
         }
     }, [isChange]);
 
     /**
      * 이미지 컴포넌트 Close
      */
     const closeImage = () => {
         setShowImage(false);
         setImageFileId("");
     };
 
     /**
      * 대책서 컴포넌트 Close
      */
     const closeCounter = () => {
         setShowCounter(false);
     };
 
     /**
      * 대책서 컴포넌트 저장 및 Close
      */
     const closeSaveCounter = (inspCd: string) => {
         for (let i = 0; i < detailResultList.length; i++) {
             if (detailResultList[i]["INSP_CD"] === inspCd) {
                 detailResultList[i]["COUNTER_CNT"] = 1;
             }
         }
 
         setShowCounter(false);
     };
 
     /**
      * swipe(Detail) 페이지로 이동
      * @param e
      * @param inspIdx
      */
     const callbackSwipe = (e: React.MouseEvent<HTMLAnchorElement>, inspIdx: number) => {
         e.preventDefault();
         setSelectedIndex(inspIdx - 1);
         setShowDetail(true);
     };
 
 
     /**
      * 리스트 페이지로 이동
      * 리스트 페이지로 갈때, detailResultList 값을 다시 세팅함.
      */
     const callbackClose = () => {
 
         for (let i = 0; i < detailResultList.length; i++) {
             let inspCd = detailResultList[i]["INSP_CD"];
             detailResultList[i]["SAMPLE_CNT"] = resultList.filter(result => result.INSP_CD === inspCd && (result.MEAS_RESULT !== undefined)).length;
             detailResultList[i]["NG_CNT"] = resultList.filter(result => result.INSP_CD === inspCd && (result.MEAS_RESULT === 'NG')).length;
 
         }
 
         setShowDetail(false);
     };
 
 
     /**
      * Tab 선택
      * @param idx
      */
     const selectTab = (idx: number) => {
         setFocusTab(idx);
     };
 
     useEffect(() => {
         if(scrollRef?.current) {
             scrollRef.current.scrollTo({left: 0 + (scrollRef.current.children[0].clientWidth * (focusTab-1)), top: 0, behavior: 'smooth'});
         }
     }, [focusTab]);
 
     useEffect(() => {
         if (!showDetail || !showImage) {
             if(scrollRef?.current) {
                 scrollRef.current.scrollTo({left: 0 + (scrollRef.current.children[0].clientWidth * (focusTab-1)), top: 0, behavior: 'smooth'});
             }
         }
     }, [showDetail, showImage]);
 
     /**
      * fileId를 가지고서, 이미지 뷰어 처리
      * @param fileId
      */
     const imageViewerCallback = (fileId: string, title: string) => {
         setShowImage(true);
         setImageFileId(fileId);
         setTitleNm(title);
 
     };
 
     /**
      * 아이템이 숫자형일때만 callback 처리함.
      * @param e
      * @param dataSeq
      * @param counterIdx
      * @param point
      */
     const onBlurCallback = (e: React.ChangeEvent<HTMLInputElement>, dataSeq: string, index: number, counterIdx: number, point: number) => {
         if (!isChange) {
             setIsChange(true);
         }
         const cntVal = e.target.value;
         if (cntVal !== '') {
             const newInspectResult = detailResultList.filter(inspectResult => inspectResult.SEQ === dataSeq);
 
             //숫자형이 아니면 바로 리턴 시킴
             let measVal: number = toNumber(cntVal);
 
             if (isNaN(toNumber(cntVal))) {
                 measVal = 0;
             } else {
                 if (measVal >= 0) {
                     measVal = floor(measVal, point);
                 } else {
                     measVal = -floor(-measVal, point);
                 }
             }
 
             //기본값은 NG
             let measResult = "NG";
             //값이 LSL , USL 안에 들어갈시에는 측정값은 OK로 변경 처리
             if (isNaN(toNumber(newInspectResult[0].USL))) {
                 if (measVal >= toNumber(newInspectResult[0].LSL)) {
                     measResult = "OK";
                 }
             } else if (isNaN(toNumber(newInspectResult[0].LSL))) {
                 if (toNumber(newInspectResult[0].USL) >= measVal) {
                     measResult = "OK";
                 }
             } else {
                 if (toNumber(newInspectResult[0].USL) >= measVal && measVal >= toNumber(newInspectResult[0].LSL)) {
                     measResult = "OK";
                 }
             }
 
             setResultList(resultList.map((result) => result.countIdx === counterIdx ? {
                 ...result,
                 MEAS_VAL: measVal.toFixed(point),
                 MEAS_RESULT: measResult,
             } : result));
         } else {
             setResultList(resultList.map((result) => result.countIdx === counterIdx ? {
                 ...result,
                 MEAS_VAL: undefined,
                 MEAS_RESULT: undefined,
             } : result));
         }
 
     };

     const inputCallback = (e: React.ChangeEvent<HTMLInputElement>, dataSeq: string, index: number, counterIdx: number, point: number) => {
       const old = resultList.find(result =>result.seqVal == dataSeq && result.countIdx === counterIdx)

       const cntVal= inputChg(e.target.value, old.MEAS_VAL as string);
       e.target.value = cntVal;

       //2024-07-26 zilong.ge GHCSEC-3998 Resolves the problem that every character entered triggers a page refresh that loses focus.
       for(let i=0; i<resultList.length; i++) {
           if (resultList[i].seqVal == dataSeq && resultList[i].countIdx === counterIdx) {
               resultList[i].MEAS_VAL = cntVal;
               resultList[i].MEAS_RESULT = undefined;
               setResultList(resultList);
               break;
           }
       }
     };

     const inputChg = (str:String, oldVal:string) =>{
        let val = String(str);
        const regx = /^(\-)?\d+(\.?)(\d+)?$/g
        if (isSamsungBrowser && val.startsWith('.')) {
            val = val.replace('.','-');
        }
        //2024-07-25 zilong.ge GHCSEC-3998 Solve the problem that cannot be deleted after 1e+2 1e2 1e-2 is converted to scientific counting when the number is too large
        if(val.includes('e') && oldVal.includes('e') && oldVal.startsWith(val)) {
            return '';
        }
        //2024-07-24 zilong.ge GHCSEC-3998 Add 01, 001, 00.1 and - 01 - 00, 1-0 0.1 scenario processing
        if (/^0(\d+)/g.test(val) || /^-0(\d+)/g.test(val)) {
            return String(toNumber(val)) || '';
        }

        if(/^(\-)?$/g.test(val) || regx.test(val)) {
            return val;
        } else {
            return oldVal || '';
        }
    };

    useEffect(() => {
        if (nextMap !== undefined) {
            const currentTarget = inputRef.current.find(
              (target) => target && target.seq == nextMap.seq && target.index == nextMap.index  && target.el != null
            );
            if (currentTarget?.el) currentTarget.el.focus();
        }
    },[nextMap]);
     /**
      * inputbox 아이템값 초기화
      * @param e
      * @param dataSeq
      * @param counterIdx
      * @param point
      */
     const deleteCallback = (dataSeq: string, counterIdx: number) => {
         if (!isChange) {
             setIsChange(true);
         }
 
         setResultList(resultList.map((result) => result.countIdx === counterIdx ? {
             ...result,
             MEAS_VAL: undefined,
             MEAS_RESULT: undefined,
         } : result));
     };
 
 
     /**
      * 문자형일때만, radio버튼 callback
      * @param e
      * @param dataSeq
      * @param counterIdx
      * @param radioData
      */
     const itemRadioCallback = (e: React.MouseEvent<HTMLInputElement>, dataSeq: string, counterIdx: number, radioData: string) => {
         if (!isChange) {
             setIsChange(true);
         }
         let measResult = radioData;
 
         setResultList(resultList.map((result) => result.countIdx === counterIdx ? {
             ...result,
             MEAS_RESULT: measResult
         } : result));
     };
 
 
     /**
      * 사진촬영  callback
      * @param seq
      */
     const photoCallback = (seq: number) => {
         imageRef.current[seq]?.click();
     };
 
 
     /**
      * 사진촬영 change event
      * @param e
      * @param seq : 검사항목
      * @param idx : 테스트 수            
      */
     const photoChangeCallback = (e: React.ChangeEvent<HTMLInputElement>, seq: number, idx: number) => {
         if (e.target.files) {
             setPictureFile(e.target.files[0]);
             let resultMap: any = resultList.filter(result => result.SEQ == idx && result.seqVal == seq);
             setInspViewerMap(resultMap);
             setQpointFileId(resultMap[0]["QPOINT_FILE_ID"]);
         }
     };
 
     useEffect(() => {
         if (pictureFile) {
             setShowInspViewer(true);
         }
     }, [pictureFile]);
 
     /**
      * 검사결과 확인 confirm
      * @param resMap
      * @param pictureFile
      */
     const callbackInspResultConfirm = async (resMap: any, pictureFile: File) => {
         setShowInspViewer(false);
 
         const formData = new FormData();
         let fileNm: string;
         const options = {
             maxWidthOrHeight: 1920, //width
             initialQuality: 0.8 //quality
         }
         if (pictureFile != undefined) {
             try {
                 const compressedFile = await imageCompression(pictureFile, options);
                 const resizeFile = new File([compressedFile], pictureFile.name, {type: pictureFile.type});
                 formData.append('file', resizeFile);
                 fileNm = pictureFile.name;
             } catch (error) {
                 console.log(error);
             }
 
             formData.append("workType", "PSIS");
             //ocr 사용 여부에 따라서... 문자형인지도 구분해야 함...
             let resultMap: any = resultList.filter(result => result.SEQ == resMap[0]["SEQ"] && result.seqVal == resMap[0]["seqVal"]);
             let detailResultMap: any = detailResultList.filter(detail => detail.SEQ == resMap[0]["seqVal"]);
             let pLSL: number = detailResultMap[0]["LSL"];
             let pUSL: number = detailResultMap[0]["USL"];
             let pCL: number = detailResultMap[0]["CL"];
             let pPoint: number = detailResultMap[0]["POINT"];
             let ocrList: any = [];
             //let ocrList:any = ["32","33.1","33.2","30","29","28"];
             if (useOCR && resultMap[0]["NUM_YN"] === 'Y') {
 
                 let inputDatasets: any = {
                     ds_input: [{
                         INSP_TARGET_CD: resultMap[0]["INSP_TARGET_CD"],
                         INSP_CD: resultMap[0]["INSP_CD"],
                         INSP_TYPE: resultMap[0]["INSP_TYPE"],
                         INSP_SEQ: resultMap[0]["seqVal"],
                         INSERT_BY: sessionValues?.USER_ID
                     }]
                 }
                 formData.append("inputDatasets", String(requestParser(inputDatasets)));
 
                 await sendAxios('/common/nexacro/ocrFileUpload.do', 'POST', AXIOS_HEADER.FORM, formData)
                     .then(response => {
                         for (const ds_res of responseParser(response?.data)?.ocr_result || []) {
                             if (ds_res["RESULT_DATA"] !== undefined) {
 
                                 let cntVal = String(ds_res["RESULT_DATA"]).replace(/[^.0-9]/g, "");
                                 let measure: number = 0;
 
                                 if (!isNaN(toNumber(cntVal))) {
                                     measure = toNumber(cntVal);
                                 }
                                 ocrList.push(measure);
                             }
                         }
                         ocrList = ocrList.filter((v: number, i: number) => ocrList.indexOf(v) === i).sort(function (a: number, b: number) {
                             return Math.abs((pCL - a)) - Math.abs((pCL - b));
                         });
                         //결과 없을시,
                         if (ocrList.length == 0) {
                             setDialog({
                                 type: 'ALERT',
                                 show: true,
                                 text: getMessage('MSG01539'),
                                 confirm: () => {
                                 }
                             });
                         }
                         if (ocrList.length > 9) {
                             ocrList = ocrList.filter((v: number, i: number) => i < 9);
                         }
 
                         let pMeasResult = "NG";
                         if (ocrList.length === 1) {
                             //값이 LSL , USL 안에 들어갈시에는 측정값은 OK로 변경 처리
                             if (pUSL >= ocrList[0] && ocrList[0] >= pLSL) {
                                 pMeasResult = "OK";
                             }
                             if (isNaN(toNumber(pUSL))) {
                                 if (ocrList[0] >= toNumber(pLSL)) {
                                     pMeasResult = "OK";
                                 }
                             } else if (isNaN(toNumber(pLSL))) {
                                 if (toNumber(pUSL) >= ocrList[0]) {
                                     pMeasResult = "OK";
                                 }
                             } else {
                                 if (toNumber(pUSL) >= ocrList[0] && ocrList[0] >= toNumber(pLSL)) {
                                     pMeasResult = "OK";
                                 }
                             }
                         }
 
                         for (const ds_res of responseParser(response?.data)?.ds_file || []) {
                             if (ds_res.FILE_ID !== undefined) {
                                 setInspFile(fileNm);
                                 setInspFileId(String(ds_res.FILE_ID));
 
                                 setResultList(resultList.map((result) => result.SEQ === resultMap[0]["SEQ"] && result.seqVal == resultMap[0]["seqVal"] ? {
                                     ...result,
                                     selectOcr: ocrList.length === 1 ? 0 : undefined,
                                     MEAS_VAL: ocrList.length === 1 ? ocrList[0] : result["MAES_VAL"],
                                     MEAS_RESULT: ocrList.length === 1 ? pMeasResult : result["MAES_RESULT"],
                                     ocrList: ocrList,
                                     FILE_ID: ds_res.FILE_ID,
                                     FILE_NM: fileNm
                                 } : result));
                             }
 
                         }
                     })
                     .catch(e => {
                         throwException(e);
                         return new Promise(() => {
                         });
                     });
             } else {
                 sendAxios('/common/nexacro/commonSingleFileUpload.do', 'POST', AXIOS_HEADER.FORM, formData)
                     .then(response => {
                         for (const ds_res of responseParser(response?.data)?.ds_output || []) {
                             if (ds_res.RTN_CODE === "SUCESS") {
                                 if (ds_res.RTN_MSG !== undefined) {
                                     setInspFile(fileNm);
                                     setInspFileId(String(ds_res.RTN_MSG));
                                     setResultList(resultList.map((result) => result.SEQ === inspViewerMap[0]["SEQ"] && result.seqVal == inspViewerMap[0]["seqVal"] ? {
                                         ...result,
                                         FILE_ID: ds_res.RTN_MSG,
                                         FILE_NM: fileNm
                                     } : result));
                                 }
                             }
                         }
                     })
                     .catch(e => {
                         throwException(e);
                         return new Promise(() => {
                         });
                     });
             }
         }
     }
 
     /**
      * OCR 클릭 Callback
      * @param inpCd
      * @param seq
      * @param idx
      */
     const ocrClickCallback = (inpCd: string, seq: number, idx: number, ocrNum: number, ocrVal: number) => {
         if (!isChange) {
             setIsChange(true);
         }
         let newInspectResult = detailResultList.filter(inspectResult => inspectResult.INSP_CD === inpCd);
         let point = newInspectResult[0]["POINT"];
         ocrVal = floor(ocrVal, point);
         //기본값은 NG
         let measResult = "NG";
         //값이 LSL , USL 안에 들어갈시에는 측정값은 OK로 변경 처리
         if (toNumber(newInspectResult[0].USL) >= ocrVal && ocrVal >= toNumber(newInspectResult[0].LSL)) {
             measResult = "OK";
         }
 
         setResultList(resultList.map(result => result.SEQ == idx && result.seqVal == seq ? {
             ...result,
             OCR_YN: 'Y',
             MEAS_VAL: ocrVal.toFixed(point),
             MEAS_RESULT: measResult,
             selectOcr: ocrNum,
         } : result));
 
     }
 
 
     /**
      * 대책서 작성 팝업 open
      */
     const counterMeasCallback = (inspTargetCd: string, inspCd: string, inspType: string) => {
 
         let counterData: any = {
             inspTargetCd: inspTargetCd,
             inspCd: inspCd,
             inspType: inspType
 
         }
         setCounterMap(counterData);
         setShowCounter(true);
     };
 
     /**
      * 검사결과 임시저장 Click Event
      */
     const callbackTempSave = () => {
 
         if (detailResultList.filter(result => result.SAMPLE_CNT == 0).length === detailResultList.length) {
             setDialog({
                 type: 'ALERT',
                 show: true,
                 text: getMessage('MSG01512'),
                 confirm: () => {
                 }
             });
         } else {
             setDialog({
                 type: 'CONFIRM',
                 show: true,
                 text: getMessage('MSG01517'),
                 confirm: () => {
                     inspectList[0]["STATUS"] = "01"; //대기 상태
                     let inspResultVal = "P";
                     if (Math.max(...detailResultList.map(target => target.NG_CNT)) > 0) {
                         inspResultVal = "F";
                     }
                     inspectList[0]["INSP_RESULT"] = inspResultVal;
                     inspectList[0]["INSP_BY"] = sessionValues?.USER_ID;
 
                     sendAxios('/common/nexacro/updatePSISInspTargetMobile.do', 'POST', AXIOS_HEADER.NEXACRO, {
                         ds_inspTarget: inspectList,
                         ds_inspDetail: resultList.filter((result) => (result.MEAS_RESULT !== undefined && result.MEAS_RESULT !== '')).map(detail => detail.ocrList !== undefined ? {
                             ...detail,
                             ocrList: undefined,
                         } : detail)
                     })
                         .then(response => {
                             if (response?.data.ds_res[0]?.CODE === "SUCESS") {
                                 setDialog({
                                     type: 'ALERT',
                                     show: true,
                                     text: getMessage('MSG01511'),
                                     confirm: () => {
                                         pageMove(-1);
                                     }
                                 });
                             }
                         })
                         .catch(e => {
                             throwException(e);
                             return new Promise(() => {
                             });
                         });
                 }
             });
         }
 
     };
 
     /**
      * 검사결과 검사완료 Click Event
      */
     const callbackCompleSave = () => {
 
         if (detailResultList.filter(result => result.NG_CNT > 0 && result.COUNTER_CNT == 0).length > 0) {
             setDialog({
                 type: 'ALERT',
                 show: true,
                 text: getMessage('MSG01513'),
                 confirm: () => {
                     return;
                 }
             });
         } else {
             setDialog({
                 type: 'CONFIRM',
                 show: true,
                 text: getMessage('MSG01514'),
                 confirm: () => {
                     inspectList[0]["STATUS"] = "01"; //검사완료
                     inspectList[0]["INSP_CNT"] = toNumber(inspectList[0]["INSP_CNT"]) + 1;
 
                     let inspResultVal = "P";
                     if (Math.max(...detailResultList.map(target => target.NG_CNT)) > 0) {
                         inspResultVal = "F";
                     }
                     inspectList[0]["INSP_RESULT"] = inspResultVal;
                     inspectList[0]["INSP_BY"] = sessionValues?.USER_ID;
 
                     sendAxios('/common/nexacro/updatePSISInspTargetMobile.do', 'POST', AXIOS_HEADER.NEXACRO, {
                         ds_inspTarget: inspectList,
                         ds_inspDetail: resultList.filter((result) => (result.MEAS_RESULT !== undefined && result.MEAS_RESULT !== '')).map(detail => detail.ocrList !== undefined ? {
                             ...detail,
                             ocrList: undefined,
                         } : detail)
                     })
                         .then(response => {
                             if (response?.data.ds_res[0]?.CODE === "SUCESS") {
                                 setDialog({
                                     type: 'ALERT',
                                     show: true,
                                     text: getMessage('MSG01515'),
                                     confirm: () => {
                                         pageMove(-1);
                                     }
                                 });
                             }
                         })
                         .catch(e => {
                             throwException(e);
                             return new Promise(() => {
                             });
                         });
                 }
             });
         }
     };
 
     return (
         <>
             {(!showDetail && !showImage && !showCounter && !showInspViewer) && (
                 <div className={'container'} style={{overflowY: "auto"}}>
                     <div className={'card-wrap single'}>
                         <ul className={'card-list'}>
                             {
                                 inspectList.map((item, index) => {
                                     return <li className={'card-item'} key={'cardView_'.concat(String(index))}>
                                         <CardMainView data={item}/>
                                     </li>
                                 })
                             }
                         </ul>
                     </div>
                     <div className={'padding-box'}>
                         <div className={'result-state'}>
                             {
                                 resultList.filter((x) => x.SEQ === focusTab && (x.MEAS_RESULT === undefined || x.FILE_ID === undefined || (x.MEAS_RESULT == 'NG' && detailResultList.filter((detail) => detail.INSP_CD === x.INSP_CD && Number(detail.COUNTER_CNT) === 0).length > 0))).length > 0 ?
                                     <span className={'result'}>{getDomain('DOMAIN4566')}</span>
                                     : resultList.filter((x) => x.SEQ === focusTab && x.MEAS_RESULT == 'NG').length > 0 ?
                                         <span className={'result fail'}>FAIL</span>
                                         : <span className={'result pass'}>PASS</span>
                             }
                         </div>
                         <div className={'tabs-wrap type2'}>
                             <ul className={'tab-list'}  ref={scrollRef}>
                                 {
                                     Array(inspCnt || 1).fill(0).map((_, i) => (
                                         <li className={focusTab === (i + 1) ? 'on' : ''}
                                             key={'inspCntTabList'.concat(String(i))}>
                                             <button type={'button'} onClick={() => selectTab((i + 1))}>#{i + 1}</button>
                                         </li>
                                     ))
                                 }
                             </ul>
                         </div>
                         <div className={'table-wrap'}>
                             <table>
                                 <colgroup>
                                     <col style={{width: '2.4rem'}}/>
                                     <col style={{width: '5rem'}}/>
                                     <col/>
                                     <col style={{width: '4.2rem'}}/>
                                 </colgroup>
                                 <thead>
                                 <tr>
                                     <th scope={'col'}>No</th>
                                     <th scope={'col'}>{getDomain('DOMAIN4744')}</th>
                                     <th scope={'col'}>{getDomain('DOMAIN4745')}</th>
                                     <th scope={'col'}>{getDomain('DOMAIN3355')}</th>
                                 </tr>
                                 </thead>
                                 <tbody>
                                 {
                                     resultList.filter((x) => x.SEQ == focusTab).map((item, index) => {
                                         return <InspTableView data={item} key={'inspTableView'.concat(String(index))}/>
                                     })
                                 }
                                 </tbody>
                             </table>
                         </div>
                     </div>
                     <div className={'btn-area bottom'}>
                         <button type={'button'} className={'button-line'}
                                 onClick={() => callbackTempSave()}>{getDomain('DOMAIN4734')}
                         </button>
                         {
                             resultList.filter(result => result.MEAS_RESULT === undefined || result.FILE_ID === undefined).length > 0 && (
                                 <button type={'button'} className={'button-primary'}
                                         disabled>{getDomain('DOMAIN4733')}</button>
                             )
                         }
                         {
                             resultList.filter(result => result.MEAS_RESULT === undefined || result.FILE_ID === undefined).length == 0 && (
                                 <button type={'button'} className={'button-primary'}
                                         onClick={() => callbackCompleSave()}>{getDomain('DOMAIN4733')}</button>
                             )
                         }
                     </div>
                 </div>
             )}
 
             <CSSTransition in={showDetail} classNames={showDetail ? 'right' : 'left'} timeout={500} unmountOnExit>
                 <div className={'pop-layer'} style={{display: 'block', zIndex: 200}}>
                     <div className={'wrap'}>
                         <header>
                             <h1>{getDomain('DOMAIN4565')}</h1>
                             <span className={'btn-wrap right'}>
                                     <button type={'button'} className={'button-close'} onClick={() => callbackClose()}>
                                         <i className={'hidden'}>닫기</i>
                                     </button>
                                 </span>
                         </header>
                         <div className={'container-noheader'}>
                             <div className={'comm-slide-wrap'} style={{
                                 display: 'block',
                                 width: '100%',
                                 height: 'unset',
                                 overflowY: 'auto'
                             }}>
                                 <div className={'swiper-pagination'} style={{
                                     width: '100% !important',
                                     paddingBottom: '2rem'
                                 }}></div>
 
                                 {/*23.11.06 네비게이션 기능 추가*/}
                                 <div className={'swiper-next-nopage'}></div>
                                 <div className={'swiper-prev-nopage'}></div>
 
                                 <Swiper modules={[Pagination, Navigation]} initialSlide={selectedIndex || 0} pagination={{
                                     clickable: true, dynamicBullets: true,
                                     el: '.swiper-pagination'
                                 }} className={'swiper mySwiper'}
                                 navigation={{
                                     nextEl: ".swiper-next-nopage",
                                     prevEl: ".swiper-prev-nopage",
                                 }}
                                 >
                                     {
                                         detailResultList.map((item, index) => {
                                             return <SwiperSlide style={{minHeight: gMinHeight}}
                                                                 key={'inspMainSwiperSlide'.concat(item.SEQ).concat(String(index))}>
                                                 <InspMainList data={item} key={uuidv4()}/>
                                             </SwiperSlide>
                                         })
                                     }
                                 </Swiper>
                             </div>
                             <div className={'btn-area bottom'}>
                                 <button type={'button'} className={'button-primary'}
                                         onClick={() => callbackClose()}>{getDomain('DOMAIN0173')}
                                 </button>
                             </div>
                         </div>
                     </div>
                 </div>
             </CSSTransition>
             {/*)}*/}
             <CSSTransition in={showImage} classNames={showImage ? 'right' : 'left'} timeout={500} unmountOnExit>
                 <ImageViewer title={titleNm} imageFileId={imageFileId} onClose={closeImage}/>
             </CSSTransition>
             <CSSTransition in={showCounter} classNames={showCounter ? 'right' : 'left'} timeout={500} unmountOnExit>
                 <CounterViewer title={getDomain('DOMAIN4573')} counterMap={counterMap} onClose={closeCounter}
                                onSave={(inspCd) => closeSaveCounter(inspCd)}/>
             </CSSTransition>
             <CSSTransition in={showInspViewer} classNames={showInspViewer ? 'right' : 'left'} timeout={500}
                            unmountOnExit>
                 <InspResultViewer inspMap={inspViewerMap} fileId={qpointFileId}
                                   onConfirm={(inspViewerMap, pictureFile) => callbackInspResultConfirm(inspViewerMap, pictureFile)}
                                   shootingFile={pictureFile}/>
             </CSSTransition>
 
 
         </>
     );
 
     /**
      * 카드뷰 동적 생성 처리
      * @param data
      * @param callback
      * @constructor
      */
     function CardMainView({data, callback}: { data: any, callback?: () => void }) {
         return (
             <>
                 <div className={'item'} role={'button'}>
                     <section>
                         <div className={'label-wrap'}>
                             {data.HQ_INSP_YN === 'Y' && (
                                 <i className={'label-type5'}>{data.HQ_INSP_YN_NM}</i>
                             )}
                             {data.HQ_INSP_YN === 'N' && (
                                 <i className={'label-type16'}>{data.HQ_INSP_YN_NM}({data.INSP_CNT})</i>
                             )}
                             {data.TIME_VALUE === '01' && (
                                 <i className={'label-time1'}>{(Number(data.TIME_REMAIN) / 60).toFixed(0)}hr</i>
                             )}
                             {data.TIME_VALUE === '02' && (
                                 <i className={'label-time2'}>{(Number(data.TIME_REMAIN) / 60).toFixed(0)}hr</i>
                             )}
                             {data.TIME_VALUE === '03' && (
                                 <i className={'label-time3'}>
                                     {
                                         Number((Number(data.TIME_REMAIN) / 60).toFixed(0)) > 0 ? Number(Number(data.TIME_REMAIN) / 60).toFixed(0).concat('hr') : String(Number(data.TIME_REMAIN)).concat('min')
                                     }
                                 </i>
                             )}
                         </div>
                         <div className={'title-wrap'}>
                             <span>[{data.PLANT_CD}] [{data.PART_CD}]</span>
                             <strong><a style={{'textDecorationLine': 'underline', 'color': 'blue'}}
                                        onClick={() => imageViewerCallback(data.PART_FILE_ID, getDomain('DOMAIN4748'))}>{data.PART_NM.split(';', 1)}</a></strong>
                         </div>
                     </section>
                     <ul className={'date-list'}>
                         {
                             <li>
                                 <em style={{width: '50%'}}>
                                     <a style={{'textDecorationLine': 'underline', 'color': 'blue'}}
                                        onClick={() => imageViewerCallback(data.LINE_FILE_ID, 'LINE'.concat(' ', getDomain('DOMAIN4748')))}>[{data.LINE_CD}] {data.LINE_NM}</a>
                                 </em>
                                 <span style={{width: '50%'}}>{data.INSERT_DT_EXPR}</span>
                             </li>
                         }
                         {
                             <li>
                                 <em style={{width: '50%'}}>{data.SPEC_CNT_EXPR}</em>
                                 <span
                                     style={{width: '50%'}}>{getDomain('DOMAIN4730')} {data.TARGET_CNT.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                             </li>
                         }
                     </ul>
                 </div>
             </>
         );
     };
 
 
     /**
      * 검사항목 테이블 동적 생성 처리
      * @param data
      * @param callback
      * @constructor
      */
     function InspTableView({data, callback}: { data: any, callback?: () => void }) {
         return (
             <>
                 <tr>
                     <td><a onClick={(e) => callbackSwipe(e, toNumber(data.seqVal))}>{data.seqVal}</a></td>
                     <td><a onClick={(e) => callbackSwipe(e, toNumber(data.seqVal))}>{data.INSP_TYPE}</a></td>
                     <td className={'left'}><a onClick={(e) => callbackSwipe(e, toNumber(data.seqVal))}>[{data.INSP_CD}] {data.INSP_NM}</a>
                     </td>
                     <td><a onClick={(e) => callbackSwipe(e, toNumber(data.seqVal))}>
                         {(data.MEAS_RESULT === 'OK' && data.FILE_ID !== undefined) ?
                             <span className={'label-state ok'}>OK</span>
                             : (data.MEAS_RESULT === 'NG' && detailResultList.filter((detail) => (detail.INSP_CD == data.INSP_CD) && detail.COUNTER_CNT > 0).length > 0 && data.FILE_ID !== undefined) ?
                                 <span className={'label-state ng'}>NG</span>
                                 : <span className={'label-state wait'}>{getDomain('DOMAIN4700')}</span>
                         }
                     </a>
                     </td>
                 </tr>
             </>
         );
     };
 
     /**
      * 검사항목 메인 리스트
      * @param data
      * @param callback
      * @constructor
      */
     function InspMainList({data}: { data: any, callback?: () => void }) {
         return (
             <>
                 <div className={'swiper-wrapper'}>
                     <div className={'swiper-slide'}>
                         <ul className={'contents-table-list'}>
                             <li>
                                 <div className={'col'}>
                                     <span className={'title'} style={{
                                         wordBreak: 'break-word',
                                         textAlign: 'center'
                                     }}>{getDomain('DOMAIN4745')}</span>
                                     <span className={'item-wrap'}>
                                     [{data.INSP_CD}] {data.INSP_NM}
                                         <button type={'button'} className={'btn-gallary'}
                                                 style={{verticalAlign: 'middle'}}
                                                 onClick={() => imageViewerCallback(data.QPOINT_FILE_ID, getDomain('DOMAIN4585'))}><i
                                             className={'hidden'}>갤러리</i></button>
                                 </span>
                                 </div>
                             </li>
                             <li>
                                 <div className={'col'}>
                                     <span className={'title'} style={{
                                         wordBreak: 'break-word',
                                         textAlign: 'center'
                                     }}>{getDomain('DOMAIN4567')}</span>
                                     <span className={'item-wrap center'} style={{justifyContent:"center"}}>{data.INSP_TYPE}</span>
                                 </div>
                                 <div className={'col'}>
                                     <span className={'title'} style={{
                                         wordBreak: 'break-word',
                                         textAlign: 'center'
                                     }}>{getDomain('DOMAIN4749')}</span>
                                     <span className={'item-wrap center'} style={{justifyContent:"center"}}>{data.EQUIP_NM}</span>
                                 </div>
                             </li>
                             <li>
                                 <div className={'col'}>
                                     <span className={'title'} style={{
                                         wordBreak: 'break-word',
                                         textAlign: 'center'
                                     }}>LSL</span>
                                     <span className={'item-wrap right'} style={{
                                         wordBreak: 'break-word'
                                     }}>{data.NUM_YN === 'Y' && data.LSL !== undefined ? Number(data.LSL).toFixed(data.POINT) : data.LSL}</span>
                                 </div>
                                 <div className={'col'}>
                                     <span className={'title'} style={{
                                         wordBreak: 'break-word',
                                         textAlign: 'center'
                                     }}>USL</span>
                                     <span className={'item-wrap right'} style={{
                                         wordBreak: 'break-word'
                                     }}>{data.NUM_YN === 'Y' && data.USL !== undefined ? Number(data.USL).toFixed(data.POINT) : data.USL}</span>
                                 </div>
                             </li>
                             <li>
                                 <div className={'col'}>
                                     <span className={'title'} style={{
                                         wordBreak: 'break-word',
                                         textAlign: 'center'
                                     }}>CL</span>
                                     <span className={'item-wrap right'} style={{
                                         wordBreak: 'break-word'
                                     }}>{data.NUM_YN === 'Y' && data.CL != undefined ? Number(data.CL).toFixed(data.POINT) : data.CL}</span>
                                 </div>
                                 <div className={'col'}>
                                     <span className={'title'} style={{
                                         wordBreak: 'break-word',
                                         textAlign: 'center'
                                     }}>{getDomain('DOMAIN4428')}</span>
                                     <span className={'item-wrap center'}
                                           style={{wordBreak: 'break-word', justifyContent:'center'}}>{data.UNIT}</span>
                                 </div>
                             </li>
                         </ul>
                     </div>
                 </div>
                 <div className={'photo-wrap'}>
                     <h2 className={'h2-title'}>{getDomain('DOMAIN4568')}</h2>
                     <div className={'btn-shoot-wrap'}>
                         {
                             resultList.filter((x) => x.INSP_CD === data.INSP_CD && x.SEQ == focusTab).map((t, i) => (
                                 <input key={uuidv4()} type={'text'} placeholder={getDomain('DOMAIN4583')}
                                        defaultValue={t.FILE_NM}
                                        onClick={() => inspCnt > focusTab ? imageViewerCallback(t.FILE_ID, getDomain('DOMAIN4748')) : photoCallback(data.SEQ)}
                                        readOnly={true}/>
                             ))
                         }
                         <input type={'file'} ref={(el) => imageRef.current[toNumber(data.SEQ)] = el}
                                id={'photoFile' + data.SEQ} accept={"image/*"} capture={"environment"}
                                style={{display: "none"}} onChange={e => photoChangeCallback(e, data.SEQ, focusTab)}/>
                         <button type={'button'} disabled={inspCnt > focusTab ? true : false}
                                 onClick={() => photoCallback(data.SEQ)} className={'button-shoot'}><i
                             className={'hidden'}>{getDomain('DOMAIN4569')}</i></button>
                     </div>
                 </div>
                 <div className={'result-box'}>
                     <div className={'h2-title-wrap'}>
                         <h2 className={'h2-title'}>{getDomain('DOMAIN2612')}</h2>
                         {data.NUM_YN === 'Y' && useOCR && (
                             <em>{getDomain('DOMAIN4576')}</em>
                         )}
                     </div>
                     {data.NUM_YN === 'Y' && useOCR && (
                         <div className={'barcode-wrap'}>
                             <div className={'barcode-item'}>
                                 <span className={'btn-ocr'}><i
                                     className={'hidden'}>barcode</i></span>
                                 {getDomain('DOMAIN4577')}
                             </div>
                             {
                                 resultList.filter((x) => x.INSP_CD === data.INSP_CD && x.SEQ == focusTab).map((t, i) => (
                                     <div className={'inp-wrap'} key={'resultList'.concat(t.INSP_CD).concat(String(i))}>
                                          <span className={'inp-item'}>
                                             <input type={'text'} style={{textAlign: 'left'}}
                                                    pattern={'d\*'}
                                                    placeholder={getDomain('DOMAIN4578')}
                                                    tabIndex={toNumber(data.SEQ.concat("0")) + i}
                                                    defaultValue={t.MEAS_VAL}
                                                    disabled={inspCnt > t.SEQ ? true : false}
                                                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onBlurCallback(e, data.SEQ, i, t.countIdx, data.POINT)}
                                                    ref={(el:HTMLInputElement) => inputRef.current[t.countIdx] = {seq:toNumber(data.SEQ),index: i, el: el}}
                                                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => inputCallback(e, data.SEQ, i, t.countIdx, data.POINT)}
                                                    // onClick={(e) => setNextMap({seq: toNumber(data.SEQ), index : i })}
                                                    />
                                              {inspCnt <= t.SEQ && (
                                                  <button type={'button'} className={'button-cancel'}
                                                          onClick={() => deleteCallback(data.SEQ, t.countIdx)}><i
                                                      className={'hidden'}>{getDomain('DOMAIN0229')}</i></button>
                                              )}
                                          </span>
                                         {(t.MEAS_RESULT === 'OK') && (
                                             <span className={'label-state ok'}>OK</span>
                                         )}
                                         {(t.MEAS_RESULT === 'NG') && (
                                             <span className={'label-state ng'}>NG</span>
                                         )}
                                     </div>
                                 ))
                             }
                             {
                                 resultList.filter((x) => x.INSP_CD === data.INSP_CD && x.SEQ == focusTab).map((t, i) => (
                                     <ul className={'num-btn-list'} key={uuidv4()}>
                                         {(t.ocrList.length > 0) && (
                                             t.ocrList.map((ocrVal: number, n: number) => (
                                                 <li key={uuidv4()}>
                                                      <span className={t.selectOcr == n ? 'item on' : 'item'}>
                                                         <button type={'button'} className={'button-number'}
                                                                 onClick={e => ocrClickCallback(t.INSP_CD, data.SEQ, focusTab, n, ocrVal)}>{ocrVal}</button>
                                                      </span>
                                                 </li>
                                             ))
                                         )}
                                     </ul>
                                 ))
                             }
                         </div>
                     )}
                     {data.NUM_YN === 'Y' && !useOCR && (
                         <ul className={'result-write-list'}>
                             {
                                 resultList.filter((x) => x.INSP_CD === data.INSP_CD && x.SEQ == focusTab).map((t, i) => (
                                     <li key={'resultList'.concat(t.INSP_CD).concat(String(i))}>
                                         <div className={'inp-item'}>
                                             <input type={'text'} placeholder={getDomain('DOMAIN4579')}
                                                pattern={'-?\d+\.?\d+'}
                                                inputMode={isIOS ? undefined : "numeric"}
                                                tabIndex={toNumber(data.SEQ.concat("0")) + i}
                                                defaultValue={t.MEAS_VAL}
                                                disabled={inspCnt > t.SEQ ? true : false}
                                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onBlurCallback(e, data.SEQ, i, t.countIdx, data.POINT)}
                                                ref={(el:HTMLInputElement) => inputRef.current[t.countIdx] = {seq:toNumber(data.SEQ),index: i, el: el}}
                                                onInput={(e: React.ChangeEvent<HTMLInputElement>) => inputCallback(e, data.SEQ, i, t.countIdx, data.POINT)}
                                                // onClick={(e) => setNextMap({seq: toNumber(data.SEQ), index : i })}
                                            />
                                         </div>
                                         <div className="label-item">
                                             {(t.MEAS_RESULT === 'OK') && (
                                                 <span className="label-state ok">OK</span>
                                             )}
                                             {(t.MEAS_RESULT === 'NG') && (
                                                 <span className="label-state ng">NG</span>
                                             )}
                                         </div>
                                     </li>
                                 ))
                             }
                         </ul>
                     )}
                     {data.NUM_YN === 'N' && (
                         <ul className={'result-write-list radio-type'}>
                             {
                                 resultList.filter((x) => x.INSP_CD === data.INSP_CD && x.SEQ == focusTab).map((t, i) => (
                                     <li key={'resultList'.concat(t.INSP_CD).concat(String(i))}>
                                         <div className={'radio-item'} tabIndex={toNumber(data.SEQ.concat("0")) + i}>
                                             <label>
                                                 <input type={'radio'} name={'radio_' + t.countIdx}
                                                        defaultChecked={t.MEAS_RESULT === 'OK'}
                                                        disabled={inspCnt > t.SEQ ? true : false}
                                                        onClick={e => itemRadioCallback(e, data.SEQ, t.countIdx, 'OK')}/> OK
                                             </label>
                                             <label>
                                                 <input type={'radio'} name={'radio_' + t.countIdx}
                                                        defaultChecked={t.MEAS_RESULT === 'NG'}
                                                        disabled={inspCnt > t.SEQ ? true : false}
                                                        onClick={e => itemRadioCallback(e, data.SEQ, t.countIdx, 'NG')}/> NG
                                             </label>
                                         </div>
                                     </li>
                                 ))
                             }
                         </ul>
                     )}
                     <div className={'dashed-box'} style={{padding: '1.5rem 0 0'}}>
                         <h2 className={'h2-title'}>{getDomain('DOMAIN4573')}</h2>
                         <div className={'result-file'}>
                             <div className={'title'}>{getDomain('DOMAIN4574')}</div>
                             <div className={'file-name'}>
                                 {
                                     resultList.filter(result => result.INSP_CD === data.INSP_CD && result.MEAS_RESULT === 'NG').length == 0 && (
                                         <button type={'button'}
                                                 style={{inlineSize: 'fit-content'}}
                                                 className={'button-primary sm write'}>{getDomain('DOMAIN4582')}</button>
                                     )
                                 }
                                 {
                                     resultList.filter(result => result.INSP_CD === data.INSP_CD && result.MEAS_RESULT === 'NG').length > 0 && (
                                         <button type={'button'} className={'button-secondary sm write'}
                                                 style={{backgroundColor: '#3478F5', inlineSize: 'fit-content'}}
                                                 onClick={() => counterMeasCallback(data.INSP_TARGET_CD, data.INSP_CD, data.INSP_TYPE)}>
                                             {data.COUNTER_CNT > 0 ? getDomain('DOMAIN4735') : getDomain('DOMAIN4582')}
                                         </button>
                                     )
                                 }
                             </div>
                         </div>
                     </div>
                 </div>
             </>
         );
     };
 };