/**************************************************************************************************
 * 01. 업무구분	    : P-SIS > 검사결과 입력 > 검사결과 뷰어
 * 02. 프로그램ID	: InspResultViewer.tsx
 * 03. 프로그램명	    : P-SIS > 검사결과 입력 > 검사결과 뷰어
 * 04. 화면설명	    : P-SIS > 검사결과 입력 > 검사결과 뷰어
 * 05. 작성일		: 2023.10.04
 * 06. 작성자		: YGY
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-10-04     YGY    최초 작성
 **************************************************************************************************
 */
/******************************************************************************************
 *                                        변경이력                                         *
 *----------   ----------   ------------   ------------------------------------------------*
 * 변경번호     변경일자       변경자                        변경내용                      *
 *----------   ----------   ------------   ------------------------------------------------*

 ******************************************************************************************/

import React, {useEffect, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {EffectFade, Navigation, Pagination, Zoom} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import {v4 as uuidv4} from 'uuid';
import useAxios, {AXIOS_HEADER} from '../../../hooks/useAxios';
import {useError} from '../../../hooks/useException';
import useGlobalData from "../../../hooks/useGlobalData";
import {useLocation} from "react-router-dom";

export default function InspResultViewer({inspMap, fileId, onConfirm, shootingFile}: {
    inspMap: any,
    fileId: string,
    onConfirm: (inspMap: any, resultFile: File) => void,
    shootingFile: any
}) {
    const {sendAxios} = useAxios();
    const {throwException} = useError();
    const location = useLocation();
    const {getGlobalSetting, setGlobalSetting, getDomain, getMessage} = useGlobalData();
    const [fileList, setFileList] = useState<{ [K: string]: string }[]>();
    const [inspFilePath, setInspFilePath] = useState<string | undefined>();
    const [inspResultFile, setInspResultFile] = useState<File>(shootingFile);
    const imageRef = useRef<any>([]);
    const insptPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setInspFilePath(URL.createObjectURL(event.target.files[0]));
            setInspResultFile(event.target.files[0]);
        }
    };

    useEffect(() => {

        sendAxios('/common/nexacro/commonSelectFileList.do', 'POST', AXIOS_HEADER.NEXACRO, {
            ds_Search: [{
                FILE_ID: fileId
            }]
        })
            .then(response => {
                let file: { [K: string]: string }[] = [];
                for (const path of response?.data.ds_List) {
                    file.push({
                        DB_SRCH: path.DB_SRCH,
                        DESCRIPTION: path.DESCRIPTION,
                        DOWNLOAD_TYPE: path.DOWNLOAD_TYPE,
                        FILE_EXTENSION_NAME: path.FILE_EXTENSION_NAME,
                        FILE_ID: path.FILE_ID,
                        FILE_MIME_TYPE_NAME: path.FILE_MIME_TYPE_NAME,
                        FILE_NAME: path.FILE_NAME,
                        FILE_PATH_NAME: '/images/@temp1.png',
                        FILE_SEQ: path.FILE_SEQ,
                        FILE_SIZE: path.FILE_SIZE,
                        FIRST_REGR_ID: path.FIRST_REGR_ID,
                        FIRST_REG_DATETIME: path.FIRST_REG_DATETIME,
                        FROM_DATE: path.FROM_DATE,
                        MODULE_ID: path.MODULE_ID,
                        ORDER_IDX: path.ORDER_IDX,
                        THRU_DATE: path.THRU_DATE
                    });
                }

                if (file.length > 0) {
                    setFileList(file);
                }
                setInspFilePath(URL.createObjectURL(shootingFile));
            })
            .catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });
    }, [fileId]);

    /**
     * 사진촬영  callback
     * @param seq
     */
    const photoCallback = () => {
        imageRef.current?.click();
    };

    const confirmCallBack = () => {
        onConfirm(inspMap, inspResultFile);
    };

    return (
        <>
            <div className={'pop-layer'} style={{display: 'block', zIndex: 300}}>
                <div className={'wrap'}>
                    <div className={'container-noheader'} style={{overflowY: 'auto', paddingTop:'0rem'}}>
                        {/*<div className={'comm-slide-wrap'}>*/}
                        <div>
                            <div className={'swiper-pagination swiper-pagination-img'} style={{
                                width: '100% !important',
                                paddingBottom: '2rem'
                            }}></div>
                            <div className={'result-img-box'}>
                                <h2 className={'h2-title'}>{getDomain('DOMAIN4585')}</h2>
                            </div>
                            <Swiper
                                modules={[Zoom, Pagination, Navigation, EffectFade]}
                                pagination={{
                                    // dynamicBullets: true,
                                    //dynamicBullets: true,
                                    //el: '.swiper-pagination-img',
                                    clickable: true
                                }}
                                spaceBetween={30}
                                zoom={true}
                                navigation={true}
                                // initialSlide={0}
                                className={'mySwiper'}>
                                {
                                    fileList && fileList.length > 0 ? fileList.map((file, index) => {
                                        return <SwiperSlide key={uuidv4()} virtualIndex={index}>
                                            <div className={'image-wrap swiper-zoom-container'} style={{
                                                marginTop: 'unset',
                                                width: '100%',
                                                overflowY: 'unset',
                                                padding: 'unset'
                                            }}>
                                                <img
                                                    src={process.env.REACT_APP_HTTP_BASE_URL + '/common/nexacro/commonGetImageFileAndFileSeq.do?fileId=' + file.FILE_ID + '&fileSeq=' + file.FILE_SEQ}
                                                    alt={file.FILE_NAME}
                                                    style={{width: '100%'}}/>
                                            </div>
                                        </SwiperSlide>;
                                    }) : null
                                }
                            </Swiper>
                            <div className={'result-img-box'}>
                                <h2 className={'h2-title'}>{getDomain('DOMAIN2612')}</h2>
                                <div className={'img-wrap'} style={{padding: 'unset', backgroundColor: 'unset'}}>
                                    <img src={inspFilePath} alt={''} style={{width: '100%'}}/>
                                </div>
                            </div>
                        </div>
                        <div className={'btn-area bottom'}>
                            <input type={'file'} ref={imageRef} accept={'image/*'} capture={'environment'}
                                   onChange={insptPicture} style={{display: "none"}}/>
                            <button type={'button'} className={'button-line'}
                                    onClick={photoCallback}>{getDomain('DOMAIN4580')}</button>
                            <button type={'button'} className={'button-primary'}
                                    onClick={confirmCallBack}>{getDomain('DOMAIN0385')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};