import React from 'react';
import {STORAGE_NAME} from '../components/CommonConstants';
import useStorage from './useStorage';
import {HistoryType} from '../components/model/SqciTypes';
import {sqciRouter} from '../index';
import {ROUTERS} from '../components/Routers';
import {useDataset} from '../components/contexts/CommonContext';

export default function usePageMove() {
    const {setStorage, getStorage, clearStorage} = useStorage();
    const {confirmHistoryBack} = useDataset();

    const pageMove = (linkPath: string | number, state?: {}) => {
        confirmHistoryBack(false);

        const appendHistory = ROUTERS.find(router => router.path === sqciRouter.state.location.pathname)?.appendHistory;

        if(typeof linkPath === 'number') {
            sqciRouter.navigate(linkPath).then();
        } else {
            if(appendHistory) {
                setStorage('SESSION', STORAGE_NAME.HISTORY, {
                    ...getStorage<HistoryType>('SESSION', STORAGE_NAME.HISTORY),
                    [sqciRouter.state.location.pathname]: state || {}
                });
            }

            sqciRouter.navigate(linkPath, {
                replace: !appendHistory,
                state: {
                    ...state,
                    slideDirectionFrom: 'right'
                }
            }).then();

            // navigate(linkPath, {
            //     replace: !(addHistory || true),
            //     state: {
            //         ...state,
            //         slideDirectionFrom: 'right'
            //     }
            // });
        }
    };

    return {pageMove};
};